import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';
 
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const { handleLogin, handleChange, errors } = props;
  return (
    <span>
      <form className={classes.form} noValidate>
        <TextField
          id="standard-username-input"
          label={t('email_address')}
          required
          fullWidth
          onChange={(e) => handleChange(e, 'email')}
          error={errors.email}
          helperText={errors.email}
        />
        <TextField
          id="standard-password-input"
          label={t('password')}
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          required
          fullWidth
          onChange={(e) => handleChange(e, 'password')}
          error={errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleLogin}
        >
          {t('login')}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/forgot-password" variant="body2">
              {t('forgot_password')}?
            </Link>
          </Grid>
          <Grid item>
            <Link href="/register/user" variant="body2">
              {t('not_have_account')}
            </Link>
          </Grid>
        </Grid>
      </form>
    </span>
  );
}

SignIn.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
};

SignIn.defaultProps = {
  errors: {},
};

export default SignIn;
