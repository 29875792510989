import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import LoginForm from '../../components/Forms/LoginForm';
import { userLogin } from '../../actions/userActions';
import Alerts from '../../components/Alerts';
import '../../assets/css/register.css';
import logo from '../../assets/img/logo-form.png';

const theme = createMuiTheme();
const styles = {
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: 3,
    padding: 2,
  },
  backArrow: {
    fontSize: '2.5rem',
    marginTop: 20,
    color: '#057cf3',
    cursor: 'pointer',
  },
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        My Sponsor
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
class Login extends Component {
  state = {
    email: '',
    password: '',
    errors: {},
  };

  componentDidUpdate(prevProps) {
    const { isAuthenticated, history, authUserData } = this.props;
    const { from } = history.location.state || { from: { pathname: '/profile/user' } };
    if (prevProps.authUserData !== authUserData && isAuthenticated) {
      history.push(from);
    }
  }

  handleLogin = (e) => {
    const { email, password } = this.state;
    const { userLogin: login } = this.props;
    e.preventDefault();
    if (this.validate()) {
      const userData = {
        email,
        password,
      };
      login(userData);
    }
  };

  handleChange = (e, prop) => {
    const { errors } = this.state;
    errors[prop] = '';
    this.setState({ [prop]: e.target.value, errors });
  };

  validate = () => {
    let isValid = true;
    const { email, password, errors } = this.state;
    if (!email) {
      isValid = false;
      errors.email = 'Bitte E-Mailadresse eingeben.';
    }
    if (!password) {
      isValid = false;
      errors.password = 'Bitte Passwort eingeben.';
    }
    this.setState({ errors });
    return isValid;
  };

  goBack = (e) => {
    e.preventDefault();
    const { history } = this.props;
    const { from } = history.location.state || { from: { pathname: '/' } };
    history.push(from);
  };

  render() {
    const { errors } = this.state;
    const { isFailed, errorMsg, classes } = this.props;

    return (
      <Fragment>
        <div className="main-regContainer">
          <Container className="form-container" component="main" maxWidth="xs">
            <KeyboardBackspaceIcon onClick={this.goBack} className={classes.backArrow} />
            <CssBaseline />
            <div className={classes.paper}>
              <div>
                <img src={logo} alt="login-icon" />
              </div>
              {isFailed ? <Alerts msg={errorMsg} type="error" /> : null}
              <LoginForm
                handleLogin={this.handleLogin}
                handleChange={this.handleChange}
                errors={errors}
              />
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  authUserData: state.user.authUserData,
  isFailed: state.formResponse.isFailed,
  errorMsg: state.formResponse.msg,
});

const mapDispatchToProps = {
  userLogin,
};

Login.propTypes = {
  userLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.shape({
          pathname: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  isFailed: PropTypes.bool,
  errorMsg: PropTypes.string,
  classes: PropTypes.shape({
    paper: PropTypes.string,
    avatar: PropTypes.string,
    backArrow: PropTypes.string,
  }),
  authUserData: PropTypes.shape({
    userId: PropTypes.string,
  }),
};

Login.defaultProps = {
  isFailed: false,
  errorMsg: '',
  classes: {
    paper: '',
    avatar: '',
  },
  authUserData: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
