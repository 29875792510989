/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState, useEffect } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Cameranew from '../../assets/img/kamera.png';
import { getClubRole, imageBaseUrl } from '../../utils/helper';
import PlaceHolderImage from '../../assets/img/cover-user.jpg';
import ConfirmModal from '../Modals/CoverImageConfirmModal';
import { uploadFiles, updateFiles } from '../../actions/productActions';

const MAX_SIZE = 1024 * 1024 * 2;
export default function CoverImageUpload(props) {
  const dispatch = useDispatch();
  const { authUserData } = useSelector((state) => state.user);
  const { isLoading } = useSelector((state) => state.spinner);
  const { clubDetails, type } = props;
  const [file, setFile] = useState('');
  const [originalFile, setOriginalFile] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [openConfirm, setConfirmModal] = useState(false);

  const clubRole = getClubRole(authUserData)
  useEffect(() => {
    if (clubDetails && clubDetails.cover) {
      const savedImageUrl = `${imageBaseUrl}/${clubDetails.cover}`;
      setFile(savedImageUrl);
      setUploadedFileName(clubDetails.cover);
    }
  }, [clubDetails]);

  const checkImageDimension = (imageFile) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      const uri = URL.createObjectURL(imageFile);
      image.src = uri;
      image.onload = function () {
        let result = true;
        const { height, width } = this;
        if (width < 850 || height < 250) {
          result = false;
        }
        resolve(result);
      };
      image.onerror = reject;
    });
  };

  const handleCloseConfirm = () => {
    setConfirmModal(false);
    setFile('');
    window.location.reload();
  };

  const handleUploadConfirm = () => {
    const fileData = new FormData();
    fileData.append('file', originalFile[0]);
    fileData.append('entity', 'club');
    fileData.append('column', 'cover');
    fileData.append('id', authUserData.clubId);
    setConfirmModal(false);
    if (uploadedFileName) {
      fileData.append('fileName', uploadedFileName);
      dispatch(updateFiles(fileData, () => {}));
    } else {
      dispatch(uploadFiles(fileData, () => {}));
    }
  };

  const handleImageChange = async (event) => {
    setUploadError('');
    setOriginalFile([]);
    setFile('');
    if (event.target.files && event.target.files.length > 0) {
      const filesCount = event.target.files.length;
      if (filesCount > 1) {
        event.preventDefault();
        setUploadError('cannot upload more than one image');
      } else if (filesCount === 1) {
        const newFile = event.target.files[0];
        const hasValidDimesion = await checkImageDimension(newFile);
        if (!hasValidDimesion) {
          setUploadError('please upload image which has good dimesion (1200/600)');
          return;
        }
        if (newFile.size > MAX_SIZE) {
          setUploadError('please upload less than 2MB size of image');
        } else {
          const dataUri = URL.createObjectURL(newFile);
          setFile(dataUri);
          setOriginalFile([newFile]);
          setTimeout(() => {
            setConfirmModal(true);
          });
        }
      }
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  };

  let url = '';
  if (file) {
    url = file;
  } else {
    url = PlaceHolderImage;
  }

  return (
    <Fragment>
      <div className="cover-image-wrapper">
        {type !== 'public' ? (
          <form className="cover-input-container" action="" method="post" id="form-image">
            <input
              style={{ display: 'none' }}
              type="file"
              id="imageUpload"
              accept=".png, .jpg, .jpeg"
              onChange={handleImageChange}
            />
            {clubRole !== 'ROLE_CLUB_FINANCE' &&
            <label htmlFor="imageUpload">
              <img src={Cameranew} className="cover-image-upload" alt="" />
            </label>}
            {uploadError ? (
              <FormHelperText style={{ color: 'red' }}>{uploadError}</FormHelperText>
            ) : null}
          </form>
        ) : null}
        <img className="cover-image-preview" src={url} alt="cover" />
      </div>
      <ConfirmModal
        isLoading={isLoading}
        open={openConfirm}
        handleClose={handleCloseConfirm}
        handleConfirm={handleUploadConfirm}
      />
    </Fragment>
  );
}

CoverImageUpload.propTypes = {
  clubDetails: PropTypes.shape({
    cover: PropTypes.string,
  }),
  type: PropTypes.string,
};

CoverImageUpload.defaultProps = {
  clubDetails: {},
  type: '',
};
