import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CreateEventForm from '../Forms/CreateEventForm';
import Spinner from '../Spinner';
import UploadImage from '../Upload/ImageUpload';

export default function CreateEventModal(props) {
  const {
    open,
    handleOpen,
    eventTiers,
    tickets,
    handleTicketAddClick,
    handleTicketRemoveClick,
    handleTicketsFieldChange,
    handleClubEventFieldChange,
    handleEventCreate,
    handleEventDescChange,
    setEventLocation,
    event,
    isLoading,
    errors,
    setEventImage,
    removeEventImage,
    handleTranslateOptions,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        className="width-xl-60-modal create-event-modal"
      >
        <DialogTitle className="modal-title-center" id="responsive-dialog-title">
          {t('create_event')}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <div>
              <CreateEventForm
                handleTicketAddClick={handleTicketAddClick}
                handleTicketRemoveClick={handleTicketRemoveClick}
                handleTicketsFieldChange={handleTicketsFieldChange}
                handleClubEventFieldChange={handleClubEventFieldChange}
                handleTranslateOptions={handleTranslateOptions}
                eventTiers={eventTiers}
                tickets={tickets}
                event={event}
                setEventLocation={setEventLocation}
                errors={errors}
                setEventImage={setEventImage}
                removeEventImage={removeEventImage}
                handleEventDescChange={handleEventDescChange}
              />
            </div>
          )}
          <Grid style={isLoading ? { display: 'none' } : {}} container spacing={3} className="upload-image-container">
            <Grid item xs={12} sm={6}>
              <FormHelperText style={{ color: 'red' }}>{errors.eventImage}</FormHelperText>
              <UploadImage setImage={setEventImage} removeImage={removeEventImage} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="action-buttons-modal">
          {!isLoading ? (
            <>
              <Button className="cancel-button" variant="outlined" onClick={handleOpen}>
                {t('cancel')}
              </Button>
              <Button
                disabled={!event.isTranlationCompleted || isLoading}
                className="btn-global-filled"
                variant="outlined"
                onClick={handleEventCreate}
              >
                {t('create')}
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateEventModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  eventTiers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleTicketAddClick: PropTypes.func.isRequired,
  handleTicketRemoveClick: PropTypes.func.isRequired,
  handleTicketsFieldChange: PropTypes.func.isRequired,
  handleClubEventFieldChange: PropTypes.func.isRequired,
  handleTranslateOptions: PropTypes.func.isRequired,
  handleEventDescChange: PropTypes.func.isRequired,
  setEventImage: PropTypes.func.isRequired,
  removeEventImage: PropTypes.func.isRequired,
  event: PropTypes.shape({
    isTranlationCompleted: PropTypes.bool,
  }),
  handleEventCreate: PropTypes.func.isRequired,
  setEventLocation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  errors: PropTypes.shape({
    eventImage: PropTypes.string,
  }),
};

CreateEventModal.defaultProps = {
  event: {},
  errors: {},
  isLoading: false,
};
