import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import contactImg from '../../../assets/img/contact-us.jpg';
import ReCAPTCHA from 'react-google-recaptcha';
import { contactUsFormSend } from '../../../actions/userActions';
import { recaptchaWebsiteKey } from '../../../utils/helper';
import { useDispatch } from 'react-redux';
import SuccessMessgae from '../../Alerts/SuccessMessage';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';


const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';
  const isMsgTriggered = useSelector(state => state.formResponse.isMsgTriggered,);
  const isFailed= useSelector(state => state.formResponse.isFailed)
  const msg = useSelector(state => state.formResponse.msg,);
  const [disableButton, setDisableButton] = useState(true)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    subject: ''
,  });
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRecaptchaVerify = (response) => {
    setRecaptchaResponse(response)
    setDisableButton(false)
  };
  let recaptchaInstance;

  const resetRecaptcha = ()=>{
    if(recaptchaInstance) window.grecaptcha.reset()
    setRecaptchaResponse(null);
    setDisableButton(true);
  }

  const handleFormClear = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
      subject: ''
    });
    setRecaptchaResponse(null);
    setDisableButton(true);
    resetRecaptcha();
  };
 

  const handleSubmit = (e) => {
    e.preventDefault();
    //if (recaptchaResponse) {
      dispatch(contactUsFormSend({...formData, recaptchaResponse: recaptchaResponse}, t("contact_us_success"), t("contact_us_failure"),()=>handleFormClear()));
      if(recaptchaInstance) recaptchaInstance.reset();
    // } else {
    //   alert(t('complete_captcha'));
    //   recaptchaInstance.reset();
    // }
  };

  return (
    <section className="contact-us-section" id="contact-us-section">
      <div className="row">
        <div className="col-lg-6">
          <div className="contact-img">
            <img src={contactImg} alt="#" />
          </div>
        </div>
        <div className="col-lg-6">
        <SuccessMessgae isMsgTriggered={isMsgTriggered} msg={msg} />
        {isFailed && <Alert severity="error">{t('contact_us_failure')}</Alert>}
          <div className="contact-form">
            <form id="contactForm" onSubmit={handleSubmit} className="contact-form-aqua">
              <h2 className="contact-head">{t('contact_form_header')}</h2>
              <input
                type="text"
                name="name"
                required
                placeholder={`${t("Name")} *`}
                className="contact-frm active"
                onChange={handleChange}
                value={formData.name}
              />
              <input
                type="email"
                name="email"
                required
                placeholder={`${t("email_address")} *`}
                className="contact-frm"
                onChange={handleChange}
                value={formData.email}
              />
              <input
                type="text"
                name="subject"
                required
                placeholder={`${t("Subject")} *`}
                className="contact-frm"
                onChange={handleChange}
                value={formData.subject}
              />
              <textarea
                name="message"
                id="message"
                required
                placeholder={`${t("Message")} *`}
                className="contact-msg"
                onChange={handleChange}
                value={formData.message}
              />
              <ReCAPTCHA
                sitekey="6LcpT-goAAAAAMbcQCTH97zEaqqFyCpvCTYynoMd"
                onChange={handleRecaptchaVerify}
                onTokenExpired={resetRecaptcha}
              />
              <button id="form-submit" type="submit" disabled= {false} className="forward-btn" >{t("send")}</button>
              <br />
              <br />
              <span className="msgSubmit" />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
