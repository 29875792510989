import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from '../../Carousel/ClubSlider';

function Clubs(props) {
  const { clubList } = props;
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <section className="choose-section">
      <div className="overlay">
        <div className="container-fruit text-center">
          <div className="row mr-0 ml-0 d-flex justify-content-center">
            <div className="col-xl-8 col-lg-12">
              <div className="choose-text">
                <h5 className="choose-title">{t('club_part_subtitle')}</h5>
                <h2 className="choose-subtitle">{t('club_part_title')}</h2>
                <p className="choose-title-describe">{t('club_part_text')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="choose-section-carousel">
          {clubList && clubList.length > 0 ? <Slider items={clubList} selectedLanguage={selectedLanguage} /> : null}
        </div>
      </div>
    </section>
  );
}

Clubs.propTypes = {
  clubList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Clubs;
