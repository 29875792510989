import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { QRCode } from 'react-qrcode-logo';
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/logo-qr.png'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  section2: {
    margin: 10,
    padding: 10,
    width: 256,
    height: 'auto',
  },
});

const useStyles = makeStyles({
  actionBtn: {
    color: '#ff5500',
    fontWeight: 'bolder',
    borderColor: '#f50',
    padding: 0,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff5500',
    },
  },
  pdfLink: {
    color: '#ff5500',
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#ff5500',
    },
  },
});

const RenderQRCode = (props) => {
  const [qrCodeValues, setQrCodeValues] = useState('');
  const { clubDetails } = props;
  const classes = useStyles();

  useEffect(() => {
    let url = '';
    if (clubDetails.shortName) {
      url = `${window.location.protocol}//${window.location.hostname}/${clubDetails.shortName}`;
      setQrCodeValues(url);
    }
  }, [clubDetails]);

  const pdfFileName = clubDetails ? `${clubDetails.name}.pdf` : 'sample.pdf';
  const canvas = document.getElementById('qr-canvas');

  const dataURL = canvas ? canvas.toDataURL() : null;
  const ClubDoc = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>Club QR Code Details</Text>
          </View>
          <View style={styles.section2}>
            {dataURL ? <Image style={{ width: '100%' }} src={dataURL} /> : null}
          </View>
        </Page>
      </Document>
    );
  };

  const downloadPNG = () => {
    const link = document.createElement('a');
    link.download = `${clubDetails.name}.png`;
    link.href = canvas.toDataURL('image/png');
    link.click();
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <QRCode
            id="qr-canvas"
            value={qrCodeValues}
            size={1200}
            bgColor= "#ffffff00"
            fgColor="#000000"
            level= 'L'
            eyeRadius={[
              [10, 10, 10, 10], // top/left eye
              [10, 10, 10, 10], // top/right eye
              [10, 10, 10, 10], // bottom/left
            ]}
            eyeColor= '#50a000'
            qrStyle= 'dots'
            logoWidth = {240}
            logoHeight= {240}
            //removeQrCodeBehindLogo= 'true'
            logoPaddingStyle= 'square'
            quietZone={50}
            logoImage={logo}
            logoPadding={30}
          />
        </Grid>
        <Grid className="display-flex flex-column align-items-center" item xs={6} sm={6}>
          <strong>Download QR-Code</strong>
          <div className="qr-download-buttons">
             <Button className={classes.actionBtn} variant="outlined" onClick={downloadPNG}>
               PNG
             </Button>
          </div>
        </Grid>
        <Grid item xs={3} sm={6}>
          <Button variant="outlined" className={classes.actionBtn+ " not-visible"}>
            <PDFDownloadLink
              className={classes.pdfLink}
              document={<ClubDoc />}
              fileName={pdfFileName}
            >
              {({ loading }) => (loading && dataURL ? '' : 'PDF')}
            </PDFDownloadLink>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderQRCode;

RenderQRCode.propTypes = {
  clubDetails: PropTypes.string.isRequired,
};
