import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function DescriptionAlerts(props) {
  const classes = useStyles();
  const { msg, type } = props;
  return (
    <div className={classes.root}>
      <Alert severity={type}>
        <AlertTitle>{type}</AlertTitle>
        {msg}
      </Alert>
    </div>
  );
}

DescriptionAlerts.propTypes = {
  msg: PropTypes.string,
  type: PropTypes.string,
};

DescriptionAlerts.defaultProps = {
  msg: '',
  type: '',
};
