import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => rest.userLoggedIn
         ? (<Component {...props} />)
          : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
      }
    />
  );
};

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
});

export default connect(mapStateToProps, null)(PrivateRoute);
