import moment from 'moment';
import {
  FETCH_USER_DETAILS,
  LOGIN_SUCCESS,
  LOGOUT,
  FETCH_USER_FAVORITES,
  FETCH_USER_DONATION_HISTORY,
  FETCH_USER_DONATION_BY_CLUBID,
  SET_USER_CLUB_ID,
  FETCH_USER_DELETE,
  FETCH_USER_SUBSCRIPTIONS,
} from '../utils/types';

export const isUserLoggedIn = () => {
  const userObj = localStorage.getItem('authUser');
  const retrieveObj = JSON.parse(userObj);
  return retrieveObj && retrieveObj.token && retrieveObj.refreshToken;
};

const getUserdata = () => {
  const userObj = localStorage.getItem('authUser');
  const retrieveObj = JSON.parse(userObj);
  return retrieveObj;
};

export const clearUserState = () => {
  localStorage.removeItem('authUser');
};

export const getAuthToken = () => {
  const userObj = JSON.parse(localStorage.getItem('authUser'));
  return userObj.token;
};

export const isTokenExpired = () => {
  const userObj = JSON.parse(localStorage.getItem('authUser'));
  const currentDate = moment(new Date()).format();

  if (userObj && userObj.expireAt) {
    return currentDate > userObj.expireAt;
  }
  return true;
};

export const setAuthAndRefreshTokens = (token, refreshToken) => {
  const userObj = JSON.parse(localStorage.getItem('authUser'));
  if (userObj) {
    userObj.token = token;
    userObj.refreshToken = refreshToken;
    userObj.expireAt = moment(new Date()).add(1, 'day').format();
    localStorage.setItem('authUser', JSON.stringify(userObj));
  }
};

export const getRefreshToken = () => {
  const userObj = JSON.parse(localStorage.getItem('authUser'));
  return userObj.refreshToken;
};

const setClubId = (clubId) => {
  const userDetails = getUserdata();
  if (userDetails) {
    userDetails.clubId = clubId;
    localStorage.setItem('authUser', JSON.stringify(userDetails));
  }
};

const addressDetails = {
  address1: '',
  address2: '',
  postalCode: '',
  city: '',
  country: '',
};

const saveToken = (payload) => {
  const authUser = {
    userId: payload.id,
    email: payload.email,
    roles: payload.roles,
    username: payload.username,
    token: payload.token,
    refreshToken: payload.refreshToken,
    expireAt: moment(new Date()).add(1, 'day').format(),
    clubId: payload.clubId ? payload.clubId : '',
    firstName: '',
    lastName: '',
    address: {},
    verified: payload.verified,
    donationEnabled: payload.donationEnabled
  };
  localStorage.setItem('authUser', JSON.stringify(authUser));
};

const saveUserDetails = (payload) => {
  const user = getUserdata();
  user.firstName = payload.firstName;
  user.lastName = payload.lastName;
  user.address = payload.address ? payload.address : addressDetails;
  user.image = payload.image;
  user.verified = payload.verified;
  user.donationEnabled = payload.donationEnabled;
  localStorage.setItem('authUser', JSON.stringify(user));
};

const initialState = {
  userDetails: {},
  isAuthenticated: false,
  userLoggedIn: isUserLoggedIn(),
  authUserData: getUserdata(),
  favoriteClubs: [],
  userDonationData: [],
  clubDonations: [],
  userSubscriptions: []
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DETAILS:
      saveUserDetails(action.payload);
      return { ...state, authUserData: getUserdata() };
    case LOGIN_SUCCESS:
      if (action.payload.token) {
        saveToken(action.payload);
        return { ...state, isAuthenticated: true, userLoggedIn: true };
      }
      return { ...state, isAuthenticated: false };
    case LOGOUT:
      localStorage.removeItem('authUser');
      return { ...state, isAuthenticated: false, userLoggedIn: false };
    case FETCH_USER_FAVORITES:
      return { ...state, favoriteClubs: action.payload };
    case FETCH_USER_DONATION_HISTORY:
      return { ...state, userDonationData: action.payload };
    case FETCH_USER_DONATION_BY_CLUBID:
      return { ...state, clubDonations: action.payload };
    case SET_USER_CLUB_ID:
      setClubId(action.payload);
      return { ...state, authUserData: getUserdata() };
    case FETCH_USER_DELETE:
      localStorage.removeItem('authUser');
      return { ...state, isAuthenticated: false, userLoggedIn: false };
    case FETCH_USER_SUBSCRIPTIONS:
      return {...state, userSubscriptions: action.payload}
    default:
      return state;
  }
}
