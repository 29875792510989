import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CountrySelect from '../Select/CountrySelect';

const useStyles = makeStyles(() => ({
  btnColor: {
    color: '#fff',
    backgroundColor: '#f50',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f50',
    },
  },
}));
// Destructuring props
const SecondStep = ({
  handleNext,
  handleBack,
  handleChange,
  handleCountryChange,
  values: {
    cpAddress1,
    cpAddress2,
    cpPostCode,
    cpCity,
    cpDesignation,
    cpFirstName,
    cpLastName,
    cpCountry,
    cpEmail,
    cpContact,
  },
  errors,
}) => {
  // Check if all values are not empty or if there are some error
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('first_name')}
            name="cpFirstName"
            placeholder={t('first_name')}
            margin="normal"
            value={cpFirstName || ''}
            onChange={handleChange}
            required
            error={errors.cpFirstName}
            helperText={errors.cpFirstName}
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('last_name')}
            name="cpLastName"
            placeholder={t('last_name')}
            margin="normal"
            value={cpLastName || ''}
            onChange={handleChange}
            required
            error={errors.cpLastName}
            helperText={errors.cpLastName}
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('email')}
            name="cpEmail"
            placeholder={t('email')}
            margin="normal"
            onChange={handleChange}
            value={cpEmail || ''}
            required
            error={errors.cpEmail}
            helperText={errors.cpEmail}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('phone')}
            name="cpContact"
            placeholder={t('phone')}
            value={cpContact || ''}
            onChange={handleChange}
            margin="normal"
            required
            error={errors.cpContact}
            helperText={errors.cpContact}
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line1')}
            name="cpAddress1"
            placeholder={t('address_line1')}
            margin="normal"
            value={cpAddress1 || ''}
            onChange={handleChange}
            required
            error={errors.cpAddress1}
            helperText={errors.cpAddress1}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line2')}
            name="cpAddress2"
            placeholder={t('address_line2')}
            margin="normal"
            value={cpAddress2 || ''}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('post_code')}
            name="cpPostCode"
            placeholder={t('post_code')}
            value={cpPostCode || ''}
            onChange={handleChange}
            margin="normal"
            required
            error={errors.cpPostCode}
            helperText={errors.cpPostCode}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('city')}
            name="cpCity"
            placeholder={t('city')}
            value={cpCity || ''}
            onChange={handleChange}
            margin="normal"
            required
            error={errors.cpCity}
            helperText={errors.cpCity}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelect
            name="cpCountry"
            label={t('country')}
            handleChange={(e, option) => handleCountryChange(option, 'cpCountry')}
            selectedCountry={cpCountry || ''}
            error={errors.cpCountry}
            helperText={errors.cpCountry}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('position_in_club')}
            name="cpDesignation"
            placeholder={t('position_in_club')}
            value={cpDesignation || ''}
            onChange={handleChange}
            margin="normal"
            required
            error={errors.cpDesignation}
            helperText={errors.cpDesignation}
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          className={classes.btnColor}
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          {t('back')}
        </Button>
        <Button variant="contained" className={classes.btnColor} onClick={handleNext}>
          {t('next')}
        </Button>
      </div>
    </Fragment>
  );
};

SecondStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    cpAddress1: PropTypes.string,
    cpAddress2: PropTypes.string,
    cpPostCode: PropTypes.string,
    country: PropTypes.string,
    cpCity: PropTypes.string,
    cpDesignation: PropTypes.string,
    cpFirstName: PropTypes.string,
    cpLastName: PropTypes.string,
    cpCountry: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    cpAddress1: PropTypes.string,
    cpPostCode: PropTypes.string,
    country: PropTypes.string,
    cpCity: PropTypes.string,
    cpDesignation: PropTypes.string,
    cpFirstName: PropTypes.string,
    cpLastName: PropTypes.string,
    cpCountry: PropTypes.string,
  }),
};

SecondStep.defaultProps = {
  errors: {},
};

export default SecondStep;
