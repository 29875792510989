import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 150,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paymentDetails: {
    display: 'flex',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: '2.5rem',
    fontWeight: 600,
    textAlign: 'center',
  },
}));

const errorResponse = {
  success: false,
  message: 'Payment completed successfully',
};

const sendResponseToWebView = (response) =>{
  console.log("Error");
  console.log(response);
  window.ReactNativeWebView.postMessage(JSON.stringify(response));
}


const TwintCancelled = (props) => {
  const classes = useStyles();

  const redirectToHome = () => {
    props.history.push('/');
  };

  //sendResponseToWebView(errorResponse);

  return (
    <div>
      <CssBaseline />
      <Container className={classes.container} maxWidth="sm">
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className={classes.headerText} component="div" variant="body1">
                <Box color="warning.main">Twint Payment Cancelled</Box>
              </Typography>
              <p style={{ textAlign: 'center' }}>
                Something went wrong with your payment. please contact us for more information
              </p>
              <div className={classes.paymentDetails}>
                <Button onClick={redirectToHome} variant="outlined" color="primary">
                  Back To Home
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

TwintCancelled.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default TwintCancelled;
