import React, { Fragment, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

import { countries } from '../../utils/countryList';
import { getClubRole } from '../../utils/helper';

const countryDropDown = () => {
  return countries.map((country) => (
    <MenuItem key={country.code} value={country.name}>
      {country.name}
    </MenuItem>
  ));
};

const useStyles = makeStyles(() => ({
  btnColor: {
    color: '#fff',
    backgroundColor: '#f50',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f50',
    },
  },
}));

// Destructuring props
const SecondStep = ({
  handleSave,
  handleChange,
  handleAddressChange,
  values: {
    firstName,
    lastName,
    position,
    contactNumber,
    email,
    address: { address1, address2, postalCode, city, country },
  },
  errors,
  authUserData
}) => {
  // Check if all values are not empty or if there are some error
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const selectedLanguage = localStorage.getItem('language') || 'du';;

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const clubRole = getClubRole(authUserData)
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('first_name')}
            name="firstName"
            placeholder={t('first_name')}
            margin="normal"
            value={firstName || ''}
            onChange={handleChange}
            required
            error={errors.firstName}
            helperText={errors.firstName}
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('last_name')}
            name="lastName"
            placeholder={t('last_name')}
            margin="normal"
            value={lastName || ''}
            onChange={handleChange}
            required
            error={errors.lastName}
            helperText={errors.lastName}
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('email')}
            name="email"
            placeholder={t('email')}
            margin="normal"
            onChange={handleChange}
            value={email || ''}
            required
            error={errors.email}
            helperText={errors.email}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('phone')}
            name="contactNumber"
            placeholder={t('phone')}
            value={contactNumber || ''}
            onChange={handleChange}
            margin="normal"
            required
            error={errors.contactNumber}
            helperText={errors.contactNumber}
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line1')}
            name="address1"
            placeholder={t('address_line1')}
            margin="normal"
            value={address1 || ''}
            onChange={(e) => handleAddressChange(e, 'contact')}
            required
            error={errors.address.address1}
            helperText={errors.address.address1}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line2')}
            name="address2"
            placeholder={t('address_line2')}
            margin="normal"
            value={address2 || ''}
            onChange={(e) => handleAddressChange(e, 'contact')}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('post_code')}
            name="postalCode"
            placeholder={t('post_code')}
            value={postalCode || ''}
            onChange={(e) => handleAddressChange(e, 'contact')}
            margin="normal"
            required
            error={errors.address.postalCode}
            helperText={errors.address.postalCode}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('city')}
            name="city"
            placeholder={t('city')}
            value={city || ''}
            onChange={(e) => handleAddressChange(e, 'contact')}
            margin="normal"
            required
            error={errors.address.city}
            helperText={errors.address.city}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required margin="normal">
            <InputLabel>{t('country')}</InputLabel>
            <Select
              value={country}
              onChange={(e) => handleAddressChange(e, 'contact')}
              name="country"
              error={errors.address.country}
            >
              {countryDropDown()}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>{errors.address.country}</FormHelperText>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('position_in_club')}
            name="position"
            placeholder={t('position_in_club')}
            value={position || ''}
            onChange={handleChange}
            margin="normal"
            required
            error={errors.position}
            helperText={errors.position}
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>
      </Grid>
      {clubRole != 'ROLE_CLUB_FINANCE' &&
        <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleSave} className={classes.btnColor}>
            {t('save')}
          </Button>
        </div>}
    </Fragment>
  );
};

SecondStep.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    position: PropTypes.string,
    email: PropTypes.string,
    contactNumber: PropTypes.string,
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
  }).isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    contactNumber: PropTypes.string,
    position: PropTypes.string,
    address: PropTypes.shape({
      address1: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};

SecondStep.defaultProps = {
  errors: {},
};

export default SecondStep;
