import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';

import lukas from '../../../assets/img/about-us/lukas-pietrobon-mysponsor.jpg';
import claude from '../../../assets/img/about-us/claude-braegger-mysponsor.jpg';
import redon from '../../../assets/img/about-us/redon-lalinovci-mysponsor.jpg';
import daniel from '../../../assets/img/about-us/daniel-fischer-mysponsor.jpg';
import antonio from '../../../assets/img/about-us/antonio-fonzo-mysponsor.jpg';
import fabienne from '../../../assets/img/about-us/fabienne-sengstag-mysponsor.jpg';
import ogimage from '../../../assets/img/mys-og-image.jpg';

const aboutBoxes = [
  { icon: 'icon-simple', bgClass: 'bg-eff', title: 'about_part_simple' },
  { icon: 'icon-foerdern', bgClass: 'bg-ex', title: 'about_part_promo' },
  { icon: 'icon-security', bgClass: 'bg-security', title: 'about_part_secure' },
  { icon: 'icon-schnell', bgClass: 'bg-trans', title: 'about_part_fast' },
  { icon: 'ren-simple', bgClass: 'bg-simple', title: 'about_part_overview' },
  { icon: 'icon-transparent', bgClass: 'bg-com', title: 'about_part_transparent' },
];

const teamMembers = [
  { name: 'Lukas Pietrobon', position: 'CEO & Founder', image: lukas, email: 'lp@mysponsor.ch', linkedin: 'https://www.linkedin.com/in/lukas-pietrobon-4221b010a/' },
  { name: 'Claude Brägger', position: 'CMO & CTO', image: claude, email: 'cb@mysponsor.ch', linkedin: 'https://www.linkedin.com/in/claude-br%C3%A4gger-1b4705170/' },
  { name: 'Fabienne Sengstag', position: 'Marketing & Social Media', image: fabienne, email: 'fs@mysponsor.ch', linkedin: 'https://www.linkedin.com/in/fabienne-sengstag-4905b013a/' },
  { name: 'Redon Lalinovci', position: 'Backoffice', image: redon, email: 'support@mysponsor.ch' },
  { name: 'Daniel Fischer', position: 'Full Stack Developer', image: daniel, email: 'df@mysponsor.ch', linkedin: 'https://www.linkedin.com/in/daniel-fischer-523454137/' },
  { name: 'Antonio Fonzo', position: 'Agent', image: antonio, email: 'af@mysponsor.ch' },
];

const AboutBox = ({ icon, bgClass, title, t }) => (
  <div className="col-lg-4 col-md-4 col-sm-4">
    <div className="single-about-box">
      <div className={`icon-box-outer flex align-items-center ${bgClass}`}>
        <div className="icon-box flex align-items-center">
          <i className={`iconabout ${icon}`} />
        </div>
      </div>
      <h3>{t(title)}</h3>
    </div>
  </div>
);

const TeamMember = ({ name, position, image, email, linkedin }) => (
  <div className="col-lg-6 col-md-6 person-box">
    <div className="personal-img-holder">
      <img className="personal-img" src={image} alt={`${name} | MY SPONSOR AG`} title={name} />
      <div className="personal-img-hover">
        <div className="icon-holder">
          <ul>
            <li className="social-nav">
              <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                <i className="icofont-email"></i>
              </a>
            </li>
            {linkedin && (
              <li className="social-nav">
                <a href={linkedin} target="_blank" rel="noopener noreferrer">
                  <i className="icofont-linkedin"></i>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
    <div className="name">
      <h3>{name}</h3>
      <span>{position}</span>
    </div>
  </div>
);

const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Wir sind MY SPONSOR | Deine Sponsoring-Plattform</title>
          <meta name="description" content="Erfahre mehr über die Idee, Entstehung und Entwicklung von MY SPONSOR und lerne unser Team kennen. Die Schweizer Sponsoring-Plattform." />
          <meta property="og:title" content="Wir sind MY SPONSOR | Deine Sponsoring-Plattform" />
          <meta property="og:description" content="Erfahre mehr über die Idee, Entstehung und Entwicklung von MY SPONSOR und lerne unser Team kennen. Die Schweizer Sponsoring-Plattform." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/ueber-uns" />
          <meta name="twitter:title" content="Wir sind MY SPONSOR | Deine Sponsoring-Plattform" />
          <meta name="twitter:description" content="Erfahre mehr über die Idee, Entstehung und Entwicklung von MY SPONSOR und lerne unser Team kennen. Die Schweizer Sponsoring-Plattform." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>{t('about_subtitle')}</h5>
              <h1 className="page-title">{t('about_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('about_text_1')}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="about-left">
                <div className="about-box">
                  {[0, 1].map(rowIndex => (
                    <div key={rowIndex} className="row text-center">
                      {aboutBoxes.slice(rowIndex * 3, (rowIndex + 1) * 3).map((box, index) => (
                        <AboutBox key={index} {...box} t={t} />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('about_text_2')}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-default">
                <h2 className="title meet-people">
                  Meet the <span>People</span>
                </h2>
                <div className="team">
                  {teamMembers.map((member, index) => (
                    <TeamMember key={index} {...member} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p className="forward-text">
                {t('about_bottom_1')}<a href="/jobs">{t('about_bottom_link')}</a> {t('about_bottom_2')}
              </p>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default AboutUs;