import axios from 'axios';
import { headers, baseUrl, authHeader } from '../utils/helper';
import { FETCH_ALL_EVENTS, FETCH_ALL_CLUB_EVENTS } from '../utils/types';
import { displaySuccess, clearSuccess, displayError, clearError } from './formResponseAction';
import { refreshToken } from './userActions';

export const getEventsNearBy = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/events/nearbyAll`, data, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_EVENTS,
          payload: response.data.content,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getClubEvents = (data) => {
  return async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/events/filter`, data, headers);
        if (response && response.data) {
          dispatch({
            type: FETCH_ALL_CLUB_EVENTS,
            payload: response.data,
          });
        }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const createClubEvents = (data, onsuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/events`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Event erfolgreich erstellt !'));
          setTimeout(() => {
            dispatch(clearSuccess());
            onsuccess(response.data.id);
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 2000);
      }
      console.error('there is an error', error);
    }
  };
};

export const editClubEvents = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/events`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Event erfolgreich aktualisiert!'));
          dispatch(getClubEvents({ clubId: data.clubId }));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 2000);
      }
      console.error('there is an error', error);
    }
  };
};
