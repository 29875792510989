import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const { handleContinue, handleChange, errors } = props;
  return (
    <span>
      <form className={classes.form} noValidate>
        <TextField
          id="standard-username-input"
          label={t('email_address')}
          required
          fullWidth
          onChange={(e) => handleChange(e, 'email')}
          error={errors.email}
          helperText={errors.email}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleContinue}
        >
          {t('next')}
        </Button>
      </form>
    </span>
  );
}

ForgotPassword.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
  }),
};

ForgotPassword.defaultProps = {
  errors: {},
};

export default ForgotPassword;
