import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { countries } from '../../utils/countryList';
import { findDescriptionField, clubRoles, getClubRole } from '../../utils/helper';
import PlaceSearch from '../PlaceSearch';
import LogoUpload from '../Upload/LogoUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const getStyles = (type, sportType, theme) => {
  return {
    fontWeight:
      sportType.indexOf(type) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const countryDropDown = () => {
  return countries.map((country) => (
    <MenuItem key={country.code} value={country.code}>
      {country.name}
    </MenuItem>
  ));
};

const namePlaceholder = (value, t ) => {
  return value === 'CLUB'? t('club_name') : value === 'ATHLETE' ? t('athlete_name') : value === 'ACADEMY' ? t('academy_name') : t('foundation_name');
}

const emailAddressPlaceholder = (value, t ) => {
  return value === 'CLUB'? t('club_email') : value === 'ATHLETE' ? t('athlete_email') : value === 'ACADEMY' ? t('academy_email') : t('foundation_email');
}

const descriptionPlaceholder = (value, t ) => {
  return value === 'CLUB'? t('club_description') : value === 'ATHLETE' ? t('athlete_description') : value === 'ACADEMY' ? t('academy_description') : t('foundation_description');
}

const webSitePlaceholder = (value, t ) => {
  return value === 'CLUB'? t('club_website') : value === 'ATHLETE' ? t('athlete_website') : value === 'ACADEMY' ? t('academy_website') : t('foundation_website');
}

const useStyles = makeStyles(() => ({
  btnColor: {
    color: '#fff',
    backgroundColor: '#f50',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f50',
    },
  },
  adminEmailTextBox: {
    display: 'flex',
  },
  deleteIcon: {
    color: 'red',
    marginTop: 35,
    cursor: 'pointer',
  },
}));
// Destructuring props
const FirstStep = ({
  handleSave,
  handleChange,
  handleAddressChange,
  handleAdminEmail,
  handleEmailChange,
  handleRoleChange,
  handleEmailCheck,
  handleTypeChange,
  handleNatureChange,
  setAddressDetails,
  setClubLogo,
  removeClubLogo,
  removeAdminEmailTextField,
  savedLogo,
  types,
  values: {
    clubName,
    address: { address1, address2, postalCode, city, country },
    tags,
    email,
    website,
    contactNumber,
    nature,
  },
  adminEmailsInput,
  errors,
  handleDescriptionText,
  decriptionText,
  authUserData
}) => {
  // Check if all values are not empty or if there are some error
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [name, setName] = useState(namePlaceholder(nature,t))
  const [emailAdress, setEmail] = useState(emailAddressPlaceholder(nature,t))
  const [description, setDescription] = useState(descriptionPlaceholder(nature,t))
  const [webSite, setWebSite] = useState(webSitePlaceholder(nature,t))
  const selectedLanguage = localStorage.getItem('language') || 'du';
  const descriptionFieldName = findDescriptionField(selectedLanguage);
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  const theme = useTheme();
  const natures = ['CLUB', 'ATHLETE', 'ACADEMY', 'FOUNDATION'];
  const clubRole = getClubRole(authUserData)
  const emailEditable = clubRole === 'ROLE_CLUB'

  const handleClubNatureChange = (event) => {
    handleNatureChange(event)
    setPlaceHolder(event.target.value)
    
  }

  const setPlaceHolder = (value)=>{
    setName(namePlaceholder(value,t))
    setEmail(emailAddressPlaceholder(value,t))
    setDescription(descriptionPlaceholder(value,t))
    setWebSite(webSitePlaceholder(value,t))
  }

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>

        <Grid item xs={12} sm={12}>
          <FormHelperText style={{ color: 'red' }}>{errors.clubLogoFiles}</FormHelperText>
          <LogoUpload savedImages={savedLogo} setImage={setClubLogo} removeImage={removeClubLogo} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required margin="normal">
            <InputLabel>{t('type')}</InputLabel>
            <Select
              labelId="label_nature"
              id="nature"
              multiple={false}
              value={nature}
              onChange={handleClubNatureChange}
              MenuProps={MenuProps}
              onLoad={()=> setPlaceHolder(nature)}
            >
              {natures.map((nature, index) => (
                <MenuItem key={index} value={nature} style={getStyles(nature, natures, theme)}>
                  {t(nature)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors.nature}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label={name}
            name="clubName"
            placeholder={name}
            margin="normal"
            onChange={handleChange}
            value={clubName || ''}
            required
            error={errors.clubName}
            helperText={errors.clubName}
            inputProps={{
              maxLength: 20,
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={emailAdress}
            name="email"
            placeholder={emailAdress}
            margin="normal"
            onChange={handleChange}
            value={email || ''}
            required
            error={errors.email}
            helperText={errors.email}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={webSite}
            name="website"
            placeholder={webSite}
            margin="normal"
            onChange={handleChange}
            value={website || ''}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PlaceSearch setAddressDetails={setAddressDetails} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line1')}
            name="address1"
            placeholder={t('address_line1')}
            margin="normal"
            value={address1 || ''}
            onChange={(e) => handleAddressChange(e, 'club')}
            required
            error={errors.address.address1}
            helperText={errors.address.address1}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line2')}
            name="address2"
            placeholder={t('address_line2')}
            margin="normal"
            value={address2 || ''}
            onChange={(e) => handleAddressChange(e, 'club')}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('post_code')}
            name="postalCode"
            placeholder={t('post_code')}
            value={postalCode || ''}
            onChange={(e) => handleAddressChange(e, 'club')}
            margin="normal"
            required
            error={errors.address.postalCode}
            helperText={errors.address.postalCode}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required margin="normal">
            <InputLabel>{t('country')}</InputLabel>
            <Select
              value={country}
              onChange={(e) => handleAddressChange(e, 'club')}
              name="country"
              error={errors.address.country}
            >
              {countryDropDown()}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors.address.country}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('city')}
            name="city"
            placeholder={t('city')}
            value={city || ''}
            onChange={(e) => handleAddressChange(e, 'club')}
            margin="normal"
            required
            error={errors.address.city}
            helperText={errors.address.city}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('phone')}
            name="contactNumber"
            placeholder={t('phone')}
            value={contactNumber || ''}
            onChange={handleChange}
            margin="normal"
            error={errors.contactNumber}
            helperText={errors.contactNumber}
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required margin="normal">
            <InputLabel>{t('sport_kind')}</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple={false}
              value={tags && tags.length ? tags[0].id : ''}
              onChange={handleTypeChange}
              MenuProps={MenuProps}
            >
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id} style={getStyles(type, types, theme)}>
                  {type.tag}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors.tags}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={description}
            name={descriptionFieldName}
            placeholder={description}
            value={decriptionText[descriptionFieldName]}
            onChange={handleDescriptionText}
            margin="normal"
            required
            error={errors[descriptionFieldName]}
            helperText={errors[descriptionFieldName]}
            inputProps={{
              maxLength: 240,
            }}
          />
        </Grid>
        {emailEditable &&
          <Grid item xs={12} sm={12}>
            {adminEmailsInput.map((i, index) => {
              return (
                <div className={classes.adminEmailTextBox} disabled={emailEditable}>
                  <TextField
                    onChange={(e) => handleEmailChange(e, index, i.role)}
                    onBlur={handleEmailCheck}
                    fullWidth
                    label={t('email_adress')}
                    name="adminEmail"
                    value={i.email}
                    key={`admin-email-${i}`}
                    placeholder={t('email_adress')}
                    margin="normal"
                  />
                  <FormControl fullWidth required margin="normal" style={{ width: "70%" }}>
                    <InputLabel>{t('role')}</InputLabel>
                    <Select
                      labelId="label_role"
                      id="role"
                      multiple={false}
                      value={i.role}
                      onChange={(e) => handleRoleChange(e, index, i.email)}
                      MenuProps={MenuProps}
                    >
                      {clubRoles.map((role, index) => (
                        <MenuItem key={index} value={role} style={getStyles(role, clubRoles, theme)}>
                          {t(role)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{errors.nature}</FormHelperText>
                  </FormControl>
                  <DeleteForeverIcon
                    onClick={() => removeAdminEmailTextField(index)}
                    className={classes.deleteIcon}
                  />
                </div>
              );
            })}
            <FormHelperText style={{ color: 'red' }}>{errors.adminEmail}</FormHelperText>
            <Button
              variant="contained"
              onClick={handleAdminEmail}
              className={classes.btnColor}
              startIcon={<AddIcon />}
            >
              {t('add_admins')}
            </Button>
            <br />
            <span className="add-admin-text">{t('add_admins_text')}</span>
          </Grid>}
      </Grid>
      {clubRole != 'ROLE_CLUB_FINANCE' &&
        <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
          <Button variant="contained" className={classes.btnColor} onClick={handleSave}>
            {t('save')}
          </Button>
        </div>}
    </Fragment>
  );
};

FirstStep.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAdminEmail: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func.isRequired,
  handleNatureChnage: PropTypes.func.isRequired,
  handleEmailCheck: PropTypes.func.isRequired,
  setAddressDetails: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  removeClubLogo: PropTypes.func.isRequired,
  handleDescriptionText: PropTypes.func.isRequired,
  setClubLogo: PropTypes.func.isRequired,
  removeAdminEmailTextField: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  savedLogo: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.shape({
    clubName: PropTypes.string,
    shortName: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    description: PropTypes.string,
    contactNumber: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    nature: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    shortName: PropTypes.string,
    address: PropTypes.shape({
      address1: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    tags: PropTypes.string,
    clubName: PropTypes.string,
    description: PropTypes.string,
    adminEmail: PropTypes.string,
    clubLogoFiles: PropTypes.string,
    email: PropTypes.string,
    contactNumber: PropTypes.string,
  }),
  adminEmailsInput: PropTypes.arrayOf(PropTypes.object).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})),
  decriptionText: PropTypes.shape({}),
};

FirstStep.defaultProps = {
  errors: {},
  types: [],
  savedLogo: [],
  decriptionText: {},
};

export default FirstStep;
