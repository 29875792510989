import React, { Fragment, useEffect } from 'react';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>{t('privacy_subtitle')}</h5>
              <h1 className="page-title">{t('privacy_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <h4 className="title">
                  {t('privacy_scope_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_1')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_2')}
                </p>
                <p className="text">
                  {t('privacy_text_3')}
                </p>
                <h4 className="title">
                  {t('privacy_person_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_4')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_5')}
                </p>
                <p className="text mb-sm-0">
                  {t('privacy_text_6')}
                </p>
               <p className="text">
                  Email: dataprotection@mysponsor.ch
                </p>
                <h4 className="title">
                  {t('privacy_type_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_7')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_8')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_9')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_10')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_11')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_12')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_13')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_14')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_15')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_16')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_17')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_18')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_19')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_20')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_21')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_22')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_23')}
                </p>
                <p className="text">
                  {t('privacy_text_24')}
                </p>
                <h4 className="title">
                  {t('privacy_purpose_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_25')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_26')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_27')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_28')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_29')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_30')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_31')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_32')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_33')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_34')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_35')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_36')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_37')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_38')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_39')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_40')}
                </p>
                <p className="text">
                  {t('privacy_text_41')}
                </p>
                <h4 className="title">
                  {t('privacy_basis_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_42')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_43')}
                </p>
                <p className="text">
                  {t('privacy_text_44')}
                </p>
                <h4 className="title">
                  {t('privacy_profiling_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_45')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_46')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_47')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_48')}
                </p>
                <p className="text">
                  {t('privacy_text_49')}
                </p>
                <h4 className="title">
                  {t('privacy_disclosure_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_50')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_51')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_52')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_53')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_54')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_55')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_56')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_57')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_58')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_59')}
                </p>
                <p className="text">
                  {t('privacy_text_60')}
                </p>
                <h4 className="title">
                  {t('privacy_processing_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_61')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_62')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_63')}
                </p>
                <p className="text">
                  {t('privacy_text_64')}
                </p>
                <h4 className="title">
                  {t('privacy_duration_title')}
                </h4>
                <p className="text">
                  {t('privacy_text_65')}
                </p>
                <h4 className="title">
                  {t('privacy_security_title')}
                </h4>
                <p className="text">
                  {t('privacy_text_66')}
                </p>
                <h4 className="title">
                  {t('privacy_yrights_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_67')}
                </p>
                <p className="text mb-sm-2">
		 <ul>
                  <li>{t('privacy_text_68')}</li>
		  <li>{t('privacy_text_69')}</li>
		  <li>{t('privacy_text_70')}</li>
		  <li>{t('privacy_text_71')}</li>
		  <li>{t('privacy_text_72')}</li>
		  <li>{t('privacy_text_73')}</li>
		  <li>{t('privacy_text_74')}</li>
		  <li>{t('privacy_text_75')}</li>
		 </ul>
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_76')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_77')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_78')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_79')}
                </p>
                <p className="text">
                  {t('privacy_text_80')}
                </p>
                <h4 className="title">
                  {t('privacy_tracking_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_81')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_82')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_83')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_84')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_85')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_86')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_87')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_88')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_89')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_90')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_91')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_92')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_93')}
                </p>
                <p className="text">
                  {t('privacy_text_94')}
                </p>
                <h4 className="title">
                  {t('privacy_social_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('privacy_text_95')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_96')}
                </p>
                <p className="text mb-sm-2">
                  {t('privacy_text_97')}
                </p>
                <p className="text">
                  {t('privacy_text_98')}
                </p>
                <h4 className="title">
                  {t('privacy_changes_title')}
                </h4>
                <p className="text">
                  {t('privacy_text_99')}
                </p>
                <p className="text">
                  {t('privacy_text_100')}
                </p>
              </div>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Privacy;
