import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import bgPhone from '../../assets/img/bg-phone.jpg';
import pgLogo from '../../assets/img/pietrobon_group_logo.png';

export default function HomePageFooter() {
  const today = new Date();
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <footer className="footer-section">
    <img className="footer-bg-icon" src={bgPhone} alt="#" />
      <div className="overlay">
        <div className="footer-content">
          <div className="container">
            <div className="row col-lg-12 col-md-12 d-flex justify-content-center">
              <div className="col-lg-5 col-md-5 col-sm-4 col-xs-12 footer-nav">
                <h6>{t('footer_clubs')}</h6>
                <ul>
                  <li>
                    <a href="/sponsoring-einzelsportler">{t('footer_forathlets')}</a>
                  </li>
                  <li>
                    <a href="/sponsoring-teams">{t('footer_forteams')}</a>
                  </li>
                  <li>
                    <a href="/sponsoring-vereine">{t('footer_forclubs')}</a>
                  </li>
                  <li className="hide">
                    <a href="/faq-sportler">{t('footer_faqclubs')}</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 footer-nav">
                <h6>{t('footer_sponsors')}</h6>
                <ul>
                  <li>
                    <a href="/wie-funktionierts">{t('footer_howitworks')}</a>
                  </li>
                  <li>
                    <a href="/spenden-app">{t('footer_sponsorwithapp')}</a>
                  </li>
                  <li>
                    <a href="/spendenbescheinigung">{t('footer_sponsorcertificat')}</a>
                  </li>
                  <li className="hide">
                    <a href="/faq-sponsoren">{t('footer_faqsponsors')}</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 footer-nav">
                <h6>{t('footer_mysponsor')}</h6>
                <ul>
                  <li className="hide">
                    <a href="/feedback">{t('footer_feedback')}</a>
                  </li>
                  <li>
                    <a href="/presse-medien">{t('footer_presse')}</a>
                  </li>
                  <li>
                    <a href="/jobs">{t('footer_jobs')}</a>
                  </li>
                  <li>
                    <a href="/contact">{t('footer_contact')}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="social-icon">
                  <ul className="icon-area">
                    <li className="social-nav">
                      <a
                        href="https://www.facebook.com/mysponsorofficial"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li className="social-nav">
                      <a
                        href="https://www.instagram.com/mysponsor_official/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                    <li className="social-nav">
                      <a
                        href="https://www.linkedin.com/company/my-sponsor-ag/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-linkedin" />
                      </a>
                    </li>
                    <li className="social-nav">
                      <a
                        href="https://www.youtube.com/channel/UCvou-y-rv-7NM3BMm53Qr3g"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4 col-md-12 d-flex justify-content-start reunir-content-center center-vertical mob-3">
                  <div className="footer-bottom-left">
                    <p>©{today.getFullYear()}. All Rights Reserved</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 d-flex justify-content-center reunir-content-center mob-1">
                  <div className="footer-bottom-center">
                    <a href="https://www.pietrobon.group" target="_blank" ><img src={pgLogo} width="200" alt="pietrobon-group" title="a pietrobon group company" /></a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 d-flex justify-content-end reunir-content-center center-vertical mob-2">
                  <div className="footer-bottom-right">
                    <ul>
                      <li>
                        <a href="/datenschutz">{t('privacy')}</a>
                      </li>
                      <li>
                        <a href="/agb">{t('terms')}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
