import { FETCH_ALL_EVENTS, FETCH_ALL_CLUB_EVENTS } from '../utils/types';

const initialState = {
  eventsNearby: [],
  clubEvents: [],
  isEventsNearByFetched: false,
};

export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_EVENTS:
      return { ...state, eventsNearby: action.payload, isEventsNearByFetched: true };
    case FETCH_ALL_CLUB_EVENTS:
      return { ...state, clubEvents: action.payload };
    default:
      return state;
  }
}
