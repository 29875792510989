import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import ContactForm from './ContactForm';

const Contact = () => {
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>{t('feedback_subtitle')}</h5>
              <h1 className="page-title">{t('feedback_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('feedback_text')}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <ContactForm />
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Contact;
