import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import getCroppedImg from '../../utils/cropImage';

const useStyles = makeStyles((theme) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
}));

const ImageCropModal = (props) => {
  const { open, handleClose, selectedImageURL, setCroppedImage, imageDimesions } = props;
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);

  const onCropComplete = useCallback((croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  useEffect(() => {
    setAspectRatio(imageDimesions.aspectRatio);
  }, [imageDimesions]);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(selectedImageURL, croppedAreaPixels, rotation);
      setCroppedImage(croppedImage);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <Dialog fullWidth open={open} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <div className={classes.cropContainer}>
            <Cropper
              image={selectedImageURL}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
                {t('zoom')}
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, imageZoom) => setZoom(imageZoom)}
              />
            </div>
            <div className={classes.sliderContainer}>
              <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
                {t('rotation')}
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rotate) => setRotation(rotate)}
              />
            </div>
          </div>
          <div>
            <Button
              onClick={handleClose}
              variant="outlined"
              className="cancel-button"
              classes={{ root: classes.cropButton }}
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={showCroppedImage}
              variant="outlined"
              className="btn-global-filled"
              classes={{ root: classes.cropButton }}
            >
              {t('crop')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ImageCropModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedImageURL: PropTypes.string.isRequired,
  setCroppedImage: PropTypes.func.isRequired,
  imageDimesions: PropTypes.shape({
    aspectRatio: PropTypes.number,
  }).isRequired,
};

export default ImageCropModal;
