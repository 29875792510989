import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// icons
import smartPhone from '../../../assets/img/moblie-download.png';
import bgPhone from '../../../assets/img/bg-phone.jpg';

export default function Download() {
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');
  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  return (
    <section className="download-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="download-text">
              <h5 className="download-title">{t('download_part_subtitle')}</h5>
              <h2 className="download-subtitle">{t('download_part_title')}</h2>
              <p className="download-title-describe">{t('download_part_text')}</p>
            </div>
            <div className="app-features">
              <div className="single-features">
                <div className="icon-box-outer">
                  <div className="icon-box">
                    <i className="icon-qrcode iconabout" />
                  </div>
                </div>
                <h3 className="single-features-text">{t('download_part_firsticon')}</h3>
              </div>
              <div className="single-features">
                <div className="icon-box-outer">
                  <div className="icon-box">
                    <i className="icon-choose iconabout" />
                  </div>
                </div>
                <h3 className="single-features-text">{t('download_part_secondicon')}</h3>
              </div>
              <div className="single-features">
                <div className="icon-box-outer">
                  <div className="icon-box">
                    <i className="icon-smile iconabout" />
                  </div>
                </div>
                <h3 className="single-features-text">{t('download_part_thirdicon')}</h3>
              </div>
            </div>
            <div className="download-buttons">
              <div className="group-btn">
                <a href="https://pb-g.ch/stfa" className="btn">
                  <i className="ren-google-play" />
                  {t('download_part_buttongfirst')}
                  <br />
                  <b>{t('download_part_buttongsecond')}</b>
                </a>
                <a href="https://pb-g.ch/xdSp" className="btn">
                  {t('download_part_buttonafirst')}
                  <br />
                  <b>{t('download_part_buttonasecond')}</b>
                  <i className="ren-apple-store" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="right-area">
              <img className="ellipse-01" src={bgPhone} alt="#" />
              <img className="smart-phone" src={smartPhone} alt="#" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
