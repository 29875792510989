import React from 'react';
import Carousel from 'react-multi-carousel';
import PropTypes from 'prop-types';
import 'react-multi-carousel/lib/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { displayDescription, imageBaseUrl } from '../../utils/helper';

import Icon1 from '../../assets/img/product-holder.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 10, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
};

const useStyles = makeStyles({
  eventLogo: {
    width: '100%',
    height: 160,
    objectFit: 'cover',
  },
  eventDetails: {
    display: 'grid',
  },
  partCart: {
    paddingBottom: '1rem',
  },
});

function CarouselSlider(props) {
  const classes = useStyles();
  const { items, selectedLanguage } = props;
  return (
    <Carousel partialVisible responsive={responsive}>
      {items.map((event) => {
        return (
          <div className="col text-center event-slider" key={event.id}>
            <div className="single-item">
              <div>
                {event.image ? (
                  <img
                    className={classes.eventLogo}
                    src={`${imageBaseUrl}/${event.image}`}
                    alt="#"
                  />
                ) : (
                  <img className={classes.eventLogo} src={Icon1} alt={event.title} />
                )}
              </div>
              <div className="event-text-box">
                <div className="part-prize">
                  <h3>{event.title}</h3>
                  <p className="clubs-slider-desc single-item-description">{displayDescription(event,selectedLanguage)}</p>
                </div>
                <div className={classes.partCart}>
                  <div className={classes.eventDetails}>
                    <span className="amount">
                      {event.fromDate ? moment(event.fromDate).format('DD.MM.') : ''}
                      {' - '}
                      {event.toDate ? moment(event.toDate).format('DD.MM.YYYY') : ''}
                    </span>
                    <span className="amount">{event.location}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
}

CarouselSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

CarouselSlider.defaultProps = {
  items: [],
};

export default CarouselSlider;
