import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
import CreateProductForm from '../Forms/CreateProductForm';
import Spinner from '../Spinner';
import UploadImage from '../Upload/ImageUpload';

export default function CreateProductModal(props) {
  const {
    open,
    handleOpen,
    product,
    errors,
    handleChange,
    handleProductType,
    handleProductCreate,
    isLoading,
    setProductImage,
    removeProductImage,
    isFreeSponsorModalOpen,
    handleProductDescChange,
    handleTranslateOptions,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        className="width-xl-60-modal create-product-modal"
      >
        <DialogTitle className="modal-title-center" id="responsive-dialog-title">
          {t('create_product')}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Spinner />
          ) : (
            <div>
              <CreateProductForm
                product={product}
                errors={errors}
                handleChange={handleChange}
                isFreeSponsorModalOpen={isFreeSponsorModalOpen}
                handleProductDescChange={handleProductDescChange}
                handleTranslateOptions={handleTranslateOptions}
                handleProductType={handleProductType}
              />
            </div>
          )}
          <Grid style={isLoading ? { display: 'none' } : {}} container spacing={3} className="upload-image-container">
            <Grid item xs={12} sm={6}>
              <FormHelperText style={{ color: 'red' }}>{errors.productImage}</FormHelperText>
              <UploadImage setImage={setProductImage} removeImage={removeProductImage} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="action-buttons-modal">
          {!isLoading ? (
            <>
              <Button className="cancel-button" onClick={handleOpen} variant="contained">
                {t('cancel')}
              </Button>
              <Button
                disabled={!product.isTranlationCompleted || isLoading}
                className="btn-global-filled"
                onClick={handleProductCreate}
                variant="contained"
              >
                {t('create')}
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  product: PropTypes.shape({
    isTranlationCompleted: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    productImage: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleProductCreate: PropTypes.func.isRequired,
  setProductImage: PropTypes.bool.isRequired,
  removeProductImage: PropTypes.bool.isRequired,
  isFreeSponsorModalOpen: PropTypes.bool.isRequired,
  handleProductDescChange: PropTypes.func.isRequired,
  handleTranslateOptions: PropTypes.func.isRequired,
};

CreateProductModal.defaultProps = {
  product: {},
  errors: {},
  handleChange: null,
  isLoading: false,
};
