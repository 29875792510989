import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import UserBasicDetails from '../../components/Pages/Profile/UserBasicDetails';
import Footer from '../../components/Footer/HomePageFooter';
import {
  getUserFavorites,
  getUserDonationHistory,
  updateUserDetails,
  getUserDetails,
  changeUserPassword,
  deleteUserAccount,
  requestEmailVerificationToken,
  getUserSubscriptions,
  cancelUserSubsriptions,
} from '../../actions/userActions';
import EditUserModal from '../../components/Modals/EditUserProfileModal';
import ChangePasswordModal from '../../components/Modals/ChangePasswordModal';
import { updateFiles, uploadFiles } from '../../actions/productActions';
import { validatePassword, getImageData } from '../../utils/helper';
import { getUserMemberships } from '../../actions/membershipAction';



class UserProfile extends Component {
  state = {
    tabId: 1,
    openEditModal: false,
    openDeleteConfirm:false,
    openUnsubscribeConfirm:false,
    openVerifyConfirm:false,
    openChangePasswordModal: false,
    openChangePaymentMethodModal: false,
    paymentMethodUpdateModalIsOpen: false,
    userBasicInfo: {
      firstName: '',
      lastName: '',
      email: '',
      address: {
        address1: '',
        address2: '',
        postalCode: '',
        city: '',
        country: '',
      },
    },
    passwordChangeDetails: {
      oldPassword: '',
      newPassword: '',
    },
    passwordChangeErrors: {},
    profileImageUrl: '',
    userInfoErrors: {},
    profileImage: [],
    savedProfileImage: [],
    updatedProfileImage: [],
  };

  async componentDidMount() {
    const {
      authUserData,
      getUserFavorites: userFavorites,
      getUserSubscriptions: userSubscriptions,
      getUserDonationHistory: userDonations,
      getUserMemberships: userMemberships,
    } = this.props;
    const { savedProfileImage } = this.state;
    if (authUserData && authUserData.userId) {
      const profileData = {
        firstName: authUserData.firstName,
        lastName: authUserData.lastName,
        email: authUserData.email,
        address: authUserData.address,
      };
      this.setState({ userBasicInfo: profileData });
      userFavorites({ userId: authUserData.userId });
      userSubscriptions({ userId: authUserData.userId });
      userDonations({ userId: authUserData.userId });
      userMemberships({ userId: authUserData.userId });
      const imageData = await this.getUserProfileImage(authUserData);
      if (imageData) {
        savedProfileImage.push({ image: imageData, fileName: authUserData.image });
        this.setState({ savedProfileImage, profileImageUrl: imageData });
      }
      this.props.getUserDetails(authUserData);
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      authUserData,
      getUserFavorites: userFavorites,
      getUserSubscriptions: userSubscriptions,
      getUserDonationHistory: userDonations,
      getUserMemberships: userMemberships,
    } = this.props;
    if (prevProps.authUserData !== authUserData) {
      if (authUserData && authUserData.userId) {
        const profileData = {
          firstName: authUserData.firstName,
          lastName: authUserData.lastName,
          email: authUserData.email,
          address: authUserData.address,
        };
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ userBasicInfo: profileData });
        userFavorites({ userId: authUserData.userId });
        userSubscriptions({ userId: authUserData.userId });
        userDonations({ userId: authUserData.userId });
        userMemberships({ userId: authUserData.userId });
      }
    }
  }

  getUserProfileImage = async (userData) => {
    if (userData.image) {
      const fileName = userData.image;
      const imageData = await getImageData(userData, fileName);
      return imageData;
    }
    return null;
  };

  handleTabClick = (tabIndex) => {
    this.setState({ tabId: tabIndex });
  };

  handleSingOut = () => {
    const { userLogOut: logout, history } = this.props;
    logout();
    history.push('/login');
  };

  handleEditModalOpen = () => {
    this.setState({ openEditModal: true });
  };

  handleModalClose = () => {
    this.setState({ openEditModal: false });
  };

  handleCancelSubscriptionClick = (subId, success, failures) =>{
    const data ={userId: this.props.authUserData.userId, subscriptionId: subId}
    this.props.cancelUserSubsriptions(data, success, failures)
  }

  handleChangeUserInfo = (e) => {
    const { userBasicInfo, userInfoErrors } = this.state;
    const { value, name } = e.target;
    userBasicInfo[name] = value;
    userInfoErrors[name] = '';
    this.setState({ userBasicInfo, userInfoErrors });
  };

  handleUserAddressChange = (e) => {
    const { userBasicInfo } = this.state;
    const { value, name } = e.target;
    userBasicInfo.address[name] = value;
    this.setState({ userBasicInfo });
  };

  isValidFormDetails = () => {
    let isValid = true;
    const { userBasicInfo, userInfoErrors } = this.state;
    if (!userBasicInfo.firstName) {
      isValid = false;
      userInfoErrors.firstName = 'Bitte Vornamen eingeben.';
    }
    if (!userBasicInfo.lastName) {
      isValid = false;
      userInfoErrors.lastName = 'Bitte Nachnamen eingeben.';
    }
    this.setState({ userInfoErrors });
    return isValid;
  };

  handleSave = () => {
    if (this.isValidFormDetails()) {
      const { userBasicInfo } = this.state;
      const { authUserData, updateUserDetails: updateUser } = this.props;
      const userData = { ...userBasicInfo };
      userData.id = authUserData.userId;
      updateUser(userData, () => {
        this.updateClubLogo(authUserData.userId, () => {
          this.setState({ openEditModal: false });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        });
      });
    }
  };

  removeProfileImage = (fileName) => {
    const { updatedProfileImage, savedProfileImage } = this.state;
    const imageFile = savedProfileImage.find((file) => file.fileName === fileName);
    if (imageFile) {
      imageFile.action = 'remove';
      updatedProfileImage.push(imageFile);
      this.setState({ updatedProfileImage });
    }
    this.setState({ savedProfileImage: [] });
  };

  updateClubLogo = (id, cb) => {
    const { profileImage, updatedProfileImage } = this.state;
    const {
      updateFiles: updateNewProfileImage,
      uploadFiles: uploadNewImage,
      getUserDetails: fetchUserDetails,
      authUserData,
    } = this.props;
    const fileData = new FormData();
    const fileName = updatedProfileImage.length ? updatedProfileImage[0].fileName : '';
    fileData.append('file', profileImage[0]);
    fileData.append('entity', 'user');
    fileData.append('column', 'image');
    fileData.append('id', id);

    if (fileName) {
      fileData.append('fileName', fileName);
      updateNewProfileImage(fileData, () => {
        fetchUserDetails(authUserData);
        cb();
      });
    } else {
      uploadNewImage(fileData, () => {
        fetchUserDetails(authUserData);
        cb();
      });
    }
  };

  setNewProfileImage = (file) => {
    const imageFile = [];
    imageFile.push(file);
    this.setState({ profileImage: imageFile });
  };

  handleChangePassword = () => {
    this.setState({ openChangePasswordModal: true });
  };

  handleCloseChangePassword = () => {
    this.setState({ openChangePasswordModal: false });
  };

  handleChangePasswordValues = (e) => {
    e.preventDefault();
    const { passwordChangeDetails, passwordChangeErrors } = this.state;
    const { value, name } = e.target;
    passwordChangeDetails[name] = value;
    passwordChangeErrors[name] = '';
    this.setState({ passwordChangeDetails, passwordChangeErrors });
  };

  validateChangePassword = () => {
    const { passwordChangeDetails, userBasicInfo, passwordChangeErrors } = this.state;
    let isValid = true;
    if (!passwordChangeDetails.oldPassword) {
      isValid = false;
      passwordChangeErrors.oldPassword = 'Bitte das alte Passwort eingeben.';
    }
    if (
      !passwordChangeDetails.newPassword ||
      !validatePassword(passwordChangeDetails.newPassword)
    ) {
      isValid = false;
      passwordChangeErrors.newPassword = 'Bitte das neue Passwort eingeben.';
    }
    if (!userBasicInfo.email) {
      isValid = false;
    }
    this.setState({ passwordChangeErrors });
    return isValid;
  };

  handleChangePasswordSave = (e) => {
    e.preventDefault();
    const { changeUserPassword: changePassword } = this.props;
    const { passwordChangeDetails, userBasicInfo } = this.state;
    if (this.validateChangePassword()) {
      const passwordChangeData = {
        email: userBasicInfo.email,
        ...passwordChangeDetails,
      };
      changePassword(passwordChangeData, () => {
        this.setState({ openChangePasswordModal: false });
      });
    }
  };

  handleCountryChange = (option, name) => {
    if (option) {
      const { userBasicInfo } = this.state;
      userBasicInfo.address[name] = option.name;
      this.setState({ userBasicInfo });
    }
  };

  handleDeleteUserAccount = () => {
    const { authUserData,  } = this.props;
    const deleteUserAccountData = {
      userId: authUserData.userId
    };
    this.props.deleteUserAccount(deleteUserAccountData);
  }

  verificationEmailRequest=()=>{
    const data = {email: this.props.authUserData.email}
    this.props.requestEmailVerificationToken(data)
  }

  setConfirmOpen = (state) => {
    this.setState({ openDeleteConfirm: state });
  }

  setConfirmOpenUnsubscribe = (state) => {
    this.setState({ openUnsubscribeConfirm: state });
  }

  setVerifyConfirmOpen = (state) => {
    this.setState({ openVerifyConfirm: state });
  }

  setOpenChangePaymentMethodModal = (state) => {
    this.setState({openChangePaymentMethodModal : state})
  }

  setPaymentMethodUpdateModalIsOpen =(state)=>{
    this.setState({paymentMethodUpdateModalIsOpen:state})
  }

  render() {
    const {
      tabId,
      openEditModal,
      openChangePasswordModal,
      userBasicInfo,
      passwordChangeDetails,
      passwordChangeErrors,
      userInfoErrors,
      savedProfileImage,
      profileImageUrl,
      openDeleteConfirm,
      openVerifyConfirm,
      openUnsubscribeConfirm,
      openChangePaymentMethodModal,
      paymentMethodUpdateModalIsOpen
    } = this.state;
    const { authUserData, clubs, donations, isLoading,userSubscriptions , userMemberships} = this.props;

    return (
      <Fragment>
        <div className="profile-header">
          <Header />
        </div>
        <UserBasicDetails
          profileImage={profileImageUrl}
          userDetails={authUserData}
          tabId={tabId}
          handleTabClick={this.handleTabClick}
          donationData={donations}
          favorites={clubs}
          handleEditModalOpen={this.handleEditModalOpen}
          handleChangePassword={this.handleChangePassword}
          handleDeleteUserAccount={this.handleDeleteUserAccount}
          verificationEmailRequest={this.verificationEmailRequest}
          setConfirmOpen={this.setConfirmOpen}
          openDeleteConfirm={openDeleteConfirm}
          setConfirmOpenUnsubscribe={this.setConfirmOpenUnsubscribe}
          openUnsubscribeConfirm={openUnsubscribeConfirm}
          openVerifyConfirm={openVerifyConfirm}
          setVerifyConfirmOpen={this.setVerifyConfirmOpen}
          userSubscriptions={userSubscriptions}
          userMemberships={userMemberships}
          handleCancelSubscriptionClick={this.handleCancelSubscriptionClick}
          openChangePaymentMethodModal = {openChangePaymentMethodModal}
          setOpenChangePaymentMethodModal = {this.setOpenChangePaymentMethodModal}
          setPaymentMethodUpdateModalIsOpen={this.setPaymentMethodUpdateModalIsOpen}
          paymentMethodUpdateModalIsOpen={paymentMethodUpdateModalIsOpen}
        />
        <EditUserModal
          userProfileErrors={userInfoErrors}
          userDetails={userBasicInfo}
          open={openEditModal}
          handleChange={this.handleChangeUserInfo}
          handleAddressChange={this.handleUserAddressChange}
          handleClose={this.handleModalClose}
          handleSave={this.handleSave}
          savedProfileImage={savedProfileImage}
          setProfileImage={this.setNewProfileImage}
          removeProfileImage={this.removeProfileImage}
          handleCountryChange={this.handleCountryChange}
          isLoading={isLoading}
        />
        <ChangePasswordModal
          open={openChangePasswordModal}
          handleClose={this.handleCloseChangePassword}
          usedata={passwordChangeDetails}
          passwordChageErrors={passwordChangeErrors}
          handleChange={this.handleChangePasswordValues}
          handleSave={this.handleChangePasswordSave}
        />
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserData: state.user.authUserData,
  clubs: state.user.favoriteClubs,
  donations: state.user.userDonationData,
  isLoading: state.spinner.isLoading,
  userSubscriptions: state.user.userSubscriptions,
  userMemberships: state.member.userMemberships
});

const mapDispatchToProps = {
  getUserFavorites,
  getUserSubscriptions,
  getUserDonationHistory,
  getUserDetails,
  updateUserDetails,
  updateFiles,
  uploadFiles,
  changeUserPassword,
  deleteUserAccount,
  requestEmailVerificationToken,
  cancelUserSubsriptions,
  getUserMemberships,
};

UserProfile.propTypes = {
  userDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  authUserData: PropTypes.shape({
    userId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.string,
    address: PropTypes.shape({}),
  }).isRequired,
  userLogOut: PropTypes.func.isRequired,
  getUserFavorites: PropTypes.func.isRequired,
  getUserSubsriptions: PropTypes.func.isRequired,
  getUserMemberships: PropTypes.func.isRequired,
  getUserDonationHistory: PropTypes.func.isRequired,
  updateUserDetails: PropTypes.func.isRequired,
  updateFiles: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  changeUserPassword: PropTypes.func.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  cancelUserSubsriptions: PropTypes.func.isRequired,
  requestEmailVerificationToken: PropTypes.func.isRequired,
  clubs: PropTypes.arrayOf(PropTypes.shape({})),
  userSubscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  userMemberships: PropTypes.arrayOf(PropTypes.shape({})),
  donations: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isLoading: PropTypes.bool,
};

UserProfile.defaultProps = {
  userDetails: {},
  clubs: [],
  userSubscriptions: [],
  donations: [],
  isLoading: false,
  userMemberships: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfile));
