import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(3),
  },
  title: {
    marginTop: 30,
  },
}));

const Success = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const selectedLanguageValue = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguageValue);
  }, [selectedLanguageValue, i18n]);

  return (
    <Box className={classes.box}>
      <Typography variant="h2" align="center">
        {t('thank_you')}!
      </Typography>
      <Typography component="p" align="center" className={classes.title}>
        {t('registration_success_text')}
      </Typography>
    </Box>
  );
};

export default Success;
