/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import PlaceHolderImage from '../../assets/img/placeholder-image.png';
import { imageBaseUrl, dataURItoBlob } from '../../utils/helper';
import ImageCrop from '../Modals/CropImageModal';

const MAX_SIZE = 1024 * 1024 * 2;
export default function LogoUpload(props) {
  const { setImage, removeImage, savedImages } = props;
  const [file, setFile] = useState('');
  const [originalFile, setOriginalFile] = useState('');
  const [selectedImageURL, setSelectedImageURL] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [openCropModal, setCropModal] = useState(false);

  useEffect(() => {
    if (savedImages && savedImages.length > 0) {
      const savedImageUrl = `${imageBaseUrl}/${savedImages[0].logo}`;
      setFile(savedImageUrl);
    }
  }, [savedImages]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesCount = event.target.files.length;
      if (filesCount > 1) {
        event.preventDefault();
        setUploadError('cannot upload more than one image');
      } else if (filesCount === 1) {
        const newFile = event.target.files[0];
        if (newFile.size > MAX_SIZE) {
          setUploadError('please upload less than 2MB size of image');
        } else {
          const dataUri = URL.createObjectURL(newFile);
          // setImage(newFile);
          // setFile(dataUri);
          setCropModal(true);
          setOriginalFile(newFile);
          setSelectedImageURL(dataUri);
        }
      }
    }
  };

  const deleteImage = () => {
    if (savedImages && savedImages.length > 0) {
      removeImage(savedImages[0].logo);
    }
    setFile('');
  };

  const isDisabled = !!file;
  let url = '';
  if (file) {
    url = file;
  } else {
    url = PlaceHolderImage;
  }

  const handleCloseCropModal = () => {
    setCropModal(false);
  };

  const setCroppedImage = (dataURL) => {
    setFile(dataURL);
    const blob = dataURItoBlob(dataURL);
    const resultFile = new File([blob], originalFile.name, { type: originalFile.type });
    setImage(resultFile);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="avatar-upload">
            <div className="avatar-edit">
              <form action="" method="post" id="form-image">
                <input
                  disabled={isDisabled}
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageChange}
                />
                {!isDisabled && savedImages.length === 0 ? (
                  <label htmlFor="imageUpload" />
                ) : (
                  <DeleteIcon className="club-item" onClick={() => deleteImage(url)} />
                )}
              </form>
            </div>
            <div className="avatar-preview">
              <img
                className="profile-user-img club-logo-holder img-responsive img-circle"
                id="imagePreview"
                src={url}
                alt="profile"
              />
            </div>
          </div>
        </Grid>
        {uploadError ? (
          <FormHelperText style={{ color: 'red' }}>{uploadError}</FormHelperText>
        ) : null}
      </Grid>
      <ImageCrop
        open={openCropModal}
        handleClose={handleCloseCropModal}
        setCroppedImage={setCroppedImage}
        selectedImageURL={selectedImageURL}
        imageDimesions={{ unit: 'px', width: 200, aspectRatio: 1 / 1 }}
      />
    </Fragment>
  );
}

LogoUpload.propTypes = {
  setImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  savedImages: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string,
    })
  ),
};

LogoUpload.defaultProps = {
  savedImages: [],
};
