import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { imageBaseUrl } from '../../../utils/helper';
import defaultImage from '../../../assets/img/product-holder.png';
import EditProductModal from '../../Modals/EditProductModal';

const ClubProducts = (props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const {
    handleOpen,
    data,
    isFreeProductCreated,
    handleDeactivateProduct,
    handleFreeProductModal,
  } = props;

  const selectedLanguage = localStorage.getItem('language') || 'en';
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const displayProducts = (products) => {
    if (products && products.length > 0) {
      const girdCount = products.length > 2 ? 4 : 6;
      return products.map((product) => (
        <Grid item xs={12} sm={girdCount}>
          <Paper>
            <div className="vertical-item content-padding text-center with-background rounded bottom-color-border item-size">
              <div className="item-media event-img-div top-rounded overflow-hidden">
                {product.image ? (
                  <img className="event-img" src={`${imageBaseUrl}/${product.image}`} alt="" />
                ) : (
                  <img className="event-img" src={defaultImage} alt="" />
                )}
                <div className="media-links no-overlay" />
              </div>
              <div className="item-content with-border bottom-rounded">
                <h5 className="entry-title">{product.title}</h5>
                <div className="price">
                  <span className="amount">
                    {product.price ? (
                      <>
                        CHF&#160;
                        {product.price}
                      </>
                    ) : (
                      <div style={{ padding: 12 }} />
                    )}
                  </span>
                </div>
                <div>
                  <Button
                    onClick={() => handleEdit(product)}
                    className="btn-global"
                    variant="outlined"
                  >
                    {t('edit')}
                  </Button>
                </div>
                <div style={{ margin: 5 }}>
                  <Button
                    onClick={(e) => handleDeactivateProduct(e, product.id)}
                    className="btn-global"
                    variant="outlined"
                  >
                    {t('deactivate')}
                  </Button>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      ));
    }
    return null;
  };

  return (
    <div className="tab-pane in active product-tab" id="tab2">
      <div className="product-create-btn flex-row justify-content-between">
        <h4>{t('products')}</h4>
        <Button onClick={handleOpen} className="btn-global" variant="outlined">
          {t('create_product')}
        </Button>
      </div>
      {!isFreeProductCreated ? (
        <div className="product-create-btn">
          <Button onClick={handleFreeProductModal} className="btn-global" variant="outlined">
            {t('create_product_open_price')}
          </Button>
        </div>
      ) : null}
      <div>
        <Grid container spacing={3}>
          {displayProducts(data)}
        </Grid>
      </div>
      <EditProductModal open={open} handleClose={handleClose} product={selectedProduct} />
    </div>
  );
};

ClubProducts.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  handleFreeProductModal: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isFreeProductCreated: PropTypes.bool.isRequired,
  handleDeactivateProduct: PropTypes.func.isRequired,
};

ClubProducts.defaultProps = {
  data: [],
};

export default ClubProducts;
