import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { getClubTopSponsers } from '../../../actions/productActions';
import { getImageData } from '../../../utils/helper';
import defaultImage from '../../../assets/img/product-holder.png';

const findHasMore = (currentList, totalElement) => {
  return currentList.length < totalElement;
};

const useStyles = makeStyles({
  loadMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const TopSponser = (props) => {
  const classes = useStyles();
  const { data } = props;
  const dispatch = useDispatch();
  const { authUserData } = useSelector((state) => state.user);
  const { totalTopSponsors } = useSelector((state) => state.product);
  const [page, setPage] = useState(0);
  const [sponsorsList, setSponsorList] = useState([]);
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  useEffect(() => {
    const getNewSponsorList = async () => {
      const newSponsorList = [];
      for (let sponsorItem = 0; sponsorItem < data.length; sponsorItem += 1) {
        const item = data[sponsorItem];
        if (data[sponsorItem].userImage) {
          const fileName = data[sponsorItem].userImage;
          // eslint-disable-next-line no-await-in-loop
          const imageData = await getImageData(authUserData, fileName);
          item.imageUrl = imageData || '';
        }
        newSponsorList.push(item);
      }
      setSponsorList(newSponsorList);
    };
    getNewSponsorList();
  }, [data, authUserData]);

  const fetchData = () => {
    dispatch(
      getClubTopSponsers({
        clubId: authUserData.clubId,
        pageId: page + 1,
        pageSize: 3,
      })
    );
    setPage(page + 1);
  };

  const hasMore = totalTopSponsors ? findHasMore(data, totalTopSponsors) : false;

  const displayTopSponsers = (products) => {
    if (products && products.length > 0) {
      return products.map((product) => (
        <Grid item xs={12} sm={4}>
          <Paper>
            <div className="vertical-item content-padding text-center with-background rounded bottom-color-border item-size">
              <div className="item-media top-rounded overflow-hidden">
                {product.userImage ? (
                  <img className="event-img" src={`${product.imageUrl}`} alt="" />
                ) : (
                  <img className="event-img" src={defaultImage} alt="" />
                )}
                <div className="media-links no-overlay" />
              </div>
              <div className="item-content with-border bottom-rounded">
                <h5 className="entry-title">{product.firstName}</h5>
                <div className="price">
                  <span className="amount">CHF {product.totalAmount}</span>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      ));
    }
    return null;
  };

  return (
    <div className="tab-pane in active product-tab" id="tab2">
      <h4>{t('top_sponsers')}</h4>
      <div style={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {displayTopSponsers(sponsorsList)}
        </Grid>
      </div>
      <div>
        <Grid container spacing={3}>
          {hasMore ? (
            <Grid item xs={12} sm={12} onClick={fetchData}>
              <div className={classes.loadMore}>
                <Button variant="outlined" className="btn-global">
                  {t('load_more')}
                </Button>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </div>
  );
};

TopSponser.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      userImage: PropTypes.string,
    })
  ),
};

TopSponser.defaultProps = {
  data: [],
};

export default TopSponser;
