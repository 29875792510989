import moment from 'moment';
import store from '../store';

export const baseUrl = process.env.REACT_APP_API_URL;
export const googleApiKey = process.env.REACT_APP_API_PLACE_API_KEY;
export const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
export const stripeApiKey = process.env.REACT_APP_API_STRIPE_API_KEY;
export const translateAuthKey = process.env.REACT_APP_TRANSLATE_AUTH_KEY;
export const translateApiUrl = process.env.REACT_APP_TRANSLATE_API_URL;
export const twintPaymentPageUrl = process.env.REACT_APP_TWINT_REDIRECT_URL;
export const recaptchaWebsiteKey= process.env.REACT_APP_RECAPTCHA_WEBSITE_KEY

export const headers = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const multiPartHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const authHeader = (authToken) => {
  const authHeaders = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (authToken) {
    authHeaders.headers.Authorization = `Bearer ${authToken}`;
  } else {
    const { token } = store.getState().user.authUserData;
    authHeaders.headers.Authorization = `Bearer ${token}`;
  }
  return authHeaders;
};

export const multipartAuthHeader = (authToken) => {
  const authHeaders = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  if (authToken) {
    authHeaders.headers.Authorization = `Bearer ${authToken}`;
  } else {
    const { token } = store.getState().user.authUserData;
    authHeaders.headers.Authorization = `Bearer ${token}`;
  }
  return authHeaders;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const formatDate = (date) => {
  if (date) {
    return moment(date).format('DD.MM.YYYY');
  }
  return null;
};

export const validatePassword = (text) => {
  const validString = /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return validString.test(text);
};

export const docTypes = {
  clubRegistration: 'doc1',
  clubLicenseCopy: 'doc2',
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const daysInMonth = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];

export const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const hourLabels = [
  '00 - 03',
  '03 - 06',
  '06 - 09',
  '09 - 12',
  '12 - 15',
  '15 - 18',
  '18 - 21',
  '21 - 24',
];

export const languageOptions = [
  { id: '1', name: 'English', value: 'EN' },
  { id: '2', name: 'German', value: 'DE' },
  { id: '3', name: 'French', value: 'FR' },
  { id: '4', name: 'Italian', value: 'IT' },
];

export const targetLanguages = {
  english: 'EN',
  german: 'DE',
  french: 'FR',
  italian: 'IT',
};

export const displayDescription = (item, selectedLanguage) => {
  let description = '';
  if (selectedLanguage === 'en') {
    description = item.description;
  } else if (selectedLanguage === 'du') {
    description = item.descriptionDe;
  } else if (selectedLanguage === 'fr') {
    description = item.descriptionFr;
  } else if (selectedLanguage === 'it') {
    description = item.descriptionIt;
  }
  return description;
};

export const translateDropdownDefultValue = (selectedLanguage) => {
  let value = '';
  if (selectedLanguage === 'en') {
    value = targetLanguages.english;
  } else if (selectedLanguage === 'du') {
    value = targetLanguages.german;
  } else if (selectedLanguage === 'fr') {
    value = targetLanguages.french;
  } else if (selectedLanguage === 'it') {
    value = targetLanguages.italian;
  }
  return value;
};

export const getTargetLanguagesAndDescriptions = (
  translatedDescriptionList,
  descriptionText,
  selectedLanguage
) => {
  const targetLanguagesList = [];
  const descriptionList = translatedDescriptionList;
  if (selectedLanguage === 'en') {
    descriptionList.EN = descriptionText;
    targetLanguagesList.push(
      targetLanguages.italian,
      targetLanguages.french,
      targetLanguages.german
    );
  } else if (selectedLanguage === 'du') {
    descriptionList.DE = descriptionText;
    targetLanguagesList.push(
      targetLanguages.italian,
      targetLanguages.french,
      targetLanguages.english
    );
  } else if (selectedLanguage === 'fr') {
    descriptionList.FR = descriptionText;
    targetLanguagesList.push(
      targetLanguages.italian,
      targetLanguages.german,
      targetLanguages.english
    );
  } else if (selectedLanguage === 'it') {
    descriptionList.IT = descriptionText;
    targetLanguagesList.push(
      targetLanguages.french,
      targetLanguages.german,
      targetLanguages.english
    );
  }
  return { targetLanguagesList, descriptionList };
};

export const findDescriptionField = (selectedLanguage) => {
  let fieldName = '';
  if (selectedLanguage === 'en') {
    fieldName = 'description';
  } else if (selectedLanguage === 'du') {
    fieldName = 'descriptionDe';
  } else if (selectedLanguage === 'fr') {
    fieldName = 'descriptionFr';
  } else if (selectedLanguage === 'it') {
    fieldName = 'descriptionIt';
  }
  return fieldName;
};

export const getImageData = async (userData, fileName) => {
  const imageObj = await fetch(`${baseUrl}/files/name`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${userData.token}`,
    },
    body: JSON.stringify({
      fileName,
    }),
  });
  const imageData = await imageObj.text();
  return imageData;
};

export const isDecimalNumber = (e) => {
  if (
    !(
      e.charCode === 0 ||
      (e.charCode >= 48 && e.charCode <= 57) ||
      (e.charCode === 46 && e.target.value.indexOf('.') < 0)
    )
  ) {
    e.preventDefault();
  }
};


export const getFileURL = async (fileName, userData) => {
  if (fileName == null) return '';
  const fileObj = await fetch(`${baseUrl}/files/name`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${userData.token}`,
    },
    body: JSON.stringify({
      fileName,
    }),
  });
  const fileUrl = await fileObj.text();
  return fileUrl;
};

export const clubRoles = ['ROLE_CLUB', 'ROLE_CLUB_FINANCE', 'ROLE_CLUB_OPERATOR'];

export const getClubRole = (authUserData) => {
  if (authUserData == null || authUserData.roles== null) return null;
  const userRoles = Array.from(authUserData.roles)
  let clubRole = clubRoles.filter(r => userRoles.includes(r))
  return clubRole[0]
};