import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  editClubProduct,
  uploadProductAndEventImages,
  updateProductAndEventImages,
  getAllClubProducts,
  descriptionTranslate,
} from '../../actions/productActions';
import Spinner from '../Spinner';
import UploadImage from '../Upload/ImageUpload';
import {
  findDescriptionField,
  getTargetLanguagesAndDescriptions,
  isDecimalNumber,
} from '../../utils/helper';

const productBasicDetails = {
  id: '',
  title: '',
  price: '',
  expiryDate: '2022-12-31',
  crowdFunding: false,
  description: '',
  descriptionDe: '',
  descriptionFr: '',
  descriptionIt: '',
};

const translatedDescription = {
  EN: '',
  DE: '',
  FR: '',
  IT: '',
};
export default function EditProductModal(props) {
  const { t, i18n } = useTranslation();
  const { authUserData } = useSelector((state) => state.user);
  const { open, handleClose, product } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(productBasicDetails);
  const [translatedText, setTranslatedText] = useState(translatedDescription);
  const [isLoading, setIsLoading] = useState(false);
  const [isTranslationCompleted, setTranslationComplete] = useState(false);
  const [productError, setProductError] = useState({});
  const [uploadedImg, setUploadedImg] = useState('');
  const [productImage, setProductImage] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const SelectedLanguageValue = localStorage.getItem('language') || 'du';

  useEffect(() => {
    if (product) {
      setSelectedProduct({
        id: product.id,
        title: product.title,
        price: product.price,
        expiryDate: product.expiryDate,
        image: product.image,
        description: product.description,
        descriptionDe: product.descriptionDe,
        descriptionFr: product.descriptionFr,
        descriptionIt: product.descriptionIt,
        crowdFunding: product.crowdFunding ? 'true' : 'false',
      });
      setUploadedImg(product.image);
      setTranslatedText(translatedDescription);
      if (product && !product.description) {
        setTranslationComplete(true);
      }
    }
  }, [product]);

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  const handleProductDetailChange = (e) => {
    const { value, name } = e.target;
    setProductError({
      ...productError,
      [name]: '',
    });
    setSelectedProduct({
      ...selectedProduct,
      [name]: value,
    });
  };

  const isValidProduct = () => {
    let isValid = true;
    const basicFieldErrors = {};
    if (!selectedProduct.title) {
      basicFieldErrors.title = 'please enter title';
      isValid = false;
    }
    if (!selectedProduct.description) {
      basicFieldErrors.description = 'please enter description';
      isValid = false;
    }
    if (!selectedProduct.expiryDate) {
      basicFieldErrors.expiryDate = 'please enter expiryDate';
      isValid = false;
    }
    if (!selectedProduct.image && productImage.length === 0) {
      isValid = false;
      basicFieldErrors.productImage = 'please upload product image';
    }
    setProductError(basicFieldErrors);
    return isValid;
  };

  const saveProductCallBack = () => {
    const { clubId } = authUserData;
    dispatch(getAllClubProducts({ clubId }));
    setIsLoading(false);
    handleClose();
  };

  const handleSaveProduct = () => {
    if (isValidProduct()) {
      const newProductObj = {
        ...selectedProduct,
        crowdFunding: selectedProduct.crowdFunding === 'true',
      };
      setIsLoading(true);
      dispatch(
        editClubProduct(newProductObj, () => {
          if (productImage.length > 0) {
            const fileData = new FormData();
            fileData.append('file', productImage[0]);
            fileData.append('entity', 'product');
            fileData.append('column', 'image');
            fileData.append('id', selectedProduct.id);

            if (uploadedImg) {
              fileData.append('fileName', uploadedImg);
              dispatch(
                updateProductAndEventImages(fileData, () => {
                  saveProductCallBack();
                })
              );
            } else {
              dispatch(
                uploadProductAndEventImages(fileData, () => {
                  saveProductCallBack();
                })
              );
            }
          } else {
            saveProductCallBack();
          }
        })
      );
    }
  };

  const setNewImage = (file) => {
    const images = [];
    images.push(file);
    setProductImage(images);
  };

  const handleDescriptionChange = async (e) => {
    const descriptionText = e.target.value;
    setTranslatedText(translatedDescription);
    const targetLanguagesAndDescriptions = getTargetLanguagesAndDescriptions(
      translatedText,
      descriptionText,
      SelectedLanguageValue
    );
    const targetTranslationList = targetLanguagesAndDescriptions.targetLanguagesList || [];
    const translatedTextList = targetLanguagesAndDescriptions.descriptionList;

    // eslint-disable-next-line no-restricted-syntax
    for (const targetLang of targetTranslationList) {
      // eslint-disable-next-line no-await-in-loop
      const response = await descriptionTranslate(descriptionText, targetLang);
      if (response) {
        translatedTextList[targetLang] = response;
      }
    }

    setSelectedProduct({
      ...selectedProduct,
      description: translatedTextList.EN,
      descriptionDe: translatedTextList.DE,
      descriptionFr: translatedTextList.FR,
      descriptionIt: translatedTextList.IT,
    });
    setTranslationComplete(true);
    setTranslatedText(translatedTextList);
  };

  const displayDescriptionField = () => {
    const fieldName = findDescriptionField(SelectedLanguageValue);
    return (
      <TextField
        id="standard-description-input"
        name={fieldName}
        label={t('product_description')}
        required
        fullWidth
        multiline
        rows={4}
        inputProps={{
          maxLength: 240,
        }}
        value={selectedProduct[fieldName]}
        error={productError[fieldName]}
        helperText={productError[fieldName]}
        InputLabelProps={{ shrink: true }}
        onChange={handleProductDetailChange}
        onBlur={handleDescriptionChange}
      />
    );
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        className="width-xl-60-modal create-prodct-modal"
      >
        <DialogTitle className="modal-title-center" id="responsive-dialog-title">
          {t('edit_product')}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Spinner />
          ) : (
            <form noValidate autoComplete="off">
              <Grid container spacing={2} xs={12} sm={7}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="standard-name-input"
                    name="title"
                    label={t('product_title')}
                    required
                    fullWidth
                    value={selectedProduct.title}
                    error={productError.title}
                    helperText={productError.title}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleProductDetailChange}
                    inputProps={{
                      maxLength: 25,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="standard-price-input"
                    name="price"
                    label={t('product_price')}
                    required
                    fullWidth
                    value={selectedProduct.price}
                    error={productError.price}
                    helperText={productError.price}
                    onKeyPress={isDecimalNumber}
                    onChange={handleProductDetailChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="standard-date-input"
                    name="expiryDate"
                    type="date"
                    label={t('product_expiry_date')}
                    defaultValue="2021-12-31"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={selectedProduct.expiryDate}
                    error={productError.expiryDate}
                    helperText={productError.expiryDate}
                    onChange={handleProductDetailChange}
                  />
                </Grid>
                
                </Grid>
                <Grid container spacing={3} sm={12} className="description-product-modal">
                <Grid item xs={12} sm={12}>
                  {displayDescriptionField()}
                </Grid>
              </Grid>
            </form>
          )}
          <Grid style={isLoading ? { display: 'none' } : {}} container spacing={3} className="upload-image-container">
            <Grid item xs={12} sm={3}>
              <FormHelperText style={{ color: 'red' }}>{productError.productImage}</FormHelperText>
              <UploadImage
                setImage={setNewImage}
                removeImage={() => setProductImage([])}
                alreadyUploadedImage={uploadedImg}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="action-buttons-modal">
          {!isLoading ? (
            <>
              <Button className="cancel-button" variant="outlined" onClick={handleClose}>
                {t('cancel')}
              </Button>
              <Button
                disabled={!isTranslationCompleted || isLoading}
                className="btn-global-filled"
                variant="outlined"
                onClick={handleSaveProduct}
              >
                {t('save')}
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditProductModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    expiryDate: PropTypes.string,
    image: PropTypes.string,
    crowdFunding: PropTypes.bool,
    descriptionDe: PropTypes.string,
    descriptionFr: PropTypes.string,
    descriptionIt: PropTypes.string,
  }),
  errors: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    expiryDate: PropTypes.string,
  }),
};

EditProductModal.defaultProps = {
  product: {},
  errors: {},
};
