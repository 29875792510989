import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles, TextField
} from '@material-ui/core';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { addExternalPayment } from '../../../actions/membershipAction';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  viewBtn: {
    textDecoration: 'none'
  },
  scrollX: {
    overflow: 'auto'
  }
}));


const PaymentHistory = ({
                          className,
                          details,
                          t,
                          member,
                          ...rest
                        }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [dateErrors, setDateErrors] = useState(new Array(details.length).fill(false));

  const checkPaymentDate = (index, invoiceId, amount) => {
    // Retrieve the date from the TextField component
    let input = document.getElementById(`paymentDate-${index}`);

    // Check if the date is valid
    if (Date.parse(input.value)) {
      let data = {
        amount: amount,
        invoiceId: invoiceId,
        userId: member.user.id,
        productId: member.product.id
      };

      dispatch(addExternalPayment(data, t('external_payment_success'), t('external_payment_error')));

      // Reset the error state for this date input field
      setDateErrors(prevErrors => {
        const newErrors = [...prevErrors];
        newErrors[index] = false;
        return newErrors;
      });
    } else {
      // If the date is not valid, set the error state for this date input field to true
      setDateErrors(prevErrors => {
        const newErrors = [...prevErrors];
        newErrors[index] = true;
        return newErrors;
      });
    }
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      id="membershipTab"
    >
      <PerfectScrollbar id="membershipTableContainer">
        <Box className={classes.scrollX} id="membershipTable">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('invoiceId')}
                </TableCell>
                <TableCell>
                  {t('period_from')}
                </TableCell>
                <TableCell>
                  {t('period_to')}
                </TableCell>
                <TableCell>
                  {t('payment_amount')}
                </TableCell>
                <TableCell>
                  {t('paid')}
                </TableCell>
                <TableCell>
                  {t('payment_date')}
                </TableCell>
                <TableCell>
                  {t('external_payment')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details.length > 0 ? (
                details.map((detail, index) => (
                <TableRow
                  hover
                  key={detail.id}
                >
                  <TableCell>
                    {detail.id}
                  </TableCell>
                  <TableCell>
                    {moment(detail.period).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(detail.nextPeriod).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    {detail.amount}
                  </TableCell>
                  <TableCell>
                    <span
                      className={'membershipStatus ' + (detail.membershipPayment ? 'membershipPaid' : 'membershipUnpaid')}
                    />
                  </TableCell>
                  <TableCell>
                    {
                      detail.membershipPayment ?
                        moment(detail.membershipPayment.createdDate).format('DD.MM.YYYY')
                        :
                        <TextField
                          id={`paymentDate-${index}`}
                          className="paymentDate"
                          type="date"
                          error={dateErrors[index]}
                          helperText={dateErrors[index] ? "Invalid date" : ""}
                        />
                    }

                  </TableCell>
                  <TableCell>
                    {
                      detail.membershipPayment ? (
                          <span
                            className={'membershipStatus ' + (detail.membershipPayment.externalPayment ? 'membershipPaid' : 'membershipUnpaid')}
                          />
                        ) :
                        <Button variant="contained" className="btn-tertiary" onClick={() => checkPaymentDate(index, detail.id, detail.amount)}>
                          {t('mark_as_paid')}
                        </Button>
                    }
                  </TableCell>
                </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="p-5" colSpan={7}>
                    {t('no_payments_to_display')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

PaymentHistory.propTypes = {
  className: PropTypes.string,
  details: PropTypes.array.isRequired
};

export default PaymentHistory;
