import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import publicIp from 'public-ip';
import ipLocation from 'iplocation';
import Header from '../../components/Header';
import Banner from '../../components/Pages/Home/Banner';
import AboutUs from '../../components/Pages/Home/AboutUs';
import Clubs from '../../components/Pages/Home/Clubs';
import Advantages from '../../components/Pages/Home/Advantages';
import Download from '../../components/Pages/Home/Download';
import Events from '../../components/Pages/Home/Events';
import Footer from '../../components/Footer/HomePageFooter';
import { getClubsNearBy } from '../../actions/clubActions';
import { getEventsNearBy } from '../../actions/eventActions';

import '../../assets/css/icofont.min.css';
import '../../assets/css/magnific-popup.css';
import '../../assets/css/app.css';

class Home extends Component {
  state = {
    pageId: 0,
    pageSize: 5,
  };

  async componentDidMount() {
    const geoNavigator = await navigator.permissions.query({ name: 'geolocation' });
    if (geoNavigator.state === 'granted') {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        if (latitude && longitude) {
          this.callNearByAPIs(position.coords);
        }
      });
    } else {
      const ipAddress = await publicIp.v4();
      const locationDetails = await ipLocation(ipAddress);
      if (locationDetails && locationDetails.latitude && locationDetails.latitude) {
        this.callNearByAPIs(locationDetails);
      }
    }
  }

  callNearByAPIs(locationData) {
    const { pageId, pageSize } = this.state;
    const {
      getClubsNearBy: fetchAllClubs,
      getEventsNearBy: fetchAllEvents,
      allClubs,
      allEvents,
    } = this.props;
    const data = {
      pageId,
      pageSize,
      latitude: locationData.latitude,
      longitude: locationData.longitude,
    };
    if (allClubs && allClubs.length === 0) {
      data.pageSize = 8;
      fetchAllClubs(data);
    }
    if (allEvents && allEvents.length === 0) {
      fetchAllEvents(data);
    }
  }

  render() {
    const { allClubs, allEvents } = this.props;
    return (
      <Fragment>
        <Header />
        <Banner />
        <Clubs clubList={allClubs} />
        <Advantages />
        <AboutUs />
        <Events events={allEvents} />
        <Download />
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allClubs: state.club.nearByClubs,
  allEvents: state.event.eventsNearby,
  isEventsDataFetched: state.event.isEventsNearByFetched,
  isClubsDataFetched: state.club.isRetrieveClubsNearBy,
});

const mapDispatchToProps = {
  getClubsNearBy,
  getEventsNearBy,
};

Home.propTypes = {
  getClubsNearBy: PropTypes.func.isRequired,
  getEventsNearBy: PropTypes.func.isRequired,
  allClubs: PropTypes.arrayOf(PropTypes.shape({})),
  allEvents: PropTypes.arrayOf(PropTypes.shape({})),
};

Home.defaultProps = {
  allClubs: [],
  allEvents: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
