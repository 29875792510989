import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ResetPassword(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const { handleReset, handleChange, errors } = props;
  return (
    <span>
      <form className={classes.form} noValidate>
        <TextField
          id="standard-password-input"
          label={t('password')}
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          required
          fullWidth
          onChange={(e) => handleChange(e, 'password')}
          error={errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          id="standard-password-input"
          label={t('confirm_password')}
          type={showConfirmPassword ? "text" : "password"}
          autoComplete="current-password"
          required
          fullWidth
          onChange={(e) => handleChange(e, 'confirmPassword')}
          error={errors.confirmPassword}
          helperText={errors.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle confirm password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          id="standard-password-token-input"
          label="Password Token"
          type="text"
          required
          fullWidth
          onChange={(e) => handleChange(e, 'passwordToken')}
          error={errors.passwordToken}
          helperText={errors.passwordToken}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleReset}
        >
          {t('reset_password')}
        </Button>
      </form>
    </span>
  );
}

ResetPassword.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    password: PropTypes.string,
    passwordToken: PropTypes.string,
  }),
};

ResetPassword.defaultProps = {
  errors: {},
};

export default ResetPassword;
