import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import translationEN from '../assets/locales/en.locale.json';
import translationDU from '../assets/locales/deutsch.locale.json';
import translationFR from '../assets/locales/french.locale.json';
import translationIT from '../assets/locales/italian.locale.json';

const resources = {
  en: {
    translation: translationEN,
  },
  du: {
    translation: translationDU,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
