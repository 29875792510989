import React from 'react';
import PropTypes from 'prop-types';
import MonthChart from './MonthChart';
import YearChart from './YearChart';
import WeekChart from './WeekChart';
import DayChart from './DayChart';

const DonationOverViewChart = (props) => {
  const renderGraph = () => {
    const { filterType, graphData } = props;
    switch (filterType) {
      case 'month':
        return <MonthChart graphData={graphData} />;
      case 'year':
      case 'all':
        return <YearChart graphData={graphData} type={filterType} />;
      case 'week':
        return <WeekChart graphData={graphData} />;
      case 'day':
        return <DayChart graphData={graphData} />;
      default:
        return null;
    }
  };

  return renderGraph();
};

DonationOverViewChart.propTypes = {
  filterType: PropTypes.string.isRequired,
};

export default DonationOverViewChart;
