import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import RegisterForm from '../../components/Forms/UserRegisterForm';
import { userSingUp } from '../../actions/userActions';
import { validateEmail, validatePassword } from '../../utils/helper';

class Register extends Component {
  state = {
    vertical: 'top',
    horizontal: 'center',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address1: '',
    address2: '',
    postcode: '',
    city: '',
    country: '',
    errors: {},
  };

  componentDidUpdate(prevProps) {
    const { isAuthenticated, history, authUserData } = this.props;
    if (prevProps.authUserData !== authUserData && isAuthenticated) {
      history.push('/profile/user');
    }
  }

  handleChange = (e) => {
    const { errors } = this.state;
    const { name, value } = e.target;
    errors[name] = '';
    this.setState({ [name]: value, errors });
  };

  validateRegister = () => {
    let isValid = true;
    const {
      errors,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      address1,
      postcode,
      city,
      country,
    } = this.state;
    if (!firstName) {
      isValid = false;
      errors.firstName = 'Bitte Vorname eingeben';
    }
    if (!lastName) {
      isValid = false;
      errors.lastName = 'Bitte Nachname eingeben';
    }
    if (!email) {
      isValid = false;
      errors.email = 'Bitte eine gültige E-Mailadresse eingeben';
    }
    if (!password) {
      isValid = false;
      errors.password = 'Bitte ein Passwort eingeben';
    }
    if (email && !validateEmail(email)) {
      isValid = false;
      errors.email = 'Bitte eine gültige E-Mailadresse eingeben';
    }
    if (password && !validatePassword(password)) {
      isValid = false;
      errors.password =
        'Dein Passwort muss mindestens 8 Zeichen, sowie ein Symbol, ein Gross- & Kleinbuchstabe beinhalten.';
    }
    if (password !== confirmPassword) {
      isValid = false;
      errors.confirmPassword = 'Passwörter stimmen nicht überein';
    }
    if (address1) {
      if (!postcode) {
        isValid = false;
        errors.postcode = 'Bitte eine gültig Postleitzahl eingeben';
      } else if (!city) {
        isValid = false;
        errors.city = 'Bitte Stadt eingeben';
      } else if (!country) {
        isValid = false;
        errors.country = 'Bitte Land eingeben';
      }
    }
    this.setState({ errors });
    return isValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      password,
      address1,
      address2,
      postcode,
      city,
      country,
    } = this.state;
    const { userSingUp: register } = this.props;
    if (this.validateRegister()) {
      const userData = {
        firstName,
        lastName,
        email,
        password,
        address: {
          address1,
          address2,
          postalCode: postcode,
          city,
          country,
        },
      };
      register(userData);
    }
  };

  handleCountryChange = (option) => {
    if (option) {
      this.setState({ country: option.name });
    }
  };

  render() {
    const { vertical, horizontal, errors, country } = this.state;
    const { isMsgTriggered, msg, isFailed, errorMsg } = this.props;
    return (
      <Fragment>
        <div className="main-regContainer">
          <Snackbar
            className="register-success"
            anchorOrigin={{ vertical, horizontal }}
            open={isMsgTriggered}
            message={msg}
            key={vertical + horizontal}
          />
          {isFailed ? (
            <Snackbar
              className="register-error"
              anchorOrigin={{ vertical, horizontal }}
              open={isFailed}
              message={errorMsg}
              key={vertical + horizontal}
            />
          ) : null}
          <RegisterForm
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            handleCountryChange={this.handleCountryChange}
            country={country}
            errors={errors}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  redirect: state.formResponse.redirect,
  isMsgTriggered: state.formResponse.isMsgTriggered,
  msg: state.formResponse.msg,
  isFailed: state.formResponse.isFailed,
  errorMsg: state.formResponse.msg,
  isAuthenticated: state.user.isAuthenticated,
  authUserData: state.user.authUserData,
});

const mapDispatchToProps = {
  userSingUp,
};

Register.propTypes = {
  userSingUp: PropTypes.func.isRequired,
  isMsgTriggered: PropTypes.bool.isRequired,
  msg: PropTypes.string.isRequired,
  isFailed: PropTypes.bool,
  errorMsg: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.shape({
          pathname: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  authUserData: PropTypes.shape({
    userId: PropTypes.string,
  }),
};

Register.defaultProps = {
  isFailed: null,
  errorMsg: null,
  authUserData: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
