import React, { Fragment, useEffect } from 'react';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

const Conditions = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>AGB's | MY SPONSOR AG | Sponsoring-Plattform</title>
          <meta name="description" content="In unseren Allgemeinen Geschäftsbedinungen findest Du sämtliche Antworten auf Deine Fragen. Doch nicht? Wir sind gerne für Dich da! :)" />
          <meta property="og:title" content="AGB's | MY SPONSOR AG | Sponsoring-Plattform" />
          <meta property="og:description" content="In unseren Allgemeinen Geschäftsbedinungen findest Du sämtliche Antworten auf Deine Fragen. Doch nicht? Wir sind gerne für Dich da! :)" />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/agb" />
          <meta name="twitter:title" content="AGB's | MY SPONSOR AG | Sponsoring-Plattform" />
          <meta name="twitter:description" content="In unseren Allgemeinen Geschäftsbedinungen findest Du sämtliche Antworten auf Deine Fragen. Doch nicht? Wir sind gerne für Dich da! :)" />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>{t('condition_subtitle')}</h5>
              <h1 className="page-title">{t('condition_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <h4 className="title">
                  {t('condition_scope_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('condition_scope_text_1')}
                </p>
                <p className="text">
                  {t('condition_scope_text_2')}
                </p>
                <h4 className="title">
                  {t('condition_purpose_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('condition_purpose_text_1')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_purpose_text_2')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_purpose_text_3')}
                </p>
                <p className="text">
                  {t('condition_purpose_text_4')}
                </p>
                <h4 className="title">
                  {t('condition_rights_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('condition_rights_text_1')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_text_2')}
                  <ul>
                    <li>{t('condition_rights_text_3')}</li>
                    </ul>
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_text_4')}
                  <ul>
                    <li>{t('condition_rights_text_5')}</li>
                    <li>{t('condition_rights_text_6')}</li>
                    </ul>
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_text_7')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_text_8')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_text_9')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_text_10')}
                </p>
                <p className="text">
                  {t('condition_rights_text_11')}
                </p>
               <h4 className="title">
                  {t('condition_rights_spons_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('condition_rights_spons_text_1')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_spons_text_2')}
                </p>
                <p className="text">
                  {t('condition_rights_spons_text_3')}
                </p>
               <h4 className="title">
                  {t('condition_rights_oper_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('condition_rights_oper_text_1')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_oper_text_2')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_oper_text_3')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_oper_text_4')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_oper_text_5')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_oper_text_6')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_rights_oper_text_7')}
                </p>
                <p className="text">
                  {t('condition_rights_oper_text_8')}
                </p>
               <h4 className="title">
                  {t('condition_cancel_title')}
                </h4>
                <p className="text mb-sm-2">
                  {t('condition_cancel_text_1')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_cancel_text_2')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_cancel_text_3')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_cancel_text_4')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_cancel_text_5')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_cancel_text_6')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_cancel_text_7')}
                </p>
                <p className="text mb-sm-2">
                  {t('condition_cancel_text_8')}
                </p>
                <p className="text">
                  <ul>
                    <li>{t('condition_cancel_text_9')}</li>
                    <li>{t('condition_cancel_text_10')}</li>
                    <li>{t('condition_cancel_text_11')}</li>
                    <li>{t('condition_cancel_text_12')}</li>
                    <li>{t('condition_cancel_text_13')}</li>
                    <li>{t('condition_cancel_text_14')}</li>
                    <li>{t('condition_cancel_text_15')}</li>
                  </ul>
                </p>
               <h4 className="title">
                  {t('condition_assign_title')}
                </h4>
                <p className="text">
                  {t('condition_assign_text_1')}
                </p>
               <h4 className="title">
                  {t('condition_partial_title')}
                </h4>
                <p className="text">
                  {t('condition_partial_text_1')}
                </p>
               <h4 className="title">
                  {t('condition_changes_title')}
                </h4>
                <p className="text">
                  {t('condition_changes_text_1')}
                </p>
               <h4 className="title">
                  {t('condition_law_title')}
                </h4>
                <p className="text">
                  {t('condition_law_text_1')}
                </p>
                <p className="text">
                  {t('condition_law_text_2')}
                </p>
              </div>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Conditions;
