import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { imageBaseUrl } from '../../../utils/helper';
import logoSample from '../../../assets/img/myFavoriteHolder.png';

const useStyles = makeStyles({
  root: {
    marginBottom: 15,
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  imageContainer: {
    marginRight: 15,
  },
  image: {
    width: 100,
    height: 'auto',
    borderRadius: 4,
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    marginBottom: 8,
    fontWeight: 'bold',
    color: '#333',
  },
  description: {
    marginBottom: 12,
    color: '#666',
  },
  info: {
    fontSize: 14,
    color: '#777',
  },
});
export default function UserFavorites(props) {
  const { favorites } = props;
  const styles = useStyles();

  const displayClubs = (clubs) => {
    let clubList = null;
    if (clubs && clubs.length > 0) {
      clubList = clubs.map((club) => {
        return (
          <div className={`row user-fav ${styles.root}`} key={club.id}>
            <div className="col-md-2">
              <div className="item-media top-rounded overflow-hidden">
                {club.logo ? (
                  <a href={`/${club.shortName}`}><img className="single-product-img" src={`${imageBaseUrl}/${club.logo}`} alt="" /></a>
                ) : (
                   <a href={`/${club.shortName}`}><img className="single-product-img" src={logoSample} alt="" /></a>
                )}
              </div>
            </div>
            <div className="col-md-10">
              <div className="item-content">
                <h5>
                  <a className={styles.clubTitle} href={`/${club.shortName}`}>
                    {club.name}
                  </a>
                </h5>
                <p>{club.description}</p>
              </div>
            </div>
          </div>
        );
      });
    }
    return clubList;
  };

  return (
    <div className="tab-pane in active" id="tab1">
      {displayClubs(favorites)}
    </div>
  );
}

UserFavorites.propTypes = {
  favorites: PropTypes.arrayOf(PropTypes.shape({})),
};

UserFavorites.defaultProps = {
  favorites: [],
};
