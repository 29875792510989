import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

const Jobs = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Teil von MY SPONSOR werden | Jobs | Sponsoring-Plattform</title>
          <meta name="description" content="Wir sind immer auf der Suche nach aussergwöhnlichen Talenten und vielleicht bist Du ja das nächste? Bewirb Dich bei uns und werde Teil von MY SPONSOR." />
          <meta property="og:title" content="Teil von MY SPONSOR werden | Jobs | Sponsoring-Plattform" />
          <meta property="og:description" content="Wir sind immer auf der Suche nach aussergwöhnlichen Talenten und vielleicht bist Du ja das nächste? Bewirb Dich bei uns und werde Teil von MY SPONSOR." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/jobs" />
          <meta name="twitter:title" content="Teil von MY SPONSOR werden | Jobs | Sponsoring-Plattform" />
          <meta name="twitter:description" content="Wir sind immer auf der Suche nach aussergwöhnlichen Talenten und vielleicht bist Du ja das nächste? Bewirb Dich bei uns und werde Teil von MY SPONSOR." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>{t('jobs_subtitle')}</h5>
              <h1 className="page-title">{t('jobs_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('jobs_text')}</p>
              </div>
            </div>
          </div>
          <div className="row" id="jobs" >
            <div className="col-lg-12">
              <div className="text-default">
                <div className="job-row">
                  <a className="job-wrapper" href="/jobs/agent-werden">
                    <h2 className="job-name">{t('jobs_agent_title')}</h2>
                    <p class="text job-text">{t('jobs_agent_text')}</p>
                    <p class="text job-place">{t('jobs_agent_place')}</p>
                  </a>
                </div>
                <div className="job-row">
                  <a className="job-wrapper" href="/jobs/react-developer">
                    <h2 className="job-name">{t('jobs_developer_title')}</h2>
                    <p class="text job-text">{t('jobs_developer_text')}</p>
                    <p class="text job-place">{t('jobs_developer_place')}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('jobs_end_text')} <a href="mailto:jobs@mysponsor.ch">jobs@mysponsor.ch</a>. </p>
              </div>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Jobs;
