import { DISPLAY_SPINNER } from '../utils/types';

const displaySpinner = (value) => {
  return {
    type: DISPLAY_SPINNER,
    payload: value,
  };
};

export default displaySpinner;
