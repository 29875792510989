import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    overflowY: 'hidden',
    marginTop: 35,
  },
  container: {
    position: 'absolute',
    top: 120,
    right: 0,
    height: 'auto',
  },
  filterWidth: {
    maxWidth: 350,
  },
  dialogTitle: {
    position: 'absolute',
    top: 0,
    right: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
  },
});

export default function ClubFilter(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    selectedTag,
    handleTypeChange,
    types,
    getClubsByRadius,
    handleRadiusTextChange,
    radius,
  } = props;
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const displayType = () => {
    if (types && types.length > 0) {
      return types.map((type) => <option value={type.tag}>{type.tag}</option>);
    }
    return null;
  };

  return (
    <div>
      <Dialog
        classes={{
          container: classes.container,
          paperWidthSm: classes.filterWidth,
        }}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{ root: classes.root }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <NativeSelect
                fullWidth
                value={selectedTag}
                onChange={handleTypeChange}
                name="club-types"
                inputProps={{ 'aria-label': 'age' }}
              >
                <option value="">{t('filter_select_type')}</option>
                {displayType()}
              </NativeSelect>
            </Grid>
            <Grid item xs={12}>
              <OutlinedInput
                id="filled-adornment-club-radius"
                className="search-box"
                aria-describedby="filled-club-radius-helper-text"
                inputProps={{
                  'aria-label': 'club-radius',
                }}
                type="number"
                placeholder={t('filter_enter_radius')}
                fullWidth
                labelWidth={0}
                onBlur={getClubsByRadius}
                onChange={handleRadiusTextChange}
                value={radius}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </div>
  );
}

ClubFilter.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedTag: PropTypes.string,
  handleTypeChange: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})),
  getClubsByRadius: PropTypes.func.isRequired,
  handleRadiusTextChange: PropTypes.func.isRequired,
  radius: PropTypes.string,
};

ClubFilter.defaultProps = {
  selectedTag: '',
  types: [],
  radius: '',
};
