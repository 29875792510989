import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeApiKey } from '../../utils/helper';
import StripeElementsProvider from './StripeElementsProvider';
import { withRouter } from 'react-router-dom';

const stripePromise = loadStripe(stripeApiKey);

const StripePayment = ({ match }) => {
  const containerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  const formStyles = {
    width: '100%',
    maxWidth: '400px',
    padding: '20px',
  };

  const hidePostalCodeStyles = {
    display: 'none',
  };

  const isMobileDevice = window.innerWidth <= 480;
  const { productId, clubId, userId, authToken, amount } = match.params;

  return (
    <div style={containerStyles}>
      <div style={isMobileDevice ? mobileFormStyles : formStyles}>
        <Elements stripe={stripePromise}>
          <StripeElementsProvider
            amount={amount}
            productId={productId}
            userId={userId}
            clubId={clubId}
            token={authToken}
          />
        </Elements>
        {isMobileDevice && (
          <style>
            {`
            .StripeElement[name="postal"] {
              ${hidePostalCodeStyles.display}
            }
          `}
          </style>
        )}
      </div>
    </div>
  );
};

const mobileFormStyles = {
  width: '100%',
  padding: '10px',
};

export default withRouter(StripePayment);
