import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateMembershipStatus, addExternalPayment, addMember, getMembers } from '../../actions/membershipAction';
import moment from 'moment';
import PaymentHistory from '../Pages/Profile/ClubMemberPaymentHistoryList';
import { validateEmail } from '../../utils/helper';


export default function ClubAddMemberModal(props) {
  const {
    open,
    toggleAddMemberModal,
    handleSelectionChange,
    data,
    product,
    setProduct,
    pageDetails,
    initialAmount,
    setInitialAmount,
    startingDate,
    setStartingDate,
    authUserData
  } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    fetchMembers();
  }, [pageDetails]);

  const fetchMembers = () => {
    dispatch(
      getMembers({
        clubId: authUserData.clubId,
        productId: product ? product.id : null,
        ...pageDetails
      })
    );
    product && setProduct(product);
  };

  const handleAddMember = () => {
    if (validateValues(firstName, lastName, email, initialAmount, startingDate, setErrors, t)) {
      const memberData = {
        firstName: firstName,
        lastName: lastName,
        userEmail: email,
        clubId: authUserData.clubId,
        productId: product.id,
        initialAmount: initialAmount,
        startingDate: startingDate
      };
      dispatch(addMember(memberData, t('add_member_success'), t('add_member_error'))); // Call the addMember action
      setFirstName('');
      setLastName('');
      setEmail('');
      fetchMembers();
    }
  };

  const [errors, setErrors] = useState({
    amount: '',
    reactivationDate: '',
    paymentPeriod: '',
    invoiceId: ''
  });


  const validateValues = (
    firstName,
    lastName,
    email,
    initialAmount,
    startingDate,
    setError,
    t
  ) => {
    const errors = {}; // Initialize an empty errors object

    if (!validateEmail(email))
      errors.email = t('invalid_email');

    if (!firstName || firstName.trim().length === 0)
      errors.firstName = t('invalid_first_name');

    if (!lastName || lastName.trim().length === 0)
      errors.lastName = t('invalid_last_name');

    if (initialAmount == null || initialAmount < 5)
      errors.initialAmount = t('invalid_initial_amount');

    setError(errors);
    return Object.keys(errors).length == 0;
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      className="width-xl-60-modal create-product-modal"
      id="membershipAddMember"
    >
      <DialogTitle className="modal-title-center" id="responsive-dialog-title">
        {t('add_member')}
      </DialogTitle>

      <DialogContent>
        <div className="product-create-btn">
          {t('mebership_type')}<br />

          <FormControl id="memberShipTypeDropdown" className="w-25">
            <Select
              value={product}
              onChange={handleSelectionChange}
            >
              {
                data.map(product => (
                  <MenuItem value={product}>{product.title}</MenuItem>
                ))
              }
            </Select>
          </FormControl>

          <Grid container spacing={3} alignItems="center" id="addMemberForm">
            <Grid item xs={12} sm={2}>
              <TextField label={t('first_name')} value={firstName} error={errors.firstName}
                         helperText={errors.firstName} required onChange={(e) => setFirstName(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField label={t('last_name')} value={lastName} error={errors.lastName} helperText={errors.lastName}
                         required onChange={(e) => setLastName(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField label={t('Email')} value={email} error={errors.email} helperText={errors.email} required
                         onChange={(e) => setEmail(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label={t('initial_amount')}
                type="number"
                required
                value={initialAmount}
                error={errors.initialAmount}
                helperText={errors.initialAmount}
                onChange={(e) => setInitialAmount(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label={t('starting_date')}
                value={startingDate}
                required
                onChange={(e) => setStartingDate(e.target.value)}
                type="date"
                error={errors.startingDate}
                helperText={errors.startingDate}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions className="action-buttons-modal">
        <Button className="cancel-button" onClick={toggleAddMemberModal} variant="contained">
          {t('close')}
        </Button>
        <Button variant="contained" className="btn-global-filled" onClick={handleAddMember} disabled={product == null}>
          {t('add_member')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


ClubAddMemberModal.propTypes = {};

ClubAddMemberModal.defaultProps = {};