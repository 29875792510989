/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState, useEffect } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Cameranew from '../../assets/img/kamera.png';
import { imageBaseUrl, dataURItoBlob, getClubRole } from '../../utils/helper';
import PlaceHolderImage from '../../assets/img/01.jpg';
import ImageCrop from '../Modals/LogoImageCropModel';
import ConfirmModal from '../Modals/CoverImageConfirmModal';
import { uploadFiles, updateFiles } from '../../actions/productActions';

const MAX_SIZE = 1024 * 1024 * 2;
export default function CoverImageUpload(props) {
  const dispatch = useDispatch();
  const { authUserData } = useSelector((state) => state.user);
  const [selectedImageURL, setSelectedImageURL] = useState('');
  const { isLoading } = useSelector((state) => state.spinner);
  const { clubDetails, type } = props;
  const [file, setFile] = useState('');
  const [originalFile, setOriginalFile] = useState('');
  const [newUploadedFile, setNewFile] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [openCropModal, setCropModal] = useState(false);
  const [openConfirm, setConfirmModal] = useState(false);
  const clubRole= getClubRole(authUserData);
  
  useEffect(() => {
    if (clubDetails && clubDetails.logo) {
      const savedImageUrl = `${imageBaseUrl}/${clubDetails.logo}`;
      setFile(savedImageUrl);
      setUploadedFileName(clubDetails.logo);
    }
  }, [clubDetails]);

  const handleCloseConfirm = () => {
    setConfirmModal(false);
    const clubLogo = clubDetails && clubDetails.logo ? `${imageBaseUrl}/${clubDetails.logo}` : '';
    setFile(clubLogo);
  };

  const handleUploadConfirm = () => {
    const fileData = new FormData();
    fileData.append('file', newUploadedFile[0]);
    fileData.append('entity', 'club');
    fileData.append('column', 'logo');
    fileData.append('id', authUserData.clubId);
    setConfirmModal(false);
    if (uploadedFileName) {
      fileData.append('fileName', uploadedFileName);
      dispatch(updateFiles(fileData, () => {}));
    } else {
      dispatch(uploadFiles(fileData, () => {}));
    }
  };

  const handleImageChange = async (event) => {
    setUploadError('');
    setOriginalFile('');
    setNewFile([]);
    setFile('');
    if (event.target.files && event.target.files.length > 0) {
      const filesCount = event.target.files.length;
      if (filesCount > 1) {
        event.preventDefault();
        setUploadError('cannot upload more than one image');
      } else if (filesCount === 1) {
        const newFile = event.target.files[0];
        if (newFile.size > MAX_SIZE) {
          setUploadError('please upload less than 2MB size of image');
        } else {
          const dataUri = URL.createObjectURL(newFile);
          setCropModal(true);
          setSelectedImageURL(dataUri);
          setOriginalFile(newFile);
        }
      }
    }
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
  };

  // const deleteImage = () => {
  //   setOriginalFile('');
  //   setNewFile([]);
  //   setFile('');
  // };

  // const isDisabled = !!file;
  let url = '';
  if (file) {
    url = file;
  } else {
    url = PlaceHolderImage;
  }

  const handleConfirmCropModal = () => {
    setCropModal(false);
    setTimeout(() => {
      setConfirmModal(true);
    }, 500);
  };

  const handleCloseCropModal = () => {
    setCropModal(false);
    window.location.reload();
  };

  const setCroppedImage = (dataURL) => {
    setFile(dataURL);
    const blob = dataURItoBlob(dataURL);
    const resultFile = new File([blob], originalFile.name, { type: originalFile.type });
    setNewFile([resultFile]);
  };

  return (
    <Fragment>
      <div className="cover-image-wrapper">
        {type !== 'public' ? (
          <form className="cover-input-container" action="" method="post" id="form-image">
            <input
              style={{ display: 'none' }}
              type="file"
              id="clubLogoUpload"
              accept=".png, .jpg, .jpeg"
              onChange={handleImageChange}
            />
            {clubRole !== 'ROLE_CLUB_FINANCE' &&
            <label htmlFor="clubLogoUpload">
              <img src={Cameranew} className="club-logo-upload" alt="camera" />
            </label>}
            {uploadError ? (
              <FormHelperText style={{ color: 'red' }}>{uploadError}</FormHelperText>
            ) : null}
          </form>
        ) : null}
        <img className="profile-img" src={url} alt="logo" />
      </div>
      <ImageCrop
        open={openCropModal}
        handleClose={handleCloseCropModal}
        handleConfirm={handleConfirmCropModal}
        setCroppedImage={setCroppedImage}
        selectedImageURL={selectedImageURL}
        imageDimesions={{ unit: 'px', width: 200, aspectRatio: 1 / 1 }}
      />
      <ConfirmModal
        isLoading={isLoading}
        open={openConfirm}
        handleClose={handleCloseConfirm}
        handleConfirm={handleUploadConfirm}
      />
    </Fragment>
  );
}

CoverImageUpload.propTypes = {
  clubDetails: PropTypes.shape({
    logo: PropTypes.string,
  }),
  type: PropTypes.string,
};

CoverImageUpload.defaultProps = {
  clubDetails: {},
  type: '',
};
