import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

import stepone from '../../../assets/img/stepone-app.jpg';
import steptwo from '../../../assets/img/scan-qr.jpg';
import stepthree from '../../../assets/img/sponsernow.png';

const DonateApp = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Schweizer Spenden-App | Zeit & Geld sparen mit MY SPONSOR</title>
          <meta name="description" content="So ünterstützt man heute sein Team! Mit unserer App machen wir es Dir möglichst einfach Deinen Lieblingsverein oder Deine/-n Lieblingssportler-/in zu unterstützen." />
          <meta property="og:title" content="Schweizer Spenden-App | Zeit & Geld sparen mit MY SPONSOR" />
          <meta property="og:description" content="So ünterstützt man heute sein Team! Mit unserer App machen wir es Dir möglichst einfach Deinen Lieblingsverein oder Deine/-n Lieblingssportler-/in zu unterstützen." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/spenden-app" />
          <meta name="twitter:title" content="Schweizer Spenden-App | Zeit & Geld sparen mit MY SPONSOR" />
          <meta name="twitter:description" content="So ünterstützt man heute sein Team! Mit unserer App machen wir es Dir möglichst einfach Deinen Lieblingsverein oder Deine/-n Lieblingssportler-/in zu unterstützen." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row pb-bottom-50">
            <div className="col-lg-12">
              <h5>{t('donateapp_subtitle')}</h5>
              <h1 className="page-title">{t('donateapp_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('donateapp_text')}</p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-80 donate-steps">
            <div className="col-lg-6 text-step">
              <div className="text-default">
                <h2 className="title">
                  <span>#1</span> {t('donateapp_firststep_title')}
                </h2>
                <p className="text">{t('donateapp_firststep_text')}</p>
                <div className="download-buttons">
                  <div className="group-btn">
                    <a href="https://pb-g.ch/stfa" className="btn">
                      <i className="ren-google-play" />
                      {t('download_part_buttongfirst')}
                      <br />
                      <b>{t('download_part_buttongsecond')}</b>
                    </a>
                    <a href="https://pb-g.ch/xdSp" className="btn">
                      {t('download_part_buttonafirst')}
                      <br />
                      <b>{t('download_part_buttonasecond')}</b>
                      <i className="ren-apple-store" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 image-step">
              <div className="text-default">
                <img className="smart-phone donate-step" src={stepone} alt="#" />
              </div>
            </div>
          </div>
          <div className="row pb-bottom-80 donate-steps">
            <div className="col-lg-6 image-step">
              <div className="text-default">
                <img className="smart-phone donate-step" src={steptwo} alt="#" />
              </div>
            </div>
            <div className="col-lg-6 text-step">
              <div className="text-default">
                <h2 className="title">
                  <span>#2</span> {t('donateapp_secondstep_title')}
                </h2>
                <p className="text">{t('donateapp_secondstep_text')}</p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-80 donate-steps">
            <div className="col-lg-6 text-step">
              <div className="text-default">
                <h2 className="title">
                  <span>#3</span> {t('donateapp_thirdstep_title')}
                </h2>
                <p className="text">{t('donateapp_thirdstep_text')}</p>
              </div>
            </div>
            <div className="col-lg-6 image-step">
              <div className="text-default">
                <img className="smart-phone donate-step" src={stepthree} alt="#" />
              </div>
            </div>
          </div>
          <div className="row cta-row">
            <div className="col-lg-9">
              <h4>{t('cta_text')}</h4>
            </div>
            <div className="col-lg-3">
              <a href="/clubs" className="forward-btn">
                {t('donationcert_button')}
              </a>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default DonateApp;
