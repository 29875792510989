import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
// import EventTickets from './EventTicketsForm';
import PlaceSearch from '../PlaceSearch';
import TranslateOptionSelect from '../Select/TranslateOptionSelect';

export default function CreateEvent(props) {
  const { t, i18n } = useTranslation();
  const {
    // eventTiers,
    // tickets,
    // handleTicketRemoveClick,
    // handleTicketAddClick,
    // handleTicketsFieldChange,
    handleClubEventFieldChange,
    setEventLocation,
    handleTranslateOptions,
    handleEventDescChange,
    event,
    errors,
  } = props;

  const selectedLanguage = localStorage.getItem('language') || 'du';
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <>
      <form noValidate autoComplete="off">
        <Grid container spacing={2} xs={12} sm={7}>
          <Grid item xs={12} sm={12}>
            <TextField
              id="standard-title-input"
              name="title"
              label={t('event_title')}
              required
              fullWidth
              value={event.title}
              error={errors.title}
              helperText={errors.title}
              onChange={handleClubEventFieldChange}
              inputProps={{
                maxLength: 25,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <PlaceSearch setAddressDetails={setEventLocation} />
            {errors.location ? (
              <FormHelperText style={{ color: 'red' }}>{errors.location}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="standard-startDate-input"
              name="fromDate"
              type="datetime-local"
              label={t('event_start_date')}
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={event.fromDate}
              onChange={handleClubEventFieldChange}
              error={errors.fromDate}
              helperText={errors.fromDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="standard-endDate-input"
              name="toDate"
              type="datetime-local"
              label={t('event_end_date')}
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={event.toDate}
              onChange={handleClubEventFieldChange}
              error={errors.toDate}
              helperText={errors.toDate}
            />
          </Grid>
          </Grid>
          <Grid container spacing={3} sm={12} className="description-event-modal">
          <Grid item xs={12} sm={12}>
            <TextField
              id="standard-description-input"
              name="description"
              label={t('event_description')}
              required
              fullWidth
              multiline
              rows={4}
              inputProps={{
                maxLength: 240,
              }}
              value={event.description}
              onChange={handleClubEventFieldChange}
              error={errors.description}
              helperText={errors.description}
              onBlur={handleEventDescChange}
            />
          </Grid>
          <TranslateOptionSelect
            handleTranslateOptions={handleTranslateOptions}
            description={event.description}
            isTranlationCompleted={event.isTranlationCompleted}
          />
        </Grid>
      </form>
      {errors.tickets ? (
        <FormHelperText style={{ color: 'red' }}>{errors.tickets}</FormHelperText>
      ) : null}
      {/* <EventTickets
        handleTicketAddClick={handleTicketAddClick}
        handleTicketRemoveClick={handleTicketRemoveClick}
        handleTicketsFieldChange={handleTicketsFieldChange}
        tickets={tickets}
        eventTiers={eventTiers}
      /> */}
    </>
  );
}

CreateEvent.propTypes = {
  // eventTiers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // handleTicketAddClick: PropTypes.func.isRequired,
  // handleTicketRemoveClick: PropTypes.func.isRequired,
  // handleTicketsFieldChange: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClubEventFieldChange: PropTypes.func.isRequired,
  handleTranslateOptions: PropTypes.func.isRequired,
  handleEventDescChange: PropTypes.func.isRequired,
  setEventLocation: PropTypes.func.isRequired,
  event: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    isTranlationCompleted: PropTypes.bool,
  }),
  errors: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    location: PropTypes.string,
    tickets: PropTypes.string,
    eventImage: PropTypes.string,
  }),
};

CreateEvent.defaultProps = {
  event: {},
  errors: {},
};
