import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

const Contact = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Kontakt aufnehmen | Sponsoring-Plattform | MY SPONSOR AG</title>
          <meta name="description" content="Du hast Fragen? Dann nimm mit uns Kontakt auf und unser Team hilft Dir gerne weiter." />
          <meta property="og:title" content="Kontakt aufnehmen | Sponsoring-Plattform | MY SPONSOR AG" />
          <meta property="og:description" content="Du hast Fragen? Dann nimm mit uns Kontakt auf und unser Team hilft Dir gerne weiter." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/contact" />
          <meta name="twitter:title" content="Kontakt aufnehmen | Sponsoring-Plattform | MY SPONSOR AG" />
          <meta name="twitter:description" content="Du hast Fragen? Dann nimm mit uns Kontakt auf und unser Team hilft Dir gerne weiter." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>{t('contact_subtitle')}</h5>
              <h1 className="page-title">{t('contact_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('contact_text')}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <ContactForm />
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Contact;
