import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../Forms/ChangePasswordForm';
import Spinner from '../Spinner';

export default function ChangePasswordModal(props) {
  const {
    open,
    handleClose,
    isLoading,
    userDetails,
    handleChange,
    passwordChageErrors,
    handleSave,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
      >
        <DialogTitle className="modal-title-center" id="responsive-dialog-title">
          {t('change_password')}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <ChangePasswordForm
              errors={passwordChageErrors}
              usedata={userDetails}
              handleChange={handleChange}
            />
          )}
        </DialogContent>
        <DialogActions className="modal-buttons-center">
          <Button className="cancel-button" onClick={handleClose} variant="outlined">
            {t('cancel')}
          </Button>
          <Button className="btn-global-filled" autoFocus onClick={handleSave} variant="outlined">
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  userDetails: PropTypes.shape({}),
  passwordChageErrors: PropTypes.shape({}),
  handleChange: PropTypes.func.isRequired,
};

ChangePasswordModal.defaultProps = {
  isLoading: false,
  userDetails: {},
  passwordChageErrors: {},
};
