import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ChangePassword(props) {
  const { handleChange, errors, usedata } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-password-input"
                label={t('old_password')}
                name="oldPassword"
                value={usedata.oldPassword}
                required
                fullWidth
                autoFocus
                onChange={handleChange}
                error={errors.oldPassword}
                helperText={errors.oldPassword}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-password-input"
                label={t('new_password')}
                autoComplete="lname"
                name="newPassword"
                value={usedata.newPassword}
                required
                fullWidth
                onChange={handleChange}
                error={errors.newPassword}
                helperText={errors.newPassword}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

ChangePassword.propTypes = {
  handleChange: PropTypes.func.isRequired,
  usedata: PropTypes.shape({
    oldPassword: PropTypes.string,
    newPassword: PropTypes.string,
  }),
  errors: PropTypes.shape({
    oldPassword: PropTypes.string,
    newPassword: PropTypes.string,
  }),
};

ChangePassword.defaultProps = {
  errors: {},
  usedata: {},
};

export default ChangePassword;
