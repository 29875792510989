import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';

export default function CoverImageConfirm(props) {
  const { open, handleClose, handleConfirm, isLoading } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          {isLoading ? (
            <Spinner />
          ) : (
            <DialogContentText>{t('upload_image_text')}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions className="modal-buttons-center">
          <Button
            disabled={isLoading}
            onClick={handleClose}
            variant="outlined"
            className="cancel-button"
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleConfirm}
            variant="outlined"
            className="btn-global-filled"
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CoverImageConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
