import axios from 'axios';
import { headers, baseUrl, authHeader } from '../utils/helper';
import { FETCH_MEMBERS_BY_PRODUCT, FETCH_MEMBERS_BY_PRODUCT_LOAD_MORE, FETCH_MEMBERS_PAYMENT_BY_PRODUCT, FETCH_USER_MEMBERSHIPS,FETCH_USER_MEMBERSHIP } from '../utils/types';
import { displaySuccess, clearSuccess, displayError, clearError } from './formResponseAction';
import { refreshToken } from './userActions';



export const getMembers = (data) => {
  return async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/memberships/get-members`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_MEMBERS_BY_PRODUCT,
            payload: response.data,
          });
        }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getMembersLoadMore = (data) => {
  return async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/memberships/get-members`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_MEMBERS_BY_PRODUCT_LOAD_MORE,
            payload: response.data,
          });
        }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getMemberPaymentHistory = (data) => {
  return async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/memberships/payment-details`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_MEMBERS_PAYMENT_BY_PRODUCT,
            payload: response.data,
          });
        }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const addMember = (data,  successMessage, errorMessage) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/memberships`, data, authHeader());
        if (response && response.data &&  response.status == 200) {
          dispatch(displaySuccess( successMessage));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
        } else{
          dispatch(displayError(errorMessage));
          setTimeout(() => {
            dispatch(clearError());
          }, 4000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(errorMessage));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const getUserMemberships = (data) => {
  return async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/memberships/member-details`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_USER_MEMBERSHIPS,
            payload: response.data,
          });
        }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};


export const updateMembershipStatus = (data,successMessage, errorMessage) => {
  return async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/memberships/update-status`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess( successMessage));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
          dispatch({
            type: FETCH_USER_MEMBERSHIP,
            payload: response.data,
          });
        } else{
          dispatch(displayError(errorMessage));
          setTimeout(() => {
            dispatch(clearError());
          }, 4000);
        }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(errorMessage));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};


export const addExternalPayment = (data,successMessage, errorMessage) => {
  return async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/memberships/external-payment`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess( successMessage));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
          dispatch({
            type: FETCH_MEMBERS_PAYMENT_BY_PRODUCT,
            payload: response.data,
          });
        } else{
          dispatch(displayError(errorMessage));
          setTimeout(() => {
            dispatch(clearError());
          }, 4000);
        }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(errorMessage));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};