import React, { Fragment, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { imageBaseUrl, dataURItoBlob } from '../../utils/helper';
import ImageCrop from '../Modals/CropImageModal';

const MAX_SIZE = 1024 * 1024 * 2;
export default function ImageUpload(props) {
  const { setImage, removeImage, alreadyUploadedImage } = props;
  const [file, setFile] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [originalFile, setOriginalFile] = useState('');
  const [openCropModal, setCropModal] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState('');

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesCount = event.target.files.length;
      if (filesCount > 1) {
        event.preventDefault();
        setUploadError('cannot upload more than one image');
      } else if (filesCount === 1) {
        const newFile = event.target.files[0];
        if (newFile.size > MAX_SIZE) {
          setUploadError('please upload less than 2MB size of image');
        } else {
          const dataUri = URL.createObjectURL(newFile);
          // setImage(newFile);
          // setFile(dataUri);
          setCropModal(true);
          setOriginalFile(newFile);
          setSelectedImageURL(dataUri);
        }
      }
    }
  };

  useEffect(() => {
    if (alreadyUploadedImage) {
      const url = `${imageBaseUrl}/${alreadyUploadedImage}`;
      setImageUrl(url);
    }
  }, [alreadyUploadedImage]);

  const deleteImage = () => {
    removeImage();
    setFile('');
    setImageUrl('');
  };

  const isDisabled = file || imageUrl;

  const handleCloseCropModal = () => {
    setCropModal(false);
  };

  const setCroppedImage = (dataURL) => {
    setFile(dataURL);
    const blob = dataURItoBlob(dataURL);
    const resultFile = new File([blob], originalFile.name, { type: originalFile.type });
    setImage(resultFile);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="box">
            <div className="js--image-preview">
              <img className="upload-container" src={file || imageUrl} alt="" />
            </div>
            <input
              disabled={isDisabled}
              accept="image/png, image/jpeg"
              style={{ display: 'none' }}
              className="image-upload"
              type="file"
              id="upload-photo"
              onChange={handleImageChange}
            />
            {file || imageUrl ? (
              <Fragment>
                <DeleteIcon className="club-item" onClick={deleteImage} />
              </Fragment>
            ) : (
              <div className="upload-options">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="upload-photo" />
              </div>
            )}
          </div>
        </Grid>
        {uploadError ? (
          <FormHelperText style={{ color: 'red' }}>{uploadError}</FormHelperText>
        ) : null}
      </Grid>
      <ImageCrop
        open={openCropModal}
        handleClose={handleCloseCropModal}
        setCroppedImage={setCroppedImage}
        selectedImageURL={selectedImageURL}
        imageDimesions={{ unit: 'px', width: 300, aspectRatio: 4 / 3 }}
      />
    </Fragment>
  );
}

ImageUpload.propTypes = {
  setImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  alreadyUploadedImage: PropTypes.string.isRequired,
};
