import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import defaultImage from '../../../assets/img/product-holder.png';
import { imageBaseUrl } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';

const displayTopProducts = (products) => {
  if (products.content && products.content.length > 0) {
    return products.content.map((product) => (
      <Grid item xs={12} sm={4}>
        <Paper>
          <div className="vertical-item content-padding text-center with-background rounded bottom-color-border item-size">
            <div className="item-media top-rounded overflow-hidden">
              {product.productImage ? (
                <img className="event-img" src={`${imageBaseUrl}/${product.productImage}`} alt="product-image" />
              ) : (
                <img className="event-img" src={defaultImage} alt="" />
              )}
              <div className="media-links no-overlay" />
            </div>
            <div className="item-content with-border bottom-rounded">
              <h5 className="entry-title">{product.productTitle}</h5>
              <div className="price">
                <span className="amount">Anzahl Spenden: {product.totalQuantity}</span>
              </div>
            </div>
          </div>
        </Paper>
      </Grid>
    ));
  }
  return null;
};

const TopProducts = (props) => {
  const { t, i18n } = useTranslation();
  const { data } = props;
  return (
    <div className="tab-pane in active product-tab" id="tab2">
      <h4>{t('top_products')}</h4>
      <div style={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {displayTopProducts(data)}
        </Grid>
      </div>
    </div>
  );
};

TopProducts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

TopProducts.defaultProps = {
  data: [],
};

export default TopProducts;
