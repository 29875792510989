import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { baseUrl, authHeader, twintPaymentPageUrl } from '../../utils/helper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(() => ({
  payButton: {
    backgroundColor: '#55a901',
    borderColor: '#55a901',
    borderWidth: 1,
    borderRadius: 5,
    width: '100%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff'
  },
  cancelBtn: {
    backgroundColor: '#fff',
    borderColor: '#666666',
    borderWidth: 0,
    borderRadius: 8,
    width: '80%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10
  },
  payText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
  },
  cardFieldStyle: {
    width: '100%',
    height: 50,
    marginTop: 30,
    borderRadius: 5,
    borderColor: "#0000",
    borderWidth: 0.5,
  },
  hederText: {
    fontWeight: 'bold',
    fontSize: 25,
    color: '#000000',
    alignItems: 'center',
    textAlign: 'center',
    marginTop:40,
  }
}));
const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
  hidePostalCode: true,
};

const StripeElementsProvider = (props) => {
  const stripe = useStripe();
  const classes = useStyles();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { userId, clubId, productId, amount, token } = props
  const apiURL = `${baseUrl}/payments/pay`;
  let history = useHistory();


  const paymentData = {
    amount: amount,
    quantity: 1,
    userId: userId,
    clubId: clubId,
    productId: productId,
  };

  const successResponse = {
    success: true,
    message: 'Payment completed successfully',
  };
  const errorResponse = {
    success: false,
    message: 'Payment completed successfully',
  };

  const sendResponseToWebView = (response) => window.ReactNativeWebView.postMessage(JSON.stringify(response));

  const redirectToSuccess = () => {
    history.push('/payment/twint-success');
  };

  const redirectToError = () => {
    history.push('/payment/twint-error');
  };

  const stripePaymentMethodHandler = async (result) => {
    try {
      paymentData.payment_method = result.id;
      const response = await axios.post(apiURL, paymentData, authHeader(token));
      if (response && response.data) {
        setPaymentLoading(false);
        if (response.data.requires_action) {
          stripe
            .confirmCardPayment(response.data.payment_intent_client_secret)
            .then(handleStripeJsResult);
        } else if (response && response.data.success && response.data.success ==true) {
          console.log("Success....");
          //sendResponseToWebView(successResponse)
          redirectToSuccess()
        }else {
          console.log("Failed....");
          //sendResponseToWebView(errorResponse)
          redirectToError()        }
      }
    } catch (error) {
      if (error && error.response) {
        console.log('Something went wrong');
        //sendResponseToWebView(errorResponse)
        redirectToError()      }
    }
  };
  const handleStripeJsResult = async (result) => {
    try {      
      if (result.error) {
        console.log("Error....");
        //sendResponseToWebView(errorResponse)
        redirectToError()
      } else {
        paymentData.paymentIntentId = result.paymentIntent.id;
        const response = await axios.post(apiURL, paymentData, authHeader(token));
        if (response && response.data.success && response.data.success ==true) {
          console.log("Success....");
          //sendResponseToWebView(successResponse)
          redirectToSuccess()
        }
      }
    } catch (error) {
      if (error && error.response) {
        console.log('Something went wrong');
        //sendResponseToWebView(errorResponse)
        redirectToError()
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentError(null)
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    setPaymentLoading(false);
    if (error) {
      setPaymentError(error.message);
    } else {
      console.log(paymentMethod);
      stripePaymentMethodHandler(paymentMethod)
    }
  };

  return (
    <div>
      <h2 className={classes.hederText}>Payment</h2>
      <form onSubmit={handleSubmit}>
        <CardElement options={CARD_OPTIONS} className={classes.cardFieldStyle} />
        <button type="submit" disabled={!stripe || paymentLoading} className={classes.payButton}>
          {paymentLoading ? 'Processing...' : 'Support'}
        </button>
      </form>
      {paymentError && <p style={{ color: 'red' }}>{paymentError}</p>}
    </div>
  );
};

export default StripeElementsProvider;