import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { findDescriptionField, clubRoles } from '../../utils/helper';
import PlaceSearch from '../PlaceSearch';
import LogoUpload from '../Upload/LogoUpload';
import CountrySelect from '../Select/CountrySelect';

const getStyles = (type, sportType, theme) => {
  return {
    fontWeight:
      sportType.indexOf(type) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  adminEmailTextBox: {
    display: 'flex',
  },
  deleteIcon: {
    color: 'red',
    marginTop: 35,
    cursor: 'pointer',
  },
  btnColor: {
    color: '#fff',
    backgroundColor: '#f50',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f50',
    },
  },
}));

// Destructuring props
const FirstStep = ({
  handleNext,
  handleChange,
  handleAdminEmail,
  handleEmailChange,
  handleRoleChange,
  handleTypeChange,
  handleNatureChange,
  handleEmailCheck,
  setClubLogo,
  removeClubLogo,
  setAddressDetails,
  removeAdminEmailTextField,
  types,
  savedLogo,
  values: {
    address1,
    address2,
    postcode,
    country,
    city,
    sportType,
    clubName,
    website,
    clubEmail,
    decriptionText,
    contactNumber,
    nature
  },
  adminEmailsInput,
  handleDescriptionText,
  handleCountryChange,
  errors,
}) => {
  // Check if all values are not empty or if there are some error
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [name, setName] = useState(t('club_name'));
  const [email, setEmail] = useState(t('club_email'));
  const [description, setDescription] = useState(t('club_description'));
  const [webSite, setWebSite] = useState(t('club_website'));
  const selectedLanguage = localStorage.getItem('language') || 'du';
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    // Step 1: Get Data from the URL
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlName = urlSearchParams.get('name') || clubName;
    const urlEmail = urlSearchParams.get('email') || clubEmail;
    const urlNature= urlSearchParams.get("nature") || nature;
    const urlWebsite= urlSearchParams.get("website") || website;
    

    // Step 2: Update Form State with URL Data
    handleChange({
      target: { name: 'clubEmail', value: urlEmail }
    });
    handleChange({
      target: { name: 'clubName', value: urlName }
    });
    handleChange({
      target: { name: 'website', value: urlWebsite }
    });
    handleChange({
      target: { name: 'nature', value: urlNature }
    });
    

  }, []);

  const natures = ['CLUB', 'ATHLETE', 'ACADEMY', 'FOUNDATION'];
  const descriptionFieldName = findDescriptionField(selectedLanguage);

  const handleClubNatureChange = (event) => {
    handleNatureChange(event)
    if (event.target.value === 'CLUB') {
      setName(t('club_name'));
      setEmail(t('club_email'));
      setDescription(t('club_description'));
      setWebSite(t('club_website'));
    } else if (event.target.value === 'ATHLETE') {
      setName(t('athlete_name'));
      setDescription(t('athlete_description'));
      setWebSite(t('athlete_website'));
      setEmail(t('athlete_email'))
    } else if (event.target.value === 'ACADEMY') {
      setName(t('academy_name'));
      setEmail(t('academy_email'));
      setDescription(t('academy_description'));
      setWebSite(t('academy_website'));
    }else if (event.target.value === 'FOUNDATION') {
      setName(t('foundation_name'));
      setEmail(t('foundation_email'));
      setDescription(t('foundation_description'));
      setWebSite(t('foundation_website'));
    }
  }

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={12}>
          <FormHelperText style={{ color: 'red' }}>{errors.clubLogoFiles}</FormHelperText>
          <LogoUpload savedImages={savedLogo} setImage={setClubLogo} removeImage={removeClubLogo} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required margin="normal">
            <InputLabel>{t('type')}</InputLabel>
            <Select
              labelId="label_nature"
              id="nature"
              multiple={false}
              value={nature}
              onChange={handleClubNatureChange}
              MenuProps={MenuProps}
            >
              {natures.map((nature, index) => (
                <MenuItem key={index} value={nature} style={getStyles(nature, natures, theme)}>
                  {t(nature)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors.nature}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label={name}
            name="clubName"
            placeholder={name}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            margin="normal"
            onChange={handleChange}
            value={clubName || ''}
            required
            error={errors.clubName}
            helperText={errors.clubName}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={email}
            name="clubEmail"
            placeholder={email}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            margin="normal"
            onChange={handleChange}
            value={clubEmail || ''}
            required
            error={errors.clubEmail}
            helperText={errors.clubEmail}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={webSite}
            name="website"
            placeholder={webSite}
            margin="normal"
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            onChange={handleChange}
            value={website || ''}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PlaceSearch setAddressDetails={setAddressDetails} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line1')}
            name="address1"
            placeholder={t('address_line1')}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            margin="normal"
            value={address1 || ''}
            onChange={handleChange}
            required
            error={errors.address1}
            helperText={errors.address1}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('address_line2')}
            name="address2"
            placeholder={t('address_line2')}
            margin="normal"
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            value={address2 || ''}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('post_code')}
            name="postcode"
            placeholder={t('post_code')}
            value={postcode || ''}
            onChange={handleChange}
            margin="normal"
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            required
            error={errors.postcode}
            helperText={errors.postcode}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('city')}
            name="city"
            placeholder={t('city')}
            value={city || ''}
            onChange={handleChange}
            margin="normal"
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            required
            error={errors.city}
            helperText={errors.city}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CountrySelect
            name="country"
            label={t('country')}
            handleChange={(e, option) => handleCountryChange(option, 'country')}
            selectedCountry={country || ''}
            error={errors.country}
            helperText={errors.country}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={t('phone')}
            name="contactNumber"
            placeholder={t('phone')}
            value={contactNumber || ''}
            onChange={handleChange}
            margin="normal"
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            required
            error={errors.contactNumber}
            helperText={errors.contactNumber}
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required margin="normal">
            <InputLabel>{t('sport_kind')}</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple={false}
              value={sportType}
              onChange={handleTypeChange}
              MenuProps={MenuProps}
            >
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id} style={getStyles(type, types, theme)}>
                  {type.tag}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors.sportType}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={description}
            name={descriptionFieldName}
            placeholder={description}
            value={decriptionText[descriptionFieldName]}
            onChange={handleDescriptionText}
            margin="normal"
            required
            error={errors[descriptionFieldName]}
            helperText={errors[descriptionFieldName]}
            inputProps={{
              maxLength: 240,
            }}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {adminEmailsInput.map((i, index) => {
            return (
              <div className={classes.adminEmailTextBox}>
                <TextField
                  onChange={(e) => handleEmailChange(e, index, i.role)}
                  onBlur={handleEmailCheck}
                  fullWidth
                  label={t('email_adress')}
                  name="adminEmail"
                  value={i.email}
                  key={`admin-email-${i}`}
                  placeholder={t('email_adress')}
                  margin="normal"
                  onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
                />
                <FormControl fullWidth required margin="normal" style={{width:"90%"}}>
                  <InputLabel>{t('role')}</InputLabel>
                  <Select
                    labelId="label_role"
                    id="role"
                    multiple={false}
                    value={i.role}
                    onChange={(e)=> handleRoleChange(e,index,i.email)}
                    MenuProps={MenuProps}
                  >
                    {clubRoles.map((role, index) => (
                      <MenuItem key={index} value={role} style={getStyles(role, clubRoles, theme)}>
                        {t(role)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: 'red' }}>{errors.nature}</FormHelperText>
                </FormControl>
                <DeleteForeverIcon
                  onClick={() => removeAdminEmailTextField(index)}
                  className={classes.deleteIcon}
                />
              </div>
            );
          })}
          <FormHelperText style={{ color: 'red' }}>{errors.adminEmail}</FormHelperText>
          <Button
            variant="contained"
            className={classes.btnColor}
            onClick={handleAdminEmail}
            startIcon={<AddIcon />}
          >
            {t('add_admins')}
          </Button>
          <br />
          <span className="add-admin-text">{t('add_admins_text')}</span>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', marginTop: 50, justifyContent: 'flex-end' }}>
        <Button variant="contained" className={classes.btnColor} onClick={handleNext}>
          {t('next')}
        </Button>
      </div>
    </Fragment>
  );
};

FirstStep.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleAdminEmail: PropTypes.func.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleRoleChange:PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func.isRequired,
  handleEmailCheck: PropTypes.func.isRequired,
  setAddressDetails: PropTypes.func.isRequired,
  removeAdminEmailTextField: PropTypes.func.isRequired,
  handleDescriptionText: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  setClubLogo: PropTypes.func.isRequired,
  removeClubLogo: PropTypes.func.isRequired,
  values: PropTypes.shape({
    clubSortName: PropTypes.string,
    clubEmail: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    sportType: PropTypes.arrayOf(PropTypes.shape({})),
    clubName: PropTypes.string,
    decriptionText: PropTypes.shape({}),
    website: PropTypes.string,
    contactNumber: PropTypes.string,
    nature: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    clubSortName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    sportType: PropTypes.string,
    clubName: PropTypes.string,
    clubDescription: PropTypes.string,
    adminEmail: PropTypes.string,
    clubEmail: PropTypes.string,
    clubLogoFiles: PropTypes.string,
    contactNumber: PropTypes.string,
    nature: PropTypes.string,
  }),
  savedLogo: PropTypes.arrayOf(PropTypes.shape({})),
  adminEmailsInput: PropTypes.arrayOf(PropTypes.object).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})),
};

FirstStep.defaultProps = {
  errors: {},
  types: [],
  savedLogo: [],
};

export default FirstStep;
