import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function AboutUs() {
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  return (
    <section className="about-section navigation" id="about-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="about-left">
              <div className="about-text">
                <h5 className="about-title">{t('about_part_subtitle')}</h5>
                <h2 className="about-subtitle">{t('about_part_title')}</h2>
                <h5 className="about-details">{t('about_part_text')}</h5>
                <p className="about-description">{t('about_part_description')}</p>
              </div>

              <div className="about-box">
                <div className="row text-left">
                  <div className="col-lg-6 col-md-6 col-sm-4">
                    <div className="single-about-box">
                      <h3>{t('about_part_simple')}</h3>
                      <div className="hover-box hover-left">
                        <a href="/ueber-uns">
                          {t('about_part_more')}
                          <i className="ren-arrowright" />
                        </a>
                      </div>
                    </div>
                    <div className="single-about-box">
                      <h3>{t('about_part_promo')}</h3>
                      <div className="hover-box hover-top">
                        <a href="/ueber-uns">
                          {t('about_part_more')}
                          <i className="ren-arrowright" />
                        </a>
                      </div>
                    </div>
                    <div className="single-about-box">
                      <h3>{t('about_part_secure')}</h3>
                      <div className="hover-box hover-right">
                        <a href="/ueber-uns">
                          {t('about_part_more')}
                          <i className="ren-arrowright" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="single-about-box">
                      <h3>{t('about_part_fast')}</h3>
                      <div className="hover-box hover-left">
                        <a href="/ueber-uns">
                          {t('about_part_more')}
                          <i className="ren-arrowright" />
                        </a>
                      </div>
                    </div>
                    <div className="single-about-box">
                      <h3>{t('about_part_overview')}</h3>
                      <div className="hover-box hover-bottom">
                        <a href="/ueber-uns">
                          {t('about_part_more')}
                          <i className="ren-arrowright" />
                        </a>
                      </div>
                    </div>
                    <div className="single-about-box">
                      <h3>{t('about_part_transparent')}</h3>
                      <div className="hover-box hover-right">
                        <a href="/ueber-uns">
                          {t('about_part_more')}
                          <i className="ren-arrowright" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-right">
              <div className="video-box">

                <iframe
                  width="80%"
                  height="450px"
                  src={t('embed_video_emotion')}
                  title="MY SPONSOR"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div className="icon-box">
                  <a className="video-play-btn popup-video">
                    <i className="ren-playicon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
