import { FETCH_MEMBERS_BY_PRODUCT, FETCH_MEMBERS_BY_PRODUCT_LOAD_MORE, FETCH_MEMBERS_PAYMENT_BY_PRODUCT, FETCH_USER_MEMBERSHIP, FETCH_USER_MEMBERSHIPS } from '../utils/types';

const initialState = {
  members: {},
  totalMembers: 0,
  userMemberships: [],
  memberPayments: [],
};


export default function membershipReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBERS_BY_PRODUCT:
      return {
        ...state,
        members: action.payload,
        totalMembers: action.payload.totalElements,
      };
    case FETCH_MEMBERS_BY_PRODUCT_LOAD_MORE:
      return {
        ...state,
        members: action.payload,
        totalMembers: action.payload.totalElements,
      };
    case FETCH_USER_MEMBERSHIPS:
      return { ...state, userMemberships: action.payload }
    case FETCH_USER_MEMBERSHIP:
      return { ...state };
    case FETCH_MEMBERS_PAYMENT_BY_PRODUCT:
      return { ...state, memberPayments: action.payload }
    default:
      return state;
  }
}
