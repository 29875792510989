import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import UserFavorites from './UserFavorites';
import bannerImg from '../../../assets/img/01.jpg';
import coverImg from '../../../assets/img/cover-user.jpg';
import DonationHistory from './DonationHistory';
import ConfirmDialog from '../../Alerts/ConfirmDialog';
import UserSubscriptions from './UserSubscription';
import { Dialog } from '@material-ui/core';
import PaymentForm from '../../Checkout/UpdatePaymentMethod';
import { loadStripe } from '@stripe/stripe-js';
import { stripeApiKey } from '../../../utils/helper';
import { Elements } from '@stripe/react-stripe-js';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import SuccessMessgae from '../../Alerts/SuccessMessage';
import UserMembership from './UserMembership';
import { withRouter } from 'react-router-dom';

const reset = (id) => {
  if (document.getElementById('active')) {
    document.getElementById("active").removeAttribute('id');

    document.getElementById(`tab${id}`).children[0].setAttribute('id', 'active');
  }
};

const stripePromise = loadStripe(stripeApiKey);

const BasicDetails = (props) => {
  const {
    tabId,
    profileImage,
    handleTabClick,
    handleChangePassword,
    donationData,
    userDetails,
    favorites,
    handleEditModalOpen,
    handleDeleteUserAccount,
    setConfirmOpen,
    openDeleteConfirm,
    setConfirmOpenUnsubscribe,
    openUnsubscribeConfirm,
    openVerifyConfirm,
    setVerifyConfirmOpen,
    verificationEmailRequest,
    userSubscriptions,
    userMemberships,
    handleCancelSubscriptionClick,
    openChangePaymentMethodModal,
    setOpenChangePaymentMethodModal,
    setPaymentMethodUpdateModalIsOpen,
    paymentMethodUpdateModalIsOpen,
    history,
    location,
  } = props;
  const { t, i18n } = useTranslation();
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const selectedLanguage = localStorage.getItem('language') || 'du';
  const isMsgTriggered = useSelector(state => state.formResponse.isMsgTriggered,);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const displayError = (msg) => {
    setErrorMsg(msg);
    setTimeout(() => {
        setErrorMsg('');
    }, 2000);
};

const hideSuccess = () => {
  setTimeout(() => {
      setSuccessMsg(false);
  }, 2500);
};

  const displayTabContent = (id) => {
    reset(id);
    switch (id) {
      case 0:
        return <DonationHistory donationData={donationData} />;
      case 1:
        return <UserFavorites favorites={favorites} />;
      case 2:
        return <UserSubscriptions
          userSubscriptions={userSubscriptions}
          handleCancelSubscriptionClick={handleCancelSubscriptionClick}
          setConfirmOpenUnsubscribe={setConfirmOpenUnsubscribe}
          openUnsubscribeConfirm={openUnsubscribeConfirm}
        />
      case 3:
          return <UserMembership
            userMemberships={userMemberships}
            userSubscriptions={userSubscriptions}
            handleCancelSubscriptionClick={handleCancelSubscriptionClick}
            setConfirmOpenUnsubscribe={setConfirmOpenUnsubscribe}
            openUnsubscribeConfirm={openUnsubscribeConfirm}
            history={history}
            location={location}
          />
      default:
        return null;
    }
  };

  const displayUserAddress = (userData) => {
    if (userData.address) {
      return (
        <div>
          <p>{userData.address.address1}</p>
          <p>{userData.address.address2}</p>
          <p>{userData.address.postalCode} {userData.address.city}</p>
          <p>{userData.address.country}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <div className="cover-img">
        <img style={{ objectFit: 'cover' }} src={coverImg} alt="cover-img" />
      </div>
      <section id="userPage" className="ls section-padding-top-150 section-padding-bottom-130 columns-padding-25">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              {isMsgTriggered && <SuccessMessgae isMsgTriggered={isMsgTriggered} msg={t('check_email')} />}
              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              <div className="vertical-item content-padding bottom-color-border rounded text-center">
                <div className="item-media">
                  {profileImage ? (
                    <img
                      style={{ width: '100%' }}
                      className="profile-img"
                      src={profileImage}
                      alt="profile-img"
                    />
                  ) : (
                    <img className="profile-img" src={bannerImg} alt="" />
                  )}
                </div>
                <div className="item-content">
                  <h4 className="entry-title bottom-margin-0">
                    {' '}
                    {userDetails.firstName || null} {userDetails.lastName || null}
                  </h4>
                  {/* <p className="small-text highlight">Producer</p> */}
                </div>
              </div>
              <div className="profile-contact-div user-details-profile">
                <div>
                  <strong><RoomIcon /> {t('address')}:</strong>
                  <span className="profile-contact-details">{displayUserAddress(userDetails)}</span>
                </div>
                <p>
                  <strong><EmailIcon /> {t('contact')}:</strong>
                  <span className="profile-contact-details">
                    <div>
                      <p>{userDetails.email || null}</p>
                    </div>
                  </span>
                </p>
              </div>
              <div>
                <Button
                  className="btn-global"
                  onClick={handleEditModalOpen}
                  fullWidth
                  variant="outlined"
                >
                  {t('profile_edit_profile')}
                </Button>

                <Button
                  onClick={handleChangePassword}
                  className="btn-global change-password-btn"
                  fullWidth
                  variant="outlined"
                >
                  {t('change_password')}
                </Button>

              {!userDetails.verified && <>
                <Button
                  onClick={() => setVerifyConfirmOpen(true)}
                  className="btn-global"
                  fullWidth
                  variant="outlined"
                >
                  {t('verify_account')}
                </Button>

                <ConfirmDialog
                  title={t('verify_account')}
                  open={openVerifyConfirm}
                  setOpen={setVerifyConfirmOpen}
                  onConfirm={verificationEmailRequest}
                  className={"btn-global"}
                  positiveRequest={true}
                >
                  {t('send_verification')}
                </ConfirmDialog>
              </>}

                <Button
                  onClick={() => setOpenChangePaymentMethodModal(true)}
                  className="btn-global"
                  fullWidth
                  variant="outlined"
                >
                  {t('update_payment_method')}
                </Button>

                <ConfirmDialog
                  title={t('change_payment')}
                  open={openChangePaymentMethodModal}
                  setOpen={setOpenChangePaymentMethodModal}
                  onConfirm={() => setPaymentMethodUpdateModalIsOpen(true)}
                  className={"btn-global"}
                  positiveRequest={true}
                >
                  {t('update_payment')}
                </ConfirmDialog>

                <Button
                  onClick={() => setConfirmOpen(true)}
                  className="btn-global delete-account-btn"
                  fullWidth
                  variant="outlined"
                >
                  {t('delete_account')}
                </Button>

                <ConfirmDialog
                  title={t('delete_account')}
                  open={openDeleteConfirm}
                  setOpen={setConfirmOpen}
                  onConfirm={handleDeleteUserAccount}
                  className={"btn-global delete-account-btn"}
                  positiveRequest={false}
                >
                  {t('do_you_want_to_delete')}
                </ConfirmDialog>

                <Dialog
                  open={paymentMethodUpdateModalIsOpen}
                  onClose={() => setPaymentMethodUpdateModalIsOpen(false)}
                  contentLabel="Payment Method Modal"
                  aria-hidden={false} // This is important to prevent accessibility issues

                >
                  <Elements stripe={stripePromise}>
                    <PaymentForm closeModal={setPaymentMethodUpdateModalIsOpen} />
                  </Elements>
                </Dialog>
              </div>
            </div>
            <div className="col-md-8 club-desc">
              {/* <!-- Nav tabs --> */}
              <ul className="nav nav-tabs top-margin-40 modern-tabs user-tabs-nav" role="tablist">
                <li id="tab0">
                  <a id="active" className="pp-tab-link" href="#payments/" onClick={() => handleTabClick(0)}>
                    {t('overview')}
                  </a>
                </li>
                <li id="tab1">
                  <a className="pp-tab-link" href="#favourites/" onClick={() => handleTabClick(1)}>
                    {t('favorites')}
                  </a>
                </li>
                <li id="tab2">
                  <a className="pp-tab-link" href="#subscriptions" onClick={() => handleTabClick(2)}>
                    {t('subscriptions')}
                  </a>
                </li>
                <li id="tab3">
                  <a className="pp-tab-link" href="#membership" onClick={() => handleTabClick(3)}>
                    {t('membership')}
                  </a>
                </li>
              </ul>
              {/* <!-- Tab panes --> */}
              <div className="tab-content big-padding top-color-border bottom-margin-40 tab-th modern-tabs-content user-tabs">
                {displayTabContent(tabId)}
              </div>
              {/* <!-- eof .tab-content --> */}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12"></div>
          </div> */}
        </div>
      </section>
    </Fragment>
  );
};

BasicDetails.propTypes = {
  tabId: PropTypes.number.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  handleEditModalOpen: PropTypes.func.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
  handleDeleteUserAccount: PropTypes.func.isRequired,
  setConfirmOpen: PropTypes.func.isRequired,
  setConfirmOpenUnsubscribe: PropTypes.func.isRequired,
  donationData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  favorites: PropTypes.arrayOf(PropTypes.shape({})),
  profileImage: PropTypes.string,
  userSubscriptions: PropTypes.arrayOf(PropTypes.shape({}))
};

BasicDetails.defaultProps = {
  userDetails: {},
  favorites: [],
  profileImage: '',
  userSubscriptions: [],
};

export default (withRouter(BasicDetails));

