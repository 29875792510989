import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { twintPayment } from '../../actions/clubActions';
import Loader from '../Spinner';
import successImage from '../../assets/img/image-payment-success.png';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import Footer from '../Footer/HomePageFooter';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 150,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paymentDetails: {
    display: 'flex',
    justifyContent: 'center',
    height: '72%',
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'center',
    height: '18%',
  },
  productDetails: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  headerText: {
    fontSize: '2.5rem',
    fontWeight: 600,
    textAlign: 'center',
  },
  productImage: {
    width: '100%',
    height: 200,
    objectFit: 'contain',
  },
}));

const successResponse = {
  success: true,
  message: 'Payment completed successfully',
};

const sendResponseToWebView = (response) => {
  console.log("success")
  console.log(response)
  window.ReactNativeWebView.postMessage(JSON.stringify(response));
}

const TwintSuccess = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const transactionId = params.get('datatransTrxId');
  const { paymentDetails } = useSelector((state) => state.club);
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';
  
  //sendResponseToWebView(successResponse);
  

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const redirectToHome = () => {
    props.history.push('/');
  };

  useEffect(() => {
    if (transactionId) {
      dispatch(twintPayment({ transactionId }));
    }
  }, [transactionId, dispatch]);

  useEffect(() => {
    if (paymentDetails.id) {
      setLoading(false);
    }
  }, [paymentDetails]);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
    <div>
      <CssBaseline />
      <Container className={classes.container} maxWidth="sm">
        {loading ? (
          <Loader />
        ) : (
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.headerText} component="div" variant="body1">
                  <Box color="success.main">{t('payment_success_title')}</Box>
                </Typography>
                <p style={{ textAlign: 'center' }}>
                  {t('payment_success_text')}
                </p>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className={classes.productDetails}>
                    <img className={classes.productImage} src={successImage} alt="product-img" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                  <Button onClick={redirectToHome} className="forward-btn payment-success-forward">
                    {t('payment_success_button')}
                  </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </Container>
    </div>
    <Footer />
  </Fragment>
  );
};

TwintSuccess.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default TwintSuccess;
