import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilterListIcon from '@material-ui/icons/FilterList';
import publicIp from 'public-ip';
import ipLocation from 'iplocation';
import Header from '../../components/Header';
import Footer from '../../components/Footer/HomePageFooter';
import ClubsList from '../../components/Pages/Club/ClubsList';
import ClubFilterModal from '../../components/Filter/ClubFilters';
import {
  getAllClubs,
  getAllTypes,
  filterClubs,
  searchClubByName,
  searchClubsByRadius,
} from '../../actions/clubActions';

const styles = {
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  btnStyle: {
    marginRight: 5,
    marginLeft: 5,
  },
};

const filterActions = {
  CLUB_SEARCH: 'CLUB_SEARCH',
  RADIUS_SEARCH: 'RADIUS_SEARCH',
  TYPE_SEARCH: 'TYPE_SEARCH',
};
class ClubLandingPage extends Component {
  state = {
    pageSize: 9,
    page: 1,
    data: [],
    searchData: [],
    typeData: [],
    radiusData: [],
    totalItems: 0,
    searchString: '',
    selectedTag: '',
    radius: '',
    selectedFilter: null,
    latitude: '',
    longitude: '',
    openFilter: false,
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    const { getAllClubs: fetchAllClubs, getAllTypes: fetchAllTypes } = this.props;
    fetchAllClubs({ pageId: page - 1, pageSize });
    fetchAllTypes();
  }

  componentDidUpdate(prevProps) {
    const { allClubs } = this.props;
    const { data, selectedFilter } = this.state;
    let { searchData, typeData, radiusData } = this.state;
    if (prevProps.allClubs !== allClubs) {
      if (allClubs.content) {
        let clubItems = [];
        if (selectedFilter === filterActions.CLUB_SEARCH) {
          clubItems = [...searchData, ...allClubs.content];
          searchData = clubItems;
          typeData = [];
          radiusData = [];
        } else if (selectedFilter === filterActions.RADIUS_SEARCH) {
          clubItems = [...radiusData, ...allClubs.content];
          radiusData = clubItems;
          typeData = [];
          searchData = [];
        } else if (selectedFilter === filterActions.TYPE_SEARCH) {
          clubItems = [...typeData, ...allClubs.content];
          typeData = clubItems;
          radiusData = [];
          searchData = [];
        } else {
          clubItems = [...data, ...allClubs.content];
          typeData = [];
          radiusData = [];
          searchData = [];
        }
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          data: clubItems,
          typeData,
          radiusData,
          searchData,
          totalItems: allClubs.totalElements,
        });
      }
    }
  }

  goToClubProfile = (shortName) => {
    const { history } = this.props;
    history.push(`/${shortName}`);
  };

  displayType = () => {
    const { types } = this.props;
    if (types && types.length > 0) {
      return types.map((type) => <option value={type.tag}>{type.tag}</option>);
    }
    return null;
  };

  clearFilters = () => {
    const { getAllClubs: fetchAllClubs } = this.props;
    fetchAllClubs({ pageId: 0, pageSize: 9 });
    this.setState({
      page: 1,
      selectedTag: '',
      searchString: '',
      radius: '',
      selectedFilter: null,
      typeData: [],
      radiusData: [],
      searchData: [],
      data: [],
    });
  };

  handleTypeChange = (e) => {
    const newChangedTag = e.target.value;
    const { pageSize } = this.state;
    this.setState({
      selectedTag: newChangedTag,
      searchString: '',
      radius: '',
      selectedFilter: filterActions.TYPE_SEARCH,
      typeData: [],
    });
    const { filterClubs: filterClubsByParams } = this.props;
    if (newChangedTag) {
      this.setState({ page: 1 });
      const tagSearchData = {
        tag: newChangedTag,
        pageId: 0,
        pageSize,
      };
      filterClubsByParams(tagSearchData);
    }
  };

  handleApplySearch = (e) => {
    e.preventDefault();
    const { pageSize, searchString } = this.state;
    const { searchClubByName: searchByName } = this.props;
    if (searchString) {
      this.setState({ page: 1 });
      const searchClubData = {
        searchString,
        pageId: 0,
        pageSize,
      };
      searchByName(searchClubData);
    }
  };

  handleSeachTextChange = (e) => {
    this.setState({
      searchString: e.target.value,
      selectedTag: '',
      radius: '',
      selectedFilter: filterActions.CLUB_SEARCH,
      searchData: [],
    });
  };

  handleRadiusTextChange = (e) => {
    this.setState({
      radius: e.target.value,
      selectedTag: '',
      searchString: '',
      selectedFilter: filterActions.RADIUS_SEARCH,
      radiusData: [],
    });
  };

  getClubsByRadius = async (e) => {
    e.preventDefault();
    const { radius, pageSize } = this.state;
    const { searchClubsByRadius: searchByRadius } = this.props;
    const ipAddress = await publicIp.v4();
    const locationDetails = await ipLocation(ipAddress);
    if (radius && locationDetails && locationDetails.latitude) {
      const radiusValue = parseInt(radius, 10);
      searchByRadius({
        latitude: locationDetails.latitude,
        longitude: locationDetails.longitude,
        radius: radiusValue,
        pageSize,
        pageId: 0,
      });
      this.setState({ latitude: locationDetails.latitude, longitude: locationDetails.longitude });
    }
  };

  handleLoadMore = (e) => {
    e.preventDefault();
    const {
      pageSize,
      page,
      selectedFilter,
      searchString,
      radius,
      selectedTag,
      latitude,
      longitude,
    } = this.state;
    const {
      getAllClubs: fetchAllClubs,
      searchClubByName: searchByName,
      filterClubs: filterClubsByParams,
      searchClubsByRadius: searchByRadius,
    } = this.props;
    const payload = { pageId: page, pageSize };
    if (selectedFilter === filterActions.CLUB_SEARCH) {
      payload.searchString = searchString;
      searchByName(payload);
    } else if (selectedFilter === filterActions.RADIUS_SEARCH) {
      payload.radius = radius;
      payload.latitude = latitude;
      payload.longitude = longitude;
      searchByRadius(payload);
    } else if (selectedFilter === filterActions.TYPE_SEARCH) {
      payload.tag = selectedTag;
      filterClubsByParams(payload);
    } else {
      fetchAllClubs(payload);
    }
    this.setState({ page: page + 1 });
  };

  handleOpenFilter = () => {
    this.setState({ openFilter: true });
  };


  render() {
    const { data, totalItems, selectedTag, searchString, radius, openFilter } = this.state;
    const { classes, types } = this.props;
    const hasMoreItems = totalItems > data.length;
    return (
      <Fragment>
        <div className="profile-header">
          <Header />
        </div>
        <div className={classes.root} id="filter-row">
          <Container className={classes.cardGrid} maxWidth="md">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <OutlinedInput
                  id="filled-adornment-club-name"
                  className="search-box"
                  aria-describedby="filled-club-name-helper-text"
                  inputProps={{
                    'aria-label': 'club-name',
                  }}
                  placeholder="Suchen..."
                  fullWidth
                  labelWidth={0}
                  onChange={this.handleSeachTextChange}
                  value={searchString}
                  endAdornment={
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon onClick={this.handleApplySearch} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={10} sm={3}>
                <Button
                  onClick={this.clearFilters}
                  className={classes.btnStyle}
                  variant="outlined"
                >
                  Filter zurücksetzen
                </Button>
              </Grid>
              <Grid item xs={2} sm={1}>
                <FilterListIcon onClick={this.handleOpenFilter} />
              </Grid>
            </Grid>
          </Container>
        </div>
        <ClubsList clubs={data} handleClick={this.goToClubProfile} />
        {hasMoreItems ? (
          <div className="club-pagination-div">
            <Button
              onClick={this.handleLoadMore}
              className="btn-global mb-5"
              variant="outlined"
              color="primary"
            >
              Load More
            </Button>
          </div>
        ) : null}
        <ClubFilterModal
          open={openFilter}
          radius={radius}
          types={types}
          selectedTag={selectedTag}
          getClubsByRadius={this.getClubsByRadius}
          handleRadiusTextChange={this.handleRadiusTextChange}
          handleTypeChange={this.handleTypeChange}
          handleClose={() => this.setState({ openFilter: false })}
        />
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allClubs: state.club.clubs,
  types: state.club.types,
});

const mapDispatchToProps = {
  getAllClubs,
  getAllTypes,
  filterClubs,
  searchClubByName,
  searchClubsByRadius,
};

ClubLandingPage.propTypes = {
  getAllClubs: PropTypes.func.isRequired,
  getAllTypes: PropTypes.func.isRequired,
  filterClubs: PropTypes.func.isRequired,
  searchClubByName: PropTypes.func.isRequired,
  searchClubsByRadius: PropTypes.func.isRequired,
  allClubs: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  classes: PropTypes.shape({
    root: PropTypes.string,
    cardGrid: PropTypes.string,
    btnStyle: PropTypes.string,
  }).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})),
};

ClubLandingPage.defaultProps = {
  allClubs: [],
  types: [],
  history: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClubLandingPage));
