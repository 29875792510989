import React, { Fragment, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';
import MyCheckoutForm from './DonationCheckout';
import Header from '../Header';
import Footer from '../Footer/HomePageFooter';
import { getClubProductById } from '../../actions/productActions';
import { stripeApiKey, isDecimalNumber } from '../../utils/helper';
import PriceSlider from '../PriceSlider';

const stripePromise = loadStripe(stripeApiKey);

const useStyles = makeStyles(() => ({
  root: {
    padding: '50px 65px 25px 85px',
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000', // (default alpha is 0.38)
    },
  },
  detailHeading: {
    textTransform: 'uppercase',
    fontWeight: 550,
  },
  title: {
    marginTop: 18,
    textTransform: 'uppercase',
  },
  productDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pricetag: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
  },
  addtionalBorder: {
    borderBottom: '1px solid',
  },
}));

const Checkout = () => {
  const classes = useStyles();
  const [enteredAmount, setEnteredAmount] = useState('');
  const [priceError, setPriceError] = useState('');
  const { clubProduct } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    if (location && location.state) {
      const { productId } = location.state;
      dispatch(getClubProductById({ id: productId }));
    } else {
      history.goBack();
    }
  }, [location, history, dispatch]);

  const handlePriceChange = (e) => {
    const enteredValue = e.target.value;
    setEnteredAmount(enteredValue);
    setPriceError('');
  };

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <Container className={classes.root} id="payment-box">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div>
              <h5>{t('payment_subtitle')}</h5>
              <h3 className="payment-page-titles">{t('payment_title')}</h3>
            </div>
            <Card>
              <CardContent>
                <p className="payment-sub-heading">
                  {t('payment_your_donation')} <span>{t('payment_your_donation_span')}</span>
                </p>
                <p className={classes.detailHeading}>
                  {clubProduct && clubProduct.clubName ? clubProduct.clubName : ''}
                </p>
                <div className={classes.productDetails}>
                  <p className={classes.title}>
                    {clubProduct && clubProduct.title ? clubProduct.title : ''}
                  </p>
                  <p className={classes.price}>
                    {clubProduct && clubProduct.price && !clubProduct.crowdFunding ? (
                      <TextField
                        id="standard-price-input"
                        className={classes.addtionalBorder}
                        label={t('payment_amount')}
                        value={clubProduct.price}
                        disabled
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start">CHF</InputAdornment>,
                          disableUnderline: true,
                        }}
                      />
                    ) : (
                      <TextField
                        id="standard-price-input"
                        label={t('payment_amount')}
                        value={enteredAmount}
                        onChange={handlePriceChange}
                        onKeyPress={isDecimalNumber}
                        error={priceError}
                        helperText={priceError}
                        className={classes.underline}
                        fullWidth
                        autoFocus 
                        InputProps={{
                          startAdornment: <InputAdornment position="start">CHF</InputAdornment>,
                        }}
                      />
                    )}
                    <span className={classes.pricetag}>
                      <PriceSlider selectedProduct={clubProduct} />
                    </span>
                  </p>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Elements stripe={stripePromise}>
              <MyCheckoutForm enteredAmount={enteredAmount} setPriceError={setPriceError} />
            </Elements>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Checkout;
