import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  viewBtn: {
    textDecoration: 'none',
  },
  scrollX: {
    overflow: 'auto'
  }
}));



const Results = ({
  className, members, pageDetails, handlePageChange, handlePageSizeChange, totalCount, setPageDetails, t, onClickMore, setemember, ...rest
}) => {
  const classes = useStyles();

  const handleClickMore = (member)=> {
    onClickMore(member.user.id, member.product.id);
    setemember(member)
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      id="membershipTab"
      {...rest}
    >
      <PerfectScrollbar id="membershipTableContainer">
        <Box className={classes.scrollX} id="membershipTable">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('first_name')}
                </TableCell>
                <TableCell>
                  {t('last_name')}
                </TableCell>
                <TableCell>
                  {t('mebership_type')}
                </TableCell>
                <TableCell>
                  {t('starting_date')}
                </TableCell>
                <TableCell>
                  {t('status')}
                </TableCell>
                <TableCell>
                  {t('paid')}
                </TableCell>
                <TableCell>
                  {t('more')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow
                  hover
                  key={member.id}
                >
                  <TableCell>
                    {member.user.firstName}
                  </TableCell>
                  <TableCell>
                    {member.user.lastName}
                  </TableCell>
                  <TableCell>
                    {member.product.title}
                  </TableCell>
                  <TableCell>
                    {moment(member.started).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    <span
                      className={'membershipStatus ' + (member.status === 'active' ? 'membershipStatusActive' : 'membershipStatusInactive')}
                    />
                  </TableCell>
                  <TableCell>
                    <span
                      className={'membershipStatus ' + (member.invoice && member.invoice.membershipPayment ? 'membershipPaid' : 'membershipUnpaid')}
                    />
                  </TableCell>
                  <TableCell onClick={()=> handleClickMore(member)} style={{ cursor: 'pointer' }}>
                    {t('more')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={pageDetails.pageId}
        rowsPerPage={pageDetails.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  members: PropTypes.array.isRequired,
  pageDetails: PropTypes.object.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePageSizeChange: PropTypes.func.isRequired
};

export default Results;
