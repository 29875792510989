import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateMembershipStatus, addExternalPayment } from '../../actions/membershipAction';
import moment from 'moment';
import PaymentHistory from '../Pages/Profile/ClubMemberPaymentHistoryList';


export default function ClubMemberModal(props) {
  const {
    open,
    closePopup,
    member
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';
  const { memberPayments } = useSelector((state) => state.member);
  const [amount, setAmount] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [active, setActive] = useState(member ? member.status : null);
  const [paymentPeriod, setPaymentPeriod] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const [errors, setErrors] = useState({
    amount: '',
    reactivationDate: '',
    paymentPeriod: '',
    invoiceId: ''
  });

  const addExternalPayments = () => {

    if (invoiceId == null)
      setErrors({
        ...errors,
        invoiceId: t('invalid_invoiceId')
      });
    else if (amount == null)
      setErrors({
        ...errors,
        amount: t('invalid_amount')
      });

    else {
      let data = {
        amount: amount,
        invoiceId: invoiceId,
        userId: member.user.id,
        productId: member.product.id
      };
      dispatch(addExternalPayment(data, t('external_payment_success'), t('external_payment_error')));
    }
  };

  const changeMemberStatus = () => {
    let data = {
      userId: member.user.id,
      productId: member.product.id,
      status: 'inactive'
    };
    if (member.status == 'inactive') {
      data.status = 'active';
    }
    setActive(data.status);
    dispatch(updateMembershipStatus(data));

  };


  const cancelMembership = () => {
    let data = {
      userId: member.user.id,
      productId: member.product.id,
      status: 'cancelled'
    };
    setActive(data.status);
    dispatch(updateMembershipStatus(data));

  };

  return (
    <Dialog
      id="membershipMore"
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      className="width-xl-60-modal create-product-modal"
    >
      <DialogTitle className="modal-title-center" id="responsive-dialog-title">
        {t('payments')}
      </DialogTitle>
      <DialogContent>
        <PaymentHistory
          details={memberPayments}
          member={member}
          t={t}
        />
      </DialogContent>

      <DialogActions className="action-buttons-modal">
        <Button variant="contained" className="btn-global-filled btn-dangerous" onClick={cancelMembership}>
          {t('remove_member')}
        </Button>
        <Button variant="contained" className="cancel-button" onClick={changeMemberStatus}>
          {member && member.status === 'active' ? t('inactivate') : t('activate')}
        </Button>

        <Button className="btn-global-filled float-right" onClick={closePopup} variant="contained">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ClubMemberModal.propTypes = {};

ClubMemberModal.defaultProps = {};