import { combineReducers } from 'redux';
import userReducer from './userReducer';
import clubReducer from './clubReducer';
import formResponseReducer from './formResponseReducer';
import spinnerReducer from './spinnerReducer';
import eventReducer from './eventReducer';
import productReducer from './productReducer';
import membershipReducer from './membershipReducers';

export default combineReducers({
  user: userReducer,
  club: clubReducer,
  formResponse: formResponseReducer,
  spinner: spinnerReducer,
  event: eventReducer,
  product: productReducer,
  member: membershipReducer, 

});
