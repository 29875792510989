import {
  GET_SUCCESS,
  CLEAR_SUCCESS,
  SUCCESS_REDIRECT,
  GET_ERROR,
  CLEAR_ERROR,
} from '../utils/types';

const initialState = {
  isFailed: false,
  isMsgTriggered: false,
  msg: '',
  redirect: '',
};

export default function FormResponseReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUCCESS:
      return { ...state, msg: action.payload, isMsgTriggered: true };
    case CLEAR_SUCCESS:
      return { ...state, msg: '', isMsgTriggered: false };
    case SUCCESS_REDIRECT:
      return { ...state, redirect: action.payload };
    case GET_ERROR:
      return { ...state, isFailed: true, msg: action.payload };
    case CLEAR_ERROR:
      return { ...state, isFailed: false, msg: '' };
    default:
      return state;
  }
}
