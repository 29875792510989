import {
  GET_SUCCESS,
  CLEAR_SUCCESS,
  SUCCESS_REDIRECT,
  GET_ERROR,
  CLEAR_ERROR,
} from '../utils/types';

export const displaySuccess = (msg) => {
  return {
    type: GET_SUCCESS,
    payload: msg,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR_SUCCESS,
  };
};

export const formRedirect = (redirectUrl) => {
  return {
    type: SUCCESS_REDIRECT,
    payload: redirectUrl,
  };
};

export const displayError = (msg) => {
  return {
    type: GET_ERROR,
    payload: msg,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};
