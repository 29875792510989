import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

import certificate from '../../../assets/img/bescheinigung.png';

const DonateReceipt = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Spendenbescheinigung | Sponsoring-Plattform | MY SPONSOR</title>
          <meta name="description" content="Sobald Du für Deinen Verein oder Athlet*in spendest, erhältst Du automatisch eine Spendenbescheinigung von uns per E-Mail." />
          <meta property="og:title" content="Spendenbescheinigung| Sponsoring-Plattform | MY SPONSOR" />
          <meta property="og:description" content="Sobald Du für Deinen Verein oder Athlet*in spendest, erhältst Du automatisch eine Spendenbescheinigung von uns per E-Mail." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/spendenbescheinigung" />
          <meta name="twitter:title" content="Spendenbescheinigung| Sponsoring-Plattform | MY SPONSOR" />
          <meta name="twitter:description" content="Sobald Du für Deinen Verein oder Athlet*in spendest, erhältst Du automatisch eine Spendenbescheinigung von uns per E-Mail." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>{t('donationcert_subtitle')}</h5>
              <h1 className="page-title">{t('donationcert_title')}</h1>
            </div>
            <div className="col-lg-5">
              <div className="text-default">
                <h2 className="title">{t('donationcert_texttitle')}</h2>
                <p className="text">{t('donationcert_text')}</p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="text-default">
                <img id="dontation-certificate" src={certificate} alt="Spendenbescheinigung | MY SPONSOR" />
              </div>
            </div>
          </div>
          <div className="row cta-row">
            <div className="col-lg-9">
              <h4>{t('cta_text')}</h4>
            </div>
            <div className="col-lg-3">
              <a href="/clubs" className="forward-btn">
                {t('donationcert_button')}
              </a>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default DonateReceipt;
