import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { getClubDetails, getClubsNearBy } from '../../actions/clubActions';
import Header from '../../components/Header';
import BasicDetails from '../../components/Pages/Profile/BasicDetails';
import Footer from '../../components/Footer/HomePageFooter';
import '../../assets/css/profile.css';
import {
  favoriteClub,
  getUserFavorites,
  unFavoriteClub,
  donateClub,
  requestEmailVerificationToken,
  getUserSubscriptions,
} from '../../actions/userActions';
import { clearProduct } from '../../actions/productActions';

class ClubPublicProfile extends Component {
  state = {
    isFavorited: false,
    clubId: '',
    vertical: 'top',
    horizontal: 'center',
    productData: {
      id: '',
      description: '',
      price: '',
      quantity: 1,
    },
    errors: { price: '' },
    openEmailVerification: false,
  };

  componentDidMount() {
    const {
      getClubDetails: fetchClubDetails,
      match,
      authUserData,
      getUserFavorites: userFavorites,
      getUserSubscriptions: userSubscriptions,
      clearProduct: clearProductCache,
      getClubsNearBy: fetchAllNearClubs,
    } = this.props;
    const { shortName } = match.params;
    if (shortName) {
      fetchClubDetails({ shortName });
      fetchAllNearClubs({
        pageId: 0,
        pageSize: 8,
        latitude: 0,
        longitude: 0,
      });
    }
    if (authUserData && authUserData.userId) {
      userFavorites({ userId: authUserData.userId });
    }
    if (authUserData && authUserData.userId) {
      userSubscriptions({ userId: authUserData.userId });
    }
    clearProductCache();
  }

  componentDidUpdate(prevProps) {
    const { clubId } = this.state;
    const { favoriteClubs, clubDetails } = this.props;
    if (clubDetails !== prevProps.clubDetails) {
      this.setState({ clubId: clubDetails.id });
    }

    if (prevProps.favoriteClubs !== favoriteClubs && favoriteClubs.length > 0) {
      const isFavoriteClub = favoriteClubs.find((a) => a.id === Number(clubId));
      if (isFavoriteClub) {
        this.setState({ isFavorited: true });
      }
    }
  }

  handleFavoriteClick = () => {
    const { clubId } = this.state;
    const { userLoggedIn, history, location, authUserData, favoriteClub: favorite } = this.props;
    if (userLoggedIn) {
      const { userId } = authUserData;
      const favoriteData = {
        clubId,
        userId,
      };
      favorite(favoriteData);
      this.setState({ isFavorited: true });
    } else {
      history.push({
        pathname: '/login',
        state: { from: location },
      });
    }
  };

  handleUnFavoriteClick = () => {
    const { clubId } = this.state;
    const {
      userLoggedIn,
      history,
      location,
      authUserData,
      unFavoriteClub: unFavorite,
    } = this.props;
    if (userLoggedIn) {
      const { userId } = authUserData;
      const favoriteData = {
        clubId,
        userId,
      };
      unFavorite(favoriteData);
      this.setState({ isFavorited: false });
    } else {
      history.push({
        pathname: '/login',
        state: { from: location },
      });
    }
  };

  handleDonationClick = (item) => {
    const { userLoggedIn, history, location, authUserData } = this.props;
    if (userLoggedIn && item && authUserData.donationEnabled) {
      history.push({
        pathname: '/donation/checkout',
        state: {
          productId: item.id,
          goBackRoute: `/${item.shortName}`,
          state: { from: location },
        },
      });
    } else if (userLoggedIn && !authUserData.donationEnabled) {
      this.setOpenEmailVerification(true)
    } else {
      history.push({
        pathname: '/login',
        state: { from: location },
      });
    }
  };

  verificationEmailRequest=()=>{
    const data = {email: this.props.authUserData.email}
    this.props.requestEmailVerificationToken(data)
  }

  setOpenEmailVerification = (state) =>{this.setState({openEmailVerification: state})}

  handleChange = (e) => {
    const { productData } = this.state;
    const { name, value } = e.target;
    productData[name] = value;
    this.setState({ productData });
  };

  isValidateForm = () => {
    let isValid = true;
    const { errors, productData } = this.state;
    if (!productData.id) {
      isValid = false;
    } else if (!productData.price) {
      isValid = false;
      errors.price = 'Bitte gib einen Betrag an.';
    }
    this.setState({ errors });
    return isValid;
  };

  render() {
    const { isFavorited, horizontal, vertical, openEmailVerification } = this.state;
    const {
      clubDetails,
      isMsgTriggered,
      msg,
      events,
      allClubs,
      products,
      authUserData,
      userSubscriptions,
    } = this.props;
    return (
      <Fragment>
        <div className="profile-header">
          <Header />
        </div>
        <Snackbar
          className="register-success"
          anchorOrigin={{ vertical, horizontal }}
          open={isMsgTriggered}
          message={msg}
          key={vertical + horizontal}
        />
        <BasicDetails
          clubDetails={clubDetails}
          profileType="public"
          clubList={allClubs}
          handleFavoriteClick={this.handleFavoriteClick}
          handleUnFavoriteClick={this.handleUnFavoriteClick}
          handleDonationClick={this.handleDonationClick}
          isFavorited={isFavorited}
          events={events}
          products={products}
          subscriptions={userSubscriptions}
          authUserData={authUserData}
          setOpenEmailVerification={this.setOpenEmailVerification}
          openEmailVerification={openEmailVerification}
          verificationEmailRequest={this.verificationEmailRequest}
        />
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  clubDetails: state.club.clubDetails,
  userLoggedIn: state.user.userLoggedIn,
  authUserData: state.user.authUserData,
  favoriteClubs: state.user.favoriteClubs,
  userSubscriptions: state.user.userSubscriptions,
  isMsgTriggered: state.formResponse.isMsgTriggered,
  msg: state.formResponse.msg,
  events: state.event.clubEvents,
  allClubs: state.club.nearByClubs,
  products: state.product.clubProducts,
  confirmDialogVisible : state.openEmailVerification,
});

const mapDispatchToProps = {
  getClubDetails,
  getClubsNearBy,
  favoriteClub,
  getUserFavorites,
  getUserSubscriptions,
  unFavoriteClub,
  donateClub,
  clearProduct,
  requestEmailVerificationToken,
};

ClubPublicProfile.propTypes = {
  getClubDetails: PropTypes.func.isRequired,
  getUserFavorites: PropTypes.func.isRequired,
  getUserSubscriptions: PropTypes.func.isRequired,
  clearProduct: PropTypes.func.isRequired,
  getClubsNearBy: PropTypes.func.isRequired,
  clubDetails: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  favoriteClub: PropTypes.func.isRequired,
  unFavoriteClub: PropTypes.func.isRequired,
  favoriteClubs: PropTypes.arrayOf(PropTypes.shape({})),
  userSubscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  authUserData: PropTypes.shape({
    userId: PropTypes.string,
  }),
  location: PropTypes.shape({}),
  userLoggedIn: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      shortName: PropTypes.string,
    }),
  }),
  isMsgTriggered: PropTypes.bool,
  msg: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  allClubs: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  requestEmailVerificationToken: PropTypes.func.isRequired,
};

ClubPublicProfile.defaultProps = {
  match: {
    params: {
      shortName: '',
    },
  },
  favoriteClubs: [],
  userLoggedIn: false,
  authUserData: {},
  location: {},
  isMsgTriggered: false,
  msg: null,
  events: [],
  allClubs: [],
  products: [],
  userSubscriptions:[]
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClubPublicProfile));
