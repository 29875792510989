import React, { Component, Fragment } from 'react';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import { withTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Footer from '../../components/Footer/HomePageFooter';
import EditClubBasicInformation from '../../components/Forms/EditClubBasicInformation';
import EditContactPerson from '../../components/Forms/EditContactPerson';
import EditBankDetails from '../../components/Forms/EditBankDetails';
import { validateEmail, getTargetLanguagesAndDescriptions, baseUrl, authHeader } from '../../utils/helper';
import {
  getClubRegistrationDetails,
  getAllTypes,
  updateClub,
  updateContactPerson,
  updateBankDetails,
} from '../../actions/clubActions';
import Spinner from '../../components/Spinner';
import Alerts from '../../components/Alerts';
import { updateFiles, uploadFiles, descriptionTranslate } from '../../actions/productActions';

const theme = createMuiTheme();
const translatedDescription = {
  EN: '',
  DE: '',
  FR: '',
  IT: '',
};
const styles = {
  root: {
    flexGrow: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
};

class EditClubProfile extends Component {
  state = {
    value: 0,
    clubId: '',
    clubs: {
      clubName: '',
      shortName: '',
      description: '',
      descriptionDe: '',
      descriptionFr: '',
      descriptionIt: '',
      email: '',
      website: '',
      address: {
        address1: '',
        address2: '',
        postalCode: '',
        city: '',
        country: '',
      },
      tags: [],
      sportType: [],
      contactNumber: '',
      nature: '',
    },
    contact: {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      contactNumber: '',
      address: {
        address1: '',
        address2: '',
        postalCode: '',
        city: '',
        country: '',
      },
    },
    bank: {
      accountName: '',
      iban: '',
      currency: '',
      country: '',
    },
    decriptionText: {
      enteredText: '',
      description: '',
      descriptionDe: '',
      descriptionFr: '',
      descriptionIt: '',
    },
    adminEmailsInput: [{email:'',role:''}],
    clubErrors: { address: {} },
    contactErrors: { address: {} },
    bankErrors: {},
    clubLogo: [],
    savedLogo: [],
    updatedLogo: [],
    documents: [],
    savedDocuments: [],
    deletedDocuments: {},
  };

  componentDidMount() {
    const {
      getAllTypes: fetchAllTypes,
      getClubRegistrationDetails: fetchAllClubDetails,
      authUserData,
    } = this.props;
    if (authUserData && authUserData.clubId) {
      this.setState({ clubId: authUserData.clubId });
      fetchAllClubDetails({ id: authUserData.clubId });
    }
    fetchAllTypes();
  }

  componentDidUpdate(prevProps) {
    const { clubDetails } = this.props;
    let { clubs, bank, adminEmailsInput } = this.state;
    const { decriptionText, savedDocuments } = this.state;
    let contactDetails = {};
    const logoDetails = [];
    if (prevProps.clubDetails !== clubDetails) {
      if (clubDetails.club) {
        clubs = clubDetails.club;
        clubs.clubName = clubDetails.club.name;
        decriptionText.description = clubDetails.club.description;
        decriptionText.descriptionDe = clubDetails.club.descriptionDe;
        decriptionText.descriptionFr = clubDetails.club.descriptionFr;
        decriptionText.descriptionIt = clubDetails.club.descriptionIt;
        adminEmailsInput = clubDetails.club.adminEmails
          ? this.setAdminEmails(clubDetails.club.adminEmails)
          : [];
        if (clubDetails.club.logo) {
          logoDetails.push({ logo: clubDetails.club.logo });
        }
      }
      if (clubDetails.contact) {
        contactDetails = clubDetails.contact;
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ contact: contactDetails });
      }
      if (clubDetails.bank) {
        bank = clubDetails.bank;
        bank.accountName = clubDetails.bank.name;
      }

      if (clubDetails.document) {
        Object.entries(clubDetails.document).forEach(([key, value]) => {
          savedDocuments.push({
            [key]: value,
          });
        });
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        clubs,
        bank,
        adminEmailsInput,
        savedLogo: logoDetails,
        decriptionText,
        savedDocuments,
      });
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  setAdminEmails = (adminEmails) => {
    const adminEmailsList = [];
    if (adminEmails.length > 0) {
      adminEmails.forEach((email, index) => {
        adminEmailsList.push(email);
      });
    }
    return adminEmailsList;
  };

  validateAddress = (address) => {
    let isValidAddress = true;
    const errors = {};
    if (!address.address1) {
      isValidAddress = false;
      errors.address1 = 'Bitte Adresse 1 ausfüllen.';
    }
    if (!address.city) {
      isValidAddress = false;
      errors.city = 'Bitte Ort angeben.';
    }
    if (!address.postalCode) {
      isValidAddress = false;
      errors.postalCode = 'Bitte Postleitzahl eingeben.';
    }
    if (!address.country) {
      isValidAddress = false;
      errors.country = 'Bitte Land angeben.';
    }
    return { isValid: isValidAddress, errors };
  };

  isValidBasicDetails = (adminEmails) => {
    let isValid = true;
    const { clubs, clubErrors, clubLogo, savedLogo } = this.state;
    const clubAddressValidInfo = this.validateAddress(clubs.address);
    if (!clubs.clubName) {
      isValid = false;
      clubErrors.clubName = 'Bitte gib einen Club-Name ein.';
    }
    if (!clubs.description) {
      isValid = false;
      clubErrors.description = 'Bitte erfasse einen Club-Beschreibung.';
    }
    if (clubs.tags.length === 0) {
      isValid = false;
      clubErrors.tags = 'Wähle mindestens einen Sportart.';
    }
    if (!clubAddressValidInfo.isValid) {
      isValid = false;
      clubErrors.address = clubAddressValidInfo.errors;
    }
    if (!clubs.email || !validateEmail(clubs.email)) {
      isValid = false;
      clubErrors.email = 'Bitte erfasse eine gültige E-Mailadresse.';
    }
    if (savedLogo.length === 0 && clubLogo.length === 0) {
      isValid = false;
      clubErrors.clubLogoFiles = 'Bitte lade Dein Club-Logo hoch.';
    }
    if (adminEmails.length === 0) {
      isValid = false;
      clubErrors.adminEmail = 'Du musst mindestens einen Administrator hinzufügen. Erfasse auch Deine E-Mailadresse.';
    } else if (!this.isEachEmailValid(adminEmails)) {
      isValid = false;
      clubErrors.adminEmail = 'Bitte eine gültige E-Mailadresse eingeben.';
    }
    this.setState({ clubErrors });
    return isValid;
  };

  isValidContactDetails = () => {
    let isValid = true;
    const { contact, contactErrors } = this.state;
    const contactAddressValidInfo = this.validateAddress(contact.address);
    if (!contact.firstName) {
      isValid = false;
      contactErrors.firstName = 'Bitte Vorname eingeben.';
    }
    if (!contact.lastName) {
      isValid = false;
      contactErrors.lastName = 'Bitte Nachname eingeben.';
    }
    if (!contact.email || !validateEmail(contact.email)) {
      isValid = false;
      contactErrors.email = 'Bitte Nachname eingeben.';
    }
    if (!contact.contactNumber) {
      isValid = false;
      contactErrors.contactNumber = 'Bitte Nachname eingeben.';
    }
    if (!contact.position) {
      isValid = false;
      contactErrors.position = 'Bitte Position im Club eingeben.';
    }
    if (!contactAddressValidInfo.isValid) {
      isValid = false;
      contactErrors.address = contactAddressValidInfo.errors;
    }
    this.setState({ contactErrors });
    return isValid;
  };

  isValidBankDetails = () => {
    let isValid = true;
    const { bank, bankErrors } = this.state;
    if (!bank.accountName) {
      isValid = false;
      bankErrors.accountName = 'Bitte den vollständingen Bank-Name eingeben.';
    }
    if (!bank.iban) {
      isValid = false;
      bankErrors.iban = 'Bitte erfasse Deine IBAN für die Auszahlungen.';
    }
    if (!bank.currency) {
      isValid = false;
      bankErrors.currency = 'Bitte erfasse die Währung.';
    }
    if (!bank.country) {
      isValid = false;
      bankErrors.country = 'Bitte wähle ein Land.';
    }
    this.setState({ bankErrors });
    return isValid;
  };

  handleSaveClubBaiscInfomation = async () => {
    const { clubId, clubs, adminEmailsInput, decriptionText } = this.state;
    const { updateClub: updateBasicInformation } = this.props;
    const descriptionObj = await this.transalateAllDescriptions();
    const clubdData = {
      id: clubId,
      name: clubs.clubName,
      shortName: slugify(clubs.clubName),
      email: clubs.email,
      website: clubs.website,
      address: clubs.address,
      tags: clubs.tags.map((tag) => ({ id: tag.id })),
      adminEmails: adminEmailsInput,
      description: descriptionObj ? descriptionObj.EN : decriptionText.description,
      descriptionDe: descriptionObj ? descriptionObj.DE : decriptionText.descriptionDe,
      descriptionFr: descriptionObj ? descriptionObj.FR : decriptionText.descriptionFr,
      descriptionIt: descriptionObj ? descriptionObj.IT : decriptionText.descriptionIt,
      nature: clubs.nature,
    };
    if (this.isValidBasicDetails(adminEmailsInput)) {
      updateBasicInformation(clubdData, () => {
        this.updateClubLogo(clubId);
      });
    }
  };

  handleSaveContactInformation = () => {
    const { updateContactPerson: updateContact } = this.props;
    const { clubId, contact } = this.state;
    const contactPersonData = {
      clubId,
      ...contact,
    };
    if (this.isValidContactDetails()) {
      updateContact(contactPersonData);
    }
  };

  handleSaveBankDetails = () => {
    const { bank, clubId, documents } = this.state;
    const { updateBankDetails: updateBank } = this.props;
    const bankData = {
      clubId,
      id: bank.id,
      name: bank.accountName,
      iban: bank.iban,
      currency: bank.currency,
      country: bank.country,
    };
    if (this.isValidBankDetails()) {
      updateBank(bankData, documents);
    }
  };

  handleAdminEmail = () => {
    const { adminEmailsInput } = this.state;
    adminEmailsInput.push({
      email: '',
      role: 'ROLE_CLUB'
    });
    this.setState({ adminEmailsInput });
  };
  removeAdminEmailTextField = (index) => {
    const { adminEmailsInput } = this.state;
    adminEmailsInput.splice(index, 1);
    this.setState({ adminEmailsInput });
  };

  handleEmailChange = (e, index,role) => {
    const { adminEmailsInput, clubErrors } = this.state;
    const { name, value } = e.target;
    adminEmailsInput[index] = {email: value, role: role};
    if (!validateEmail(value)) {
      clubErrors.adminEmail = 'Bitte eine gültige E-Mailadresse eingeben.';
    } else {
      clubErrors.adminEmail = '';
    }
    this.setState({ adminEmailsInput, clubErrors });
  };

  handleTypeChange = (event) => {
    const { clubs, clubErrors } = this.state;
    const tagsList = [];
    tagsList.push({ id: event.target.value });
    clubs.tags = tagsList;
    clubErrors.tags = '';
    this.setState({ clubs, clubErrors });
  };

  handleNatureChange = (event) => {
    const { clubs, clubErrors } = this.state;
    clubs.nature = event.target.value;
    clubErrors.nature = '';
    this.setState({ clubs, clubErrors });
  }
  handleRoleChange = (e, index,email) => {
    const { adminEmailsInput, clubErrors } = this.state;
    const { name, value } = e.target;
    let adminAvailable = false;
    adminEmailsInput.forEach(val=> {
      if(val.role == 'ROLE_CLUB') adminAvailable = true;
    })
    if(!adminAvailable) clubErrors.adminEmail = 'Select at least one Administrator';
    adminEmailsInput[index] = {role: value, email: email};
    this.setState({ adminEmailsInput });
  };

  setAddressDetails = (placeData) => {
    const { clubs } = this.state;
    const clubAddress = clubs.address;
    const addressDetails = placeData.address_components ? placeData.address_components : null;
    const geometry = placeData.geometry ? placeData.geometry : null;
    const placeDetails = {
      streetNumber: '',
      route: '',
      premise: '',
    };
    if (geometry) {
      clubAddress.latitude = geometry.location ? geometry.location.lat() : '';
      clubAddress.longitude = geometry.location ? geometry.location.lng() : '';
    }
    if (addressDetails && addressDetails.length > 0) {
      for (let i = 0; i < addressDetails.length; i += 1) {
        const addressComponent = addressDetails[i];
        const addressType = addressComponent.types[0];

        switch (addressType) {
          case 'street_number':
            placeDetails.streetNumber = addressComponent.long_name;
            break;
          case 'premise':
            placeDetails.premise = addressComponent.short_name;
            break;
          case 'route':
            placeDetails.route = addressComponent.long_name;
            break;
          case 'locality':
            clubAddress.city = addressComponent.long_name;
            break;
          case 'postal_code':
            clubAddress.postalCode = addressComponent.long_name;
            break;
          case 'country':
            clubAddress.country = addressComponent.short_name;
            break;
          default:
            break;
        }
      }
      let address = '';
      if (placeDetails.route) {
        address = placeDetails.route;
      } else if (placeDetails.premise) {
        address = placeDetails.premise;
      }
      clubAddress.address1 = address;
      this.setState({ clubs });
    }
  };

  handleBasicDetailsChange = (e) => {
    const { clubs } = this.state;
    const { name, value } = e.target;
    clubs[name] = value;
    this.setState({ clubs });
  };

  handleContactDetailsChange = (e) => {
    const { contact } = this.state;
    const { name, value } = e.target;
    contact[name] = value;
    this.setState({ contact });
  };

  handleBankDetailsChange = (e) => {
    const { bank } = this.state;
    const { name, value } = e.target;
    bank[name] = value;
    this.setState({ bank });
  };

  handleAddressChange = (e, type) => {
    const { clubs, contact } = this.state;
    const { name, value } = e.target;
    if (type === 'club') {
      clubs.address[name] = value;
      this.setState({ clubs });
    } else {
      contact.address[name] = value;
      this.setState({ contact });
    }
  };

  isEachEmailValid = (emailList) => {
    let isValid = true;
    if (emailList && emailList.length > 0) {
      for (let i = 0; i < emailList.length; i += 1) {
        if (!validateEmail(emailList[i].email)) {
          isValid = false;
          return isValid;
        }
      }
    }
    return isValid;
  };

  setNewClubLogo = (file) => {
    const { clubErrors } = this.state;
    const imageFile = [];
    imageFile.push(file);
    clubErrors.clubLogoFiles = '';
    this.setState({ clubLogo: imageFile, clubErrors });
  };

  removeClubLogo = (fileName) => {
    const { updatedLogo, savedLogo } = this.state;
    const imageFile = savedLogo.find((file) => file.logo === fileName);
    if (imageFile) {
      imageFile.action = 'remove';
      updatedLogo.push(imageFile);
      this.setState({ updatedLogo: imageFile });
    }
    this.setState({ savedLogo: [] });
  };

  updateClubLogo = (id) => {
    const { clubLogo, updatedLogo } = this.state;
    const {
      updateFiles: updateNewClubLogo,
      uploadFiles: uploadNewLogo,
      getClubRegistrationDetails: fetchClubDetails,
    } = this.props;

    if (clubLogo.length > 0) {
      const fileData = new FormData();
      const fileName = updatedLogo.logo ? updatedLogo.logo : '';
      fileData.append('file', clubLogo[0]);
      fileData.append('entity', 'club');
      fileData.append('column', 'logo');
      fileData.append('id', id);

      if (fileName) {
        fileData.append('fileName', fileName);
        updateNewClubLogo(fileData, () => {
          fetchClubDetails({ id });
        });
      } else {
        uploadNewLogo(fileData, () => {
          fetchClubDetails({ id });
        });
      }
    }
  };

  transalateAllDescriptions = async () => {
    const { decriptionText } = this.state;
    const descriptionText = decriptionText.enteredText;
    if (descriptionText) {
      const selectedLang = localStorage.getItem('language') || 'du';
      const targetLanguagesAndDescriptions = getTargetLanguagesAndDescriptions(
        translatedDescription,
        descriptionText,
        selectedLang
      );
      const targetTranslationList = targetLanguagesAndDescriptions.targetLanguagesList || [];
      const translatedTextList = targetLanguagesAndDescriptions.descriptionList;

      // eslint-disable-next-line no-restricted-syntax
      for (const targetLang of targetTranslationList) {
        // eslint-disable-next-line no-await-in-loop
        const response = await descriptionTranslate(descriptionText, targetLang);
        if (response) {
          translatedTextList[targetLang] = response;
        }
      }
      return translatedTextList;
    }
    return null;
  };

  handleDescriptionText = (e) => {
    const { decriptionText, clubErrors } = this.state;
    const { name, value } = e.target;
    decriptionText.enteredText = value;
    decriptionText[name] = value;
    clubErrors[name] = '';
    this.setState({ decriptionText, clubErrors });
  };

  saveDocuments = (file, docType) => {
    const { documents, deletedDocuments } = this.state;
    const fileName = deletedDocuments[docType] ? deletedDocuments[docType] : null;
    if (!file) return;
    documents.push({
      file,
      type: docType,
      fileName,
    });
    this.setState({ documents });
  };

  deleteDocuments = (file, docType) => {
    const { deletedDocuments, documents } = this.state;
    if (file.fileName) {
      deletedDocuments[docType] = file.fileName;
      this.setState({ deletedDocuments });
    } else {
      const findDocumet = documents.find((document) => document.type === docType);
      if (findDocumet) {
        const otherDocs = documents.filter((document) => document !== findDocumet);
        this.setState({ documents: otherDocs });
      }
    }
  };
  checkEmailExistOrNot = async (e) => {
    const { clubErrors } = this.state;
    let isExist = true;
    const response = await fetch(`${baseUrl}/users/email-admin`, {
      method: 'post',
      ...authHeader(),
      body: JSON.stringify({ email: e.target.value }),
    });
    const data = await response.json();
    isExist = data.emailExists;
    if (isExist) {
      clubErrors.adminEmail = 'Diese E-Mailadresse besitzt noch keinen MY SPONSOR Account. Die Person muss sich zuerst auf MY SPONSOR registrieren.';
    } else {
      clubErrors.adminEmail = '';
    }
    this.setState({ clubErrors });
  };

  displayEditForms = (step) => {
    const { types, authUserData } = this.props;
    const {
      clubs,
      contact,
      bank,
      adminEmailsInput,
      clubErrors,
      contactErrors,
      bankErrors,
      savedLogo,
      decriptionText,
      savedDocuments,
    } = this.state;
    switch (step) {
      case 0:
        return (
          <EditClubBasicInformation
            handleSave={this.handleSaveClubBaiscInfomation}
            handleChange={this.handleBasicDetailsChange}
            decriptionText={decriptionText}
            values={clubs}
            adminEmailsInput={adminEmailsInput}
            handleAdminEmail={this.handleAdminEmail}
            handleRoleChange={this.handleRoleChange}
            handleEmailCheck={this.checkEmailExistOrNot}
            handleEmailChange={this.handleEmailChange}
            removeAdminEmailTextField={this.removeAdminEmailTextField}
            errors={clubErrors}
            types={types}
            handleTypeChange={this.handleTypeChange}
            setAddressDetails={this.setAddressDetails}
            handleAddressChange={this.handleAddressChange}
            setClubLogo={this.setNewClubLogo}
            removeClubLogo={this.removeClubLogo}
            savedLogo={savedLogo}
            handleDescriptionText={this.handleDescriptionText}
            handleNatureChange={this.handleNatureChange}
            authUserData={authUserData}
          />
        );
      case 1:
        return (
          <EditContactPerson
            handleSave={this.handleSaveContactInformation}
            handleChange={this.handleContactDetailsChange}
            values={contact}
            errors={contactErrors}
            handleAddressChange={this.handleAddressChange}
            authUserData={authUserData}
          />
        );
      case 2:
        return (
          <EditBankDetails
            handleSave={this.handleSaveBankDetails}
            handleChange={this.handleBankDetailsChange}
            values={bank}
            errors={bankErrors}
            documents={savedDocuments}
            saveDocuments={this.saveDocuments}
            deleteDocuments={this.deleteDocuments}
            authUserData={authUserData}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { value } = this.state;
    const { classes, isLoading, isFailed, errorMsg, t } = this.props;
    const selectedLanguage = localStorage.getItem('language') || 'du';
    return (
      <Fragment>
        <div className="profile-header">
          <Header />
        </div>
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={t('club_informations', { lng: selectedLanguage })} />
            <Tab label={t('contact_person', { lng: selectedLanguage })} />
            <Tab label={t('bank_details_documents', { lng: selectedLanguage })} />
          </Tabs>
        </Paper>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {isFailed ? <Alerts msg={errorMsg} type="error" /> : null}
            {isLoading ? <Spinner /> : this.displayEditForms(value)}
          </Paper>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

EditClubProfile.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    layout: PropTypes.string,
    paper: PropTypes.string,
  }).isRequired,
  getAllTypes: PropTypes.func.isRequired,
  updateFiles: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  getClubRegistrationDetails: PropTypes.func.isRequired,
  authUserData: PropTypes.shape({
    clubId: PropTypes.number,
  }),
  t: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})),
  clubDetails: PropTypes.shape({
    club: PropTypes.shape({
      name: PropTypes.string,
      shortName: PropTypes.string,
      description: PropTypes.string,
      descriptionDe: PropTypes.string,
      descriptionFr: PropTypes.string,
      descriptionIt: PropTypes.string,
      email: PropTypes.string,
      logo: PropTypes.string,
      address: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
      }),
      tags: PropTypes.arrayOf(PropTypes.shape({})),
      adminEmails: PropTypes.arrayOf(PropTypes.object),
    }),
    contact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      position: PropTypes.string,
      email : PropTypes.string,
      contactNumber: PropTypes.string,
      address: PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
      }),
    }),
    bank: PropTypes.shape({
      name: PropTypes.string,
      iban: PropTypes.number,
      currency: PropTypes.string,
      country: PropTypes.string,
    }),
    document: PropTypes.shape({}),
  }),
  updateClub: PropTypes.func.isRequired,
  updateContactPerson: PropTypes.func.isRequired,
  updateBankDetails: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMsg: PropTypes.string,
};

EditClubProfile.defaultProps = {
  authUserData: {},
  clubDetails: {
    club: {},
    contact: {},
    bank: {},
    document: {},
  },
  types: [],
  isLoading: false,
  isFailed: false,
  errorMsg: '',
};

const mapStateToProps = (state) => ({
  clubDetails: state.club.clubRegistrationDetails,
  types: state.club.types,
  authUserData: state.user.authUserData,
  isLoading: state.spinner.isLoading,
  isFailed: state.formResponse.isFailed,
  errorMsg: state.formResponse.msg,
});

const mapDispatchToProps = {
  getClubRegistrationDetails,
  getAllTypes,
  updateClub,
  updateContactPerson,
  updateBankDetails,
  updateFiles,
  uploadFiles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(EditClubProfile)));
