import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { displayDescription, formatDate, imageBaseUrl } from '../../../utils/helper';
import logoSample from '../../../assets/img/myFavoriteHolder.png';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../Alerts/ConfirmDialog';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardContent, Collapse, Divider, List, ListItem, ListItemText } from '@material-ui/core';


const useStyles = makeStyles({
  root: {
    marginBottom: 15,
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  invoices: {
    marginBottom: 15,
    borderRadius: 8,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  imageContainer: {
    marginRight: 15,
  },
  image: {
    width: 100,
    height: 'auto',
    borderRadius: 4,
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    marginBottom: 8,
    fontWeight: 'bold',
    color: '#333',
  },
  description: {
    marginBottom: 12,
    color: '#666',
  },
  info: {
    fontSize: 14,
    color: '#777',
  },
  subscriptionCard: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: 15,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    position: 'relative',
  },
  unsubscribeButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    backgroundColor: '#FF5733', // Change the background color to your preference
    color: '#FFF',
    borderRadius: 4,
    padding: '8px 16px',
    fontSize: 14,
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E74C3C', // Change the hover color if desired
    },
  },
  listTextA: {
    width: '100%',
  },
  listTextB: {
    width: '20%',
    position: 'absolute',
    bottom: 10,
    right: 10,
    backgroundColor: '#FF5733', // Change the background color to your preference
    color: '#FFF',
    borderRadius: 4,
    padding: '8px 16px',
    fontSize: 14,
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E74C3C', // Change the hover color if desired
    },
  },
});

const translatePeriod = (period, t) => {
  if (period === 'week') return t('per_week');
  if (period === 'month') return t('per_month');
  if (period === 'year') return t('per_year');
}

export default function UserMembership(props) {
  const { userMemberships, history, location } = props;
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';
  const { authUserData } = useSelector((state) => state.user);
  const [expanded, setExpanded] = useState(false);
  const [tabIndex, setTabIndex] = useState(null);
  const [product, setProduct] = useState(null)


  const handleExpandClick = (index, product) => {
    setExpanded(!expanded);
    setTabIndex(index);
    setProduct(product)
  };

  const displayDonationDetails = (details, index) => {
    return details && details.length > 0 && tabIndex === index ? (
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {details.map((detail) => (
            <List className={styles.invoices}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  className={styles.listTextA}
                  primary={`${formatDate(detail.period)} - ${formatDate(detail.nextPeriod)}`}
                />
                <ListItemText className={styles.listTextA} primary={`CHF ${detail.amount.toFixed(2)}`} />
                {detail.membershipPayment == null &&
                  <ListItemText className={styles.listTextB}primary={t('pay')} onClick={() => handleDonationClick(detail.id)} >
                    <button onClick={() => handleDonationClick(detail.id)}
                      className={styles.unsubscribeButton}>{t("pay")}</button>
                  </ListItemText>
                }
              </ListItem>
              <Divider component="hr" />
            </List>
          ))}
        </CardContent>
      </Collapse>
    ) : null;
  };


  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleDonationClick = (invoiceId) => {
    history.push({
      pathname: '/donation/checkout',
      state: {
        productId: product.id,
        invoiceId: invoiceId,
        goBackRoute: `/`,
        state: { from: location },
      },
    });
  };

  const displaySubscriptions = (memberships) => {
    let membershipList = null;
    if (memberships && memberships.length > 0) {
      membershipList = memberships.map((mem, index) => {

        return (
          <div >
            <div className={`row user-abo ${styles.root}`} key={mem.id}>
              <div className="col-md-2">
                <div className="item-media top-rounded overflow-hidden">
                  {mem.product.image ? (
                    <img className="single-product-img" src={`${imageBaseUrl}/${mem.product.image}`} alt="" />
                  ) : (
                    <img className="single-product-img" src={logoSample} alt="" />
                  )}
                </div>
              </div>
              <div className="col-md-8">
                <div className="item-content">
                  <h5>
                    <a className={mem.product.title}>
                      {mem.product.title}
                    </a>
                  </h5>
                  <p>{displayDescription(mem.product, selectedLanguage)}</p>
                  <p>
                    {`${t("payment_amount")}  CHF: ${mem.product.price.toFixed(2)}`}
                    <br />
                    {/* {`${t("next_period")}: ${moment(mem.product.nextPeriod).format('DD.MM.YYYY')}`}
                  <br />
                  {`${t("current_period")}: ${moment(mem.product.currentPeriod).format('DD.MM.YYYY')}`} */}
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <button onClick={() => handleExpandClick(index, mem.product)}
                  className={styles.unsubscribeButton}>{t("pay")}</button>
              </div>

            </div>
            {displayDonationDetails(mem.invoices, index)}
          </div>
        );
      });
    }
    return membershipList;
  };

  return (
    <div className="tab-pane in active" id="tab4">
      {displaySubscriptions(userMemberships)}
    </div>
  );
}

UserMembership.propTypes = {
  userMembership: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    product: PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      recurringPeriod: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    nextDate: PropTypes.string.isRequired,
  })).isRequired,
  handleCancelSubscriptionClick: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({}),
};

UserMembership.defaultProps = {
  userMembership: [],
  location: {},
};
