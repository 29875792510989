import axios from 'axios';
import {
  FETCH_USER_DETAILS,
  LOGIN_SUCCESS,
  LOGOUT,
  FETCH_USER_FAVORITES,
  FETCH_USER_DONATION_HISTORY,
  FETCH_USER_DONATION_BY_CLUBID,
  FETCH_USER_DELETE,
  FETCH_USER_SUBSCRIPTIONS
} from '../utils/types';
import { baseUrl, headers, authHeader } from '../utils/helper';
import {
  isUserLoggedIn,
  isTokenExpired,
  setAuthAndRefreshTokens,
  clearUserState,
  getRefreshToken,
} from '../reducers/userReducer';
import {
  displaySuccess,
  clearSuccess,
  displayError,
  clearError,
  formRedirect,
} from './formResponseAction';
import displaySpinner from './spinnerAction';


// get refresh token
export const refreshToken = async () => {
  if (!isUserLoggedIn()) {
    clearUserState();
    return false;
  }

  if (isTokenExpired()) {
    const tokenData = {
      refreshToken: getRefreshToken(),
    };
    const response = await axios.post(`${baseUrl}/users/token-refresh`, tokenData, headers);
    if (response && response.data) {
      setAuthAndRefreshTokens(response.data.token, response.data.refreshToken);
    }
  }
  return true;
};

// action creator
export const getUserDetails = (userdata) => {
  return async (dispatch) => {
    refreshToken();
    try {
      const response = await axios.post(
        `${baseUrl}/users/id`,
        { userId: userdata.userId },
        authHeader(userdata.token)
      );
      if (response && response.data) {
        dispatch({
          type: FETCH_USER_DETAILS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const userSingUp = (userdata) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users`, userdata, headers);
      if (response && response.data) {
        dispatch(displaySuccess('Benutzer erfolgreich erstellt!'));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });
        dispatch(getUserDetails({ userId: response.data.id, token: response.data.token }));
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const userLogin = (userdata) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users/login`, userdata, headers);
      if (response && response.data) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });
        dispatch(getUserDetails({ userId: response.data.id, token: response.data.token }));
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const userLogOut = () => {
  return {
    type: LOGOUT,
  };
};

export const getUserFavorites = (userdata) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/favourites/filter`, userdata, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_USER_FAVORITES,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const getUserDonationHistory = (userdata) => {
  return async (dispatch) => {
    refreshToken();
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(
          `${baseUrl}/donations/user-club-total`,
          userdata,
          authHeader()
        );
        if (response && response.data) {
          dispatch({
            type: FETCH_USER_DONATION_HISTORY,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const favoriteClub = (userdata) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/favourites`, userdata, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Favorit gespeichert!'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const unFavoriteClub = (userdata) => {
  return async (dispatch, getState) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const { user } = getState();
        const response = axios({
          method: 'DELETE',
          url: `${baseUrl}/favourites`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.authUserData.token}`,
          },
          data: userdata,
        });
        if (response) {
          dispatch(displaySuccess('Favorit gelöscht!'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const donateClub = (userdata, callBack) => {
  return async (dispatch) => {
    refreshToken();
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations`, userdata, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Erfolgreich gespendet!'));
          callBack();
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        callBack();
        setTimeout(() => {
          dispatch(clearError());
        }, 3000);
      }
      console.error('there is an error', error);
    }
  };
};

export const getUserDonationByClubId = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/user-club`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_USER_DONATION_BY_CLUBID,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const updateUserDetails = (data, callBack) => {
  return async (dispatch, getState) => {
    const { user } = getState();
    try {
      dispatch(displaySpinner(true));
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/users`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySpinner(false));
          dispatch(displaySuccess('Deine Daten wurden aktualisiert!'));
          dispatch(getUserDetails({ userId: response.data.id, token: user.authUserData.token }));
          callBack();
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        callBack();
        setTimeout(() => {
          dispatch(clearError());
        }, 3000);
      }
      console.error('there is an error', error);
    }
  };
};

export const changeUserPassword = (data, onsuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/users/change-password`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Passwort erfolgreich aktualisiert!'));
          setTimeout(() => {
            dispatch(clearSuccess());
            onsuccess();
          }, 2000);
        }
      }
    } catch (error) {
      onsuccess();
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 3000);
      }
      console.error('there is an error', error);
    }
  };
};

export const forgotPassword = (userdata) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users/forgot-password`, userdata, headers);
      if (response) {
        dispatch(displaySuccess('Ein Passwort-Token wurde soeben an Dich gesendet!'));
        setTimeout(() => {
          dispatch(clearSuccess());
          dispatch(formRedirect('/reset-password'));
        }, 2000);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const resetPassword = (userdata) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users/reset-password`, userdata, headers);
      if (response) {
        dispatch(displaySuccess('Passwort wurde erfolgreich zurückgesetzt!'));
        setTimeout(() => {
          dispatch(clearSuccess());
          dispatch(formRedirect('/login'));
        }, 2000);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const deleteUserAccount = (userdata) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users/delete`, userdata, authHeader());
      if (response.status === 200) {
        dispatch({
          type: FETCH_USER_DELETE,
          payload: response.data,
        });
        setTimeout(() => {
          dispatch(clearSuccess());
          dispatch({
            type: LOGOUT,
          })
          dispatch(formRedirect('/'));
        }, 2000);
      } else {
        dispatch(displayError("Fehler – bitte kontaktiere support@mysponsor.ch"));
        setTimeout(() => {
          dispatch(clearError());
        }, 400);
        console.error('there is an error', response.error);
      }
    } catch (error) {
      dispatch(displayError("Fehler – bitte kontaktiere support@mysponsor.ch"));
      setTimeout(() => {
        dispatch(clearError());
      }, 400);

      console.error('there is an error', error);
    }
  };
};

export const updateUserToken = (tokenData) => {
  return async () => {
    try {
      const response = await axios.post(`${baseUrl}/users/token-refresh`, tokenData, headers);
      if (response && response.data) {
        setAuthAndRefreshTokens(response.data.token, response.data.refreshToken);
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};


export const requestEmailVerificationToken = (tokenData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/users/request-email-verification`, tokenData, headers);
      console.log(response)
      if (response && response.status == 200) {
        dispatch(displaySuccess('Please check your email!'));
        setTimeout(() => {
          dispatch(clearSuccess());
        }, 6000);
      } else{
          dispatch(displayError("Invalid email"));
          setTimeout(() => {
            dispatch(clearError());
          }, 4000);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError("Invalid email"));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};


export const getUserSubscriptions = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/subscriptions/user-subscriptions`, data, authHeader());
      if (response && response.data) {
        dispatch({
          type: FETCH_USER_SUBSCRIPTIONS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const cancelUserSubsriptions = (data, successMessage, errorMessage) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/subscriptions/cancel-subscription`, data, authHeader());
      if (response && response.status == 200) {
        dispatch(displaySuccess(successMessage));
        setTimeout(() => {
          dispatch(clearSuccess());
        }, 6000);
        dispatch(getUserSubscriptions({ userId: data.userId }))
      } else {
        dispatch(displayError(errorMessage));
        setTimeout(() => {
          dispatch(clearError());
        }, 6000);
      }

    } catch (error) {
      console.error('there is an error', error);
    }
  };
};


export const contactUsFormSend = (data, successMessage, errorMessage,callback) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/emails/contact-us`, data);
      if (response && response.status == 200) {
        dispatch(displaySuccess(successMessage));
        callback();
        setTimeout(() => {
          dispatch(clearSuccess());
        }, 12000);
      } else {
        dispatch(displayError(errorMessage));
        setTimeout(() => {
          dispatch(clearError());
        }, 6000);
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};