import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import ResetPasswordForm from '../../components/Forms/ResetPasswordForm';
import { resetPassword } from '../../actions/userActions';
import Alerts from '../../components/Alerts';
import { validatePassword } from '../../utils/helper';
import '../../assets/css/register.css';
import logo from '../../assets/img/logo-form.png';

const theme = createMuiTheme();
const styles = {
  paper: {
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: 3,
    padding: 2,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        My Sponsor
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
class ResetPassword extends Component {
  state = {
    email: '',
    password: '',
    passwordToken: '',
    errors: {},
  };

  componentDidMount() {
    const { history } = this.props;
    const userObj = JSON.parse(localStorage.getItem('forgotPassword'));
    if (userObj && userObj.isForgotStepCompleted) {
      this.setState({ email: userObj.email });
    } else {
      history.push('/forgot-password');
    }
  }

  componentDidUpdate(prevProps) {
    const { redirect, history } = this.props;
    if (prevProps.redirect !== redirect) {
      localStorage.removeItem('forgotPassword');
      history.push(redirect);
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('forgotPassword');
  }

  handleReset = (e) => {
    const { password, email, passwordToken } = this.state;
    const { resetPassword: userResetPassword } = this.props;
    e.preventDefault();
    if (this.validate() && email) {
      const userData = {
        token: passwordToken,
        email,
        newPassword: password,
      };
      userResetPassword(userData);
    }
  };

  handleChange = (e, prop) => {
    const { errors } = this.state;
    errors[prop] = '';
    this.setState({ [prop]: e.target.value, errors });
  };

  validate = () => {
    let isValid = true;
    const { password, confirmPassword, passwordToken, errors } = this.state;
    if (!password || !validatePassword(password)) {
      isValid = false;
      errors.password = 'Dein Passwort muss mindestens 8 Zeichen, sowie ein Symbol, ein Gross- & Kleinbuchstabe beinhalten.';
    }
    if (password !== confirmPassword) {
      isValid = false;
      errors.confirmPassword = 'Passwörter stimmen nicht überein.';
    }
    if (!passwordToken) {
      isValid = false;
      errors.email = 'Bitte den Passwort-Token eingeben.';
    }
    this.setState({ errors });
    return isValid;
  };

  render() {
    const { errors } = this.state;
    const { isFailed, errorMsg, classes, msg } = this.props;
    return (
      <Fragment>
        <div className="main-regContainer">
          <Container className="form-container" component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <div className={classes.logoContainer}>
                <img src={logo} alt="login-icon" />
              </div>
              {isFailed ? <Alerts msg={errorMsg} type="error" /> : null}
              {msg ? <Alerts msg={msg} type="success" /> : null}
              <ResetPasswordForm
                handleReset={this.handleReset}
                handleChange={this.handleChange}
                errors={errors}
              />
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  redirect: state.formResponse.redirect,
  msg: state.formResponse.msg,
  isFailed: state.formResponse.isFailed,
  errorMsg: state.formResponse.msg,
});

const mapDispatchToProps = {
  resetPassword,
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  msg: PropTypes.string,
  redirect: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.shape({
          pathname: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  isFailed: PropTypes.bool,
  errorMsg: PropTypes.string,
  classes: PropTypes.shape({
    paper: PropTypes.string,
    avatar: PropTypes.string,
    logoContainer: PropTypes.string,
  }),
};

ResetPassword.defaultProps = {
  isFailed: false,
  redirect: '',
  errorMsg: '',
  msg: '',
  classes: {
    paper: '',
    avatar: '',
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPassword));
