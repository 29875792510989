import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { months } from '../../utils/helper';

class YearChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadyToRender: false,
      series: [
        {
          name: 'Donation',
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 6,
            columnWidth: '45%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: months,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    const { series, options } = this.state;
    const { graphData, type } = this.props;
    const values = [];

    if (type === 'year') {
      months.forEach((month) => {
        const monthValue = graphData.find((don) => don.date === month);
        if (monthValue && monthValue.donations) {
          values.push(monthValue.donations);
        } else {
          values.push(0);
        }
      });
    } else {
      const labels = [];
      graphData.forEach((don) => {
        values.push(don.donations);
        labels.push(don.date);
      });
      options.xaxis.categories = labels;
    }

    series[0].data = values;
    this.setState({
      options,
      series,
      isReadyToRender: true,
    });
  }

  render() {
    const { options, series, isReadyToRender } = this.state;
    return isReadyToRender ? (
      <Chart options={options} series={series} type="bar" height={350} />
    ) : null;
  }
}

YearChart.propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string.isRequired,
};

YearChart.defaultProps = {
  graphData: [],
};

export default YearChart;
