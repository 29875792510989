import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from 'react-i18next';

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  const positiveRequest = props.positiveRequest ? props.positiveRequest : false;
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent id="confirm-dialog-content">{children}</DialogContent>
      <DialogActions id="confirm-dialog-buttons">
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color= "default"
          className= {`${!positiveRequest ? "no-button" : "yes-button"}`} 
        >
          {t('no')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="default"
          className= {`${positiveRequest ? "no-button" : "yes-button"}`}
        >
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
