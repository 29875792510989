/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { countries } from '../../utils/countryList';

export default function CountrySelect(props) {
  const { handleChange, selectedCountry, elementName, label } = props;
  return (
    <Autocomplete
      name={elementName}
      id="country-select-box"
      onChange={handleChange}
      getOptionLabel={(option) => option.name}
      options={countries}
      style={{ width: '100%', marginTop: 16, marginBottom: 16 }}
      value={{ name: selectedCountry }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}

CountrySelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string,
  label: PropTypes.string.isRequired,
};

CountrySelect.defaultProps = {
  selectedCountry: '',
};
