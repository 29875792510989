import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm';
import { forgotPassword } from '../../actions/userActions';
import Alerts from '../../components/Alerts';
import '../../assets/css/register.css';
import logo from '../../assets/img/logo-form.png';

const theme = createMuiTheme();
const styles = {
  paper: {
    marginTop: theme.spacing(8),
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    margin: 3,
    padding: 2,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        My Sponsor
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
class ForgotPassword extends Component {
  state = {
    email: '',
    errors: {},
  };

  componentDidUpdate(prevProps) {
    const { redirect, history } = this.props;
    const { email } = this.state;
    if (prevProps.redirect !== redirect) {
      const userObj = {
        email,
        isForgotStepCompleted: true,
      };
      localStorage.setItem('forgotPassword', JSON.stringify(userObj));
      history.push(redirect);
    }
  }

  handleContinue = (e) => {
    const { email } = this.state;
    const { forgotPassword: userForgotPassword } = this.props;
    e.preventDefault();
    if (this.validate()) {
      const userData = {
        email,
      };
      userForgotPassword(userData);
    }
  };

  handleChange = (e, prop) => {
    const { errors } = this.state;
    errors[prop] = '';
    this.setState({ [prop]: e.target.value, errors });
  };

  validate = () => {
    let isValid = true;
    const { email, errors } = this.state;
    if (!email) {
      isValid = false;
      errors.email = 'Bitte E-mailadresse eingeben.';
    }
    this.setState({ errors });
    return isValid;
  };

  render() {
    const { errors } = this.state;
    const { isFailed, errorMsg, classes, msg } = this.props;
    return (
      <Fragment>
        <div className="main-regContainer">
          <Container className="form-container" component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <div className={classes.logoContainer}>
                <img src={logo} alt="login-icon" />
              </div>
              {isFailed ? <Alerts msg={errorMsg} type="error" /> : null}
              {msg ? <Alerts msg={msg} type="success" /> : null}
              <ForgotPasswordForm
                handleContinue={this.handleContinue}
                handleChange={this.handleChange}
                errors={errors}
              />
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  redirect: state.formResponse.redirect,
  msg: state.formResponse.msg,
  isFailed: state.formResponse.isFailed,
  errorMsg: state.formResponse.msg,
});

const mapDispatchToProps = {
  forgotPassword,
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  msg: PropTypes.string,
  redirect: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.shape({
          pathname: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  isFailed: PropTypes.bool,
  errorMsg: PropTypes.string,
  classes: PropTypes.shape({
    paper: PropTypes.string,
    avatar: PropTypes.string,
    logoContainer: PropTypes.string,
  }),
};

ForgotPassword.defaultProps = {
  isFailed: false,
  msg: '',
  redirect: '',
  errorMsg: '',
  classes: {
    paper: '',
    avatar: '',
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPassword));
