import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TodayIcon from '@material-ui/icons/Today';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import eventSample from '../../../assets/img/product-holder.png';
import EditEventModal from '../../Modals/EditEventModal';
import { editClubEvents, getClubEvents } from '../../../actions/eventActions';
import {
  uploadProductAndEventImages,
  updateProductAndEventImages,
  descriptionTranslate,
} from '../../../actions/productActions';
import {
  imageBaseUrl,
  displayDescription,
  getTargetLanguagesAndDescriptions,
} from '../../../utils/helper';

const translatedDescription = {
  EN: '',
  DE: '',
  FR: '',
  IT: '',
};

const Events = (props) => {
  const { t, i18n } = useTranslation();
  const [openEdit, setEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventErrors, setEventErrors] = useState({});
  const [uploadedImg, setUploadedImg] = useState('');
  const [eventImage, setEventImage] = useState([]);
  const [translatedText, setTranslatedText] = useState(translatedDescription);
  const [isTranslationCompleted, setTranslationComplete] = useState(false);
  const [tickets, setTickets] = useState([{ tier: 'Silver', amount: '200' }]);
  const dispatch = useDispatch();
  const { handleOpen, data } = props;

  const handleEdit = (event) => {
    setEditOpen(true);
    setSelectedEvent(event);
    if (event.ticketSends && event.ticketSends.length > 0) {
      const savedTickets = [...event.ticketSends];
      setTickets(savedTickets);
    }
    if (event.image) {
      setUploadedImg(event.image);
    }
    setTranslatedText(translatedDescription);
    setTranslationComplete(false);
  };

  const handleTicketAddClick = () => {
    const newTicket = [...tickets, { tier: '', amount: '' }];
    setTickets(newTicket);
  };

  const handleTicketRemoveClick = (index) => {
    const currentTickets = [...tickets];
    currentTickets.splice(index, 1);
    setTickets(currentTickets);
  };

  const handleTicketsFieldChange = (e, i) => {
    const newTickets = [...tickets];
    newTickets[i][e.target.name] = e.target.value;
    setTickets(newTickets);
    setSelectedEvent({
      ...selectedEvent,
      ticketSends: newTickets,
    });
  };

  const handleClubEventFieldChange = (e) => {
    setSelectedEvent({
      ...selectedEvent,
      [e.target.name]: e.target.value,
    });
  };

  const selectedLanguage = localStorage.getItem('language') || 'du';
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const isValidEvent = () => {
    let isValid = true;
    const errors = {};
    if (!selectedEvent.title) {
      isValid = false;
      errors.title = 'Bitte schreibe einen Titel';
    }
    if (!selectedEvent.description) {
      isValid = false;
      errors.description = 'Bitte schreibe eine Beschreibung';
    }
    if (!selectedEvent.fromDate) {
      isValid = false;
      errors.fromDate = 'Bitte Startdatum hinzufügen';
    }
    if (!selectedEvent.toDate) {
      isValid = false;
      errors.toDate = 'Bitte Enddatum hinzufügen';
    }
    setEventErrors(errors);
    return isValid;
  };

  const handleClose = () => {
    setSelectedEvent({});
    setEventErrors({});
    setUploadedImg('');
    setEventImage([]);
    setTickets([{ tier: '', amount: '' }]);
    setEditOpen(false);
    setIsLoading(false);
  };

  const handleEventSave = () => {
    if (isValidEvent()) {
      const eventTickets = selectedEvent.ticketSends ? selectedEvent.ticketSends : [];
      const eventRequestPayload = {
        ...selectedEvent,
        tickets: eventTickets,
      };
      delete eventRequestPayload.ticketSends;
      setIsLoading(true);
      eventRequestPayload.fromDate = moment(eventRequestPayload.fromDate).format('yyyy-MM-DD HH:mm:ss')
      eventRequestPayload.toDate = moment(eventRequestPayload.toDate).format('yyyy-MM-DD HH:mm:ss')
      dispatch(editClubEvents(eventRequestPayload));

      if (eventImage && eventImage.length > 0) {
        const fileData = new FormData();
        fileData.append('file', eventImage[0]);
        fileData.append('entity', 'event');
        fileData.append('column', 'image');
        fileData.append('id', selectedEvent.id);
        if (selectedEvent.image) {
          fileData.append('fileName', uploadedImg);
          dispatch(
            updateProductAndEventImages(fileData, () => {
              dispatch(getClubEvents({ clubId: selectedEvent.clubId }));
              handleClose();
            })
          );
        } else {
          dispatch(
            uploadProductAndEventImages(fileData, () => {
              dispatch(getClubEvents({ clubId: selectedEvent.clubId }));
              handleClose();
            })
          );
        }
      } else {
        handleClose();
      }
    }
  };

  const addEventImage = (file) => {
    setEventImage([file]);
  };

  const handleDescriptionChange = async (e) => {
    const descriptionText = e.target.value;
    setTranslatedText(translatedDescription);
    const targetLanguagesAndDescriptions = getTargetLanguagesAndDescriptions(
      translatedText,
      descriptionText,
      selectedLanguage
    );
    const targetTranslationList = targetLanguagesAndDescriptions.targetLanguagesList || [];
    const translatedTextList = targetLanguagesAndDescriptions.descriptionList;

    // eslint-disable-next-line no-restricted-syntax
    for (const targetLang of targetTranslationList) {
      // eslint-disable-next-line no-await-in-loop
      const response = await descriptionTranslate(descriptionText, targetLang);
      if (response) {
        translatedTextList[targetLang] = response;
      }
    }

    setSelectedEvent({
      ...selectedEvent,
      description: translatedTextList.EN,
      descriptionDe: translatedTextList.DE,
      descriptionFr: translatedTextList.FR,
      descriptionIt: translatedTextList.IT,
    });
    setTranslationComplete(true);
    setTranslatedText(translatedTextList);
  };

  const displayEvents = (events) => {
    if (events && events.length > 0) {
      return (
        <div>
          <Grid container spacing={3}>
            {events.map((event) => (
              <Grid item xs={6} sm={4}>
                <Paper>
                  <div
                    className="vertical-item content-padding text-center with-background rounded bottom-color-border item-size club-profile-events">
                    <div className="item-media event-img-div top-rounded overflow-hidden text-center">
                      {event.image ? (
                        <img className="event-img" src={`${imageBaseUrl}/${event.image}`} alt="" />
                      ) : (
                        <img className="event-img" src={eventSample} alt="" />
                      )}
                      <div className="media-links no-overlay" />
                    </div>
                    <div className="item-content with-border bottom-rounded text-center">
                      <h5 className="entry-title">{event.title}</h5>
                      <Tooltip
                        title={displayDescription(event, selectedLanguage)}
                        placement="top-start"
                      >
                        <p className="event-desc">{displayDescription(event, selectedLanguage)}</p>
                      </Tooltip>
                      <div className="event-details text-center">
                        <p className="event-desc">
                          <Tooltip title={event.location} arrow>
                          <span>
                            {event.location}
                          </span>
                          </Tooltip>
                        </p>
                        <span className="amount">
                          {event.fromDate ? moment(event.fromDate).format('DD.MM.') : ''}
                          {' - '}
                          {event.toDate ? moment(event.toDate).format('DD.MM.YYYY') : ''}
                        </span>
                        <span>
                          <Button
                            onClick={() => handleEdit(event)}
                            className="btn-global"
                            variant="outlined"
                          >
                            {t('edit')}
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      );
    }
    return null;
  };
  return (
    <div className="tab-pane in active" id="tab1">
      <div className="product-create-btn flex-row justify-content-between">
        <h4>{t('events')}</h4>
        <Button onClick={handleOpen} className="btn-global" variant="outlined">
          {t('create_event')}
        </Button>
      </div>
      {displayEvents(data)}
      <EditEventModal
        open={openEdit}
        handleClose={handleClose}
        event={selectedEvent}
        tickets={tickets}
        handleTicketAddClick={handleTicketAddClick}
        handleTicketRemoveClick={handleTicketRemoveClick}
        handleClubEventFieldChange={handleClubEventFieldChange}
        handleTicketsFieldChange={handleTicketsFieldChange}
        errors={eventErrors}
        handleSave={handleEventSave}
        alreadyUploadedImage={uploadedImg}
        setEventImage={addEventImage}
        removeEventImage={() => setEventImage([])}
        isTranslationCompleted={isTranslationCompleted}
        handleDescriptionChange={handleDescriptionChange}
        isLoading={isLoading}
      />
    </div>
  );
};

Events.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

Events.defaultProps = {
  data: [],
};

export default Events;
