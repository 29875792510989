import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';
// import EventTickets from '../Forms/EventTicketsForm';
import UploadImage from '../Upload/ImageUpload';
import { findDescriptionField } from '../../utils/helper';

// const eventTiers = [
//   { id: 1, name: 'Silver' },
//   { id: 2, name: 'Gold' },
//   { id: 3, name: 'Platinum' },
//   { id: 4, name: 'Free' },
// ];

export default function EditEventModal(props) {
  const { t, i18n } = useTranslation();
  const {
    open,
    handleClose,
    // tickets,
    // handleTicketAddClick,
    // handleTicketRemoveClick,
    // handleTicketsFieldChange,
    handleClubEventFieldChange,
    event,
    errors,
    handleSave,
    setEventImage,
    removeEventImage,
    alreadyUploadedImage,
    handleDescriptionChange,
    isLoading,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const SelectedLanguageValue = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  const displayDescriptionField = () => {
    const fieldName = findDescriptionField(SelectedLanguageValue);
    return (
      <TextField
        id="standard-description-input"
        name={fieldName}
        label={t('event_description')}
        required
        fullWidth
        multiline
        rows={4}
        inputProps={{
          maxLength: 240,
        }}
        value={event[fieldName]}
        onChange={handleClubEventFieldChange}
        error={errors[fieldName]}
        helperText={errors[fieldName]}
        onBlur={handleDescriptionChange}
      />
    );
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        className="width-xl-60-modal create-event-modal"
      >
        <DialogTitle className="modal-title-center" id="responsive-dialog-title">
          {t('edit_event')}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <>
              <form noValidate autoComplete="off">
                <Grid container spacing={2} xs={12} sm={7}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="standard-title-input"
                      name="title"
                      label={t('event_title')}
                      required
                      fullWidth
                      value={event.title}
                      error={errors.title}
                      helperText={errors.title}
                      onChange={handleClubEventFieldChange}
                      inputProps={{
                        maxLength: 25,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="standard-location-input"
                      name="location"
                      label="Location"
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={event.location || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="standard-startDate-input"
                      name="fromDate"
                      type="datetime-local"
                      label={t('event_start_date')}
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={
                        event.fromDate ? moment(event.fromDate).format('YYYY-MM-DDTHH:mm') : ''
                      }
                      onChange={handleClubEventFieldChange}
                      error={errors.fromDate}
                      helperText={errors.fromDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="standard-endDate-input"
                      name="toDate"
                      type="datetime-local"
                      label={t('event_end_date')}
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={event.toDate ? moment(event.toDate).format('YYYY-MM-DDTHH:mm') : ''}
                      onChange={handleClubEventFieldChange}
                      error={errors.toDate}
                      helperText={errors.toDate}
                    />
                  </Grid>
                  </Grid>
                  <Grid container spacing={3} sm={12} className="description-event-modal">
                  <Grid item xs={12} sm={12}>
                    {displayDescriptionField()}
                  </Grid>
                </Grid>
              </form>
              {/* {errors.tickets ? (
                <FormHelperText style={{ color: 'red' }}>{errors.tickets}</FormHelperText>
              ) : null}
              <EventTickets
                handleTicketAddClick={handleTicketAddClick}
                handleTicketRemoveClick={handleTicketRemoveClick}
                handleTicketsFieldChange={handleTicketsFieldChange}
                tickets={tickets}
                eventTiers={eventTiers}
              /> */}
            </>
          )}
          <Grid style={isLoading ? { display: 'none' } : {}} container spacing={3} className="upload-image-container">
            <Grid item xs={12} sm={6}>
              <FormHelperText style={{ color: 'red' }}>{errors.eventImage}</FormHelperText>
              <UploadImage
                setImage={setEventImage}
                removeImage={removeEventImage}
                alreadyUploadedImage={alreadyUploadedImage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="action-buttons-modal">
          {!isLoading ? (
            <>
              <Button className="cancel-button" onClick={handleClose} variant="outlined">
                {t('cancel')}
              </Button>
              <Button
                disabled={isLoading}
                className="btn-global-filled"
                onClick={handleSave}
                variant="outlined"
              >
                {t('save')}
              </Button>
            </>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditEventModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // tickets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // handleTicketAddClick: PropTypes.func.isRequired,
  // handleTicketRemoveClick: PropTypes.func.isRequired,
  // handleTicketsFieldChange: PropTypes.func.isRequired,
  handleClubEventFieldChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  setEventImage: PropTypes.func.isRequired,
  removeEventImage: PropTypes.func.isRequired,
  event: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    location: PropTypes.string,
  }),
  errors: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    tickets: PropTypes.string,
    eventImage: PropTypes.string,
  }),
  alreadyUploadedImage: PropTypes.string,
  handleDescriptionChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

EditEventModal.defaultProps = {
  event: {},
  errors: {},
  alreadyUploadedImage: '',
  isLoading: false,
};
