import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
}));

const RegistrationConfirmation = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <main className={classes.layout}>
        <Paper className={classes.paper} id="confirmation-reg-box">
          <h4 className="MuiTypography-root MuiTypography-h4 MuiTypography-alignCenter">
            {t('registration_confirmation_title')}
          </h4>
          <p style={{ textAlign: 'center', marginTop: 20 }}>
	    {t('registration_confirmation')}
          </p>
          <a className="forward-btn" style={{ color: '#fff' }} href="/profile/club">
            {t('go_profile_button')}
          </a>
        </Paper>
      </main>
      <Footer />
    </Fragment>
  );
};

export default RegistrationConfirmation;
