import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import ProfileUpload from '../Upload/ProfileUpload';
import CountrySelect from '../Select/CountrySelect';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function EditUserProfile(props) {
  const {
    handleChange,
    handleAddressChange,
    errors,
    usedata,
    savedProfileImage,
    setProfileImage,
    removeProfileImage,
    handleCountryChange,
  } = props;
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <ProfileUpload
                savedImages={savedProfileImage}
                setImage={setProfileImage}
                removeImage={removeProfileImage}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-firstName-input"
                label={t('first_name')}
                autoComplete="fname"
                name="firstName"
                value={usedata.firstName}
                required
                fullWidth
                autoFocus
                onChange={handleChange}
                error={errors.firstName}
                helperText={errors.firstName}
                inputProps={{
                  maxLength: 25,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-lastName-input"
                label={t('last_name')}
                autoComplete="lname"
                name="lastName"
                value={usedata.lastName}
                required
                fullWidth
                onChange={handleChange}
                error={errors.lastName}
                helperText={errors.lastName}
                inputProps={{
                  maxLength: 25,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-email-input"
                label={t('email_address')}
                name="email"
                value={usedata.email}
                disabled
                required
                fullWidth
                onChange={handleChange}
                error={errors.email}
                helperText={errors.email}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-address1-input"
                label={t('address_line1')}
                value={usedata.address ? usedata.address.address1 : ''}
                name="address1"
                fullWidth
                onChange={handleAddressChange}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-address2-input"
                label={t('address_line2')}
                name="address2"
                value={usedata.address ? usedata.address.address2 : ''}
                fullWidth
                onChange={handleAddressChange}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-postcode-input"
                label={t('post_code')}
                name="postalCode"
                type="number"
                value={usedata.address ? usedata.address.postalCode : ''}
                fullWidth
                onChange={handleAddressChange}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-city-input"
                label={t('city')}
                name="city"
                value={usedata.address ? usedata.address.city : ''}
                fullWidth
                onChange={handleAddressChange}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CountrySelect
                name="country"
                label={t('country')}
                handleChange={(e, option) => handleCountryChange(option, 'country')}
                selectedCountry={usedata.address ? usedata.address.country : ''}
                required
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

EditUserProfile.propTypes = {
  handleChange: PropTypes.func.isRequired,
  savedProfileImage: PropTypes.func.isRequired,
  setProfileImage: PropTypes.func.isRequired,
  removeProfileImage: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  usedata: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};

EditUserProfile.defaultProps = {
  errors: {},
  usedata: {},
};

export default EditUserProfile;
