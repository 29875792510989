import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import logo from '../../assets/img/logo.png';
import { userLogOut } from '../../actions/userActions';

const Appbar = (props) => {
  const { userLoggedIn, authUserData } = props;
  const [clubId, setClubId] = useState(null);
  const [language, setLanguage] = useState('du');
  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    localStorage.setItem('language', e.target.value);
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const selectedLanguage = localStorage.getItem('language') || 'du';
  useEffect(() => {
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleSingOut = () => {
    const { userLogOut: logout, history } = props;
    logout();
    history.push('/login');
  };

  useEffect(() => {
    if (authUserData && authUserData.clubId) {
      setClubId(authUserData.clubId);
    }
  }, [authUserData]);

  const goToLogin = (e) => {
    const { history, location } = props;
    e.preventDefault();
    history.push({
      pathname: '/login',
      state: { from: location },
    });
  };

  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    // toggle isActive state on click
    setIsActive(current => !current);
  };

  const displayOption = (isValid, userData) => {
    if (isValid && userData) {
      return (
        <>
          <div className="dropdown">
            <span className="dropbtn">
              <AccountCircleIcon /> {userData.firstName ? userData.firstName : null}
            </span>
            <div className="dropdown-content">
              <a href="/profile/user">{t('header_profile')}</a>
              {clubId ? (
                <a href="/profile/club">{t('header_toclub')}</a>
              ) : (
                <a href="/register/club">{t('header_createclub')}</a>
              )}
              <span onClick={handleSingOut} aria-hidden="true">
                {t('header_signout')}
              </span>
            </div>
          </div>
        </>
      );
    }
    return (
      <span onClick={goToLogin} role="button" aria-hidden="true">
        <AccountCircleIcon /> {t('sign_in')}
      </span>
    );
  };

  return (
    <header className="header-section" id="header-section">
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 d-flex justify-content-start reunir-content-center">
              <div className="header-left">
                <ul>
                  <li className="header-left-list">
                    <p className="header-left-text">
                      <a href="/contact">
                        <span className="header-left-icon">
                          <i className="icofont-headphone-alt" />
                        </span>
                        {t('header_Support')}
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-flex justify-content-end reunir-content-center">
              <div className="header-right">
                <ul>
                  <li className="header-right-list">
                    <div className="dropdown show header-dropdown">
                      <span className="header-left-icon">
                        <i className="icofont-web" />
                      </span>
                      <select
                        value={language}
                        onChange={handleChange}
                        className="selectpicker"
                        name="languages"
                        tabIndex="-98"
                      >
                        <option value="en">English</option>
                        <option value="du">Deutsch</option>
                        <option value="fr">Française</option>
                        <option value="it">Italiana</option>
                      </select>
                    </div>
                  </li>
                  {/* <li className="header-right-list">
                    <p className="header-right-text">
                      <span className="header-right-icon carticon">
                        <i className="icofont-sign-in" />
                      </span>
                      {displayOption(userLoggedIn, authUserData)}
                    </p>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- nav top begin --> */}
      <nav id="navbar" className="navbar navbar-expand-lg navbar-light reunir-navbar z-50">
        <div className="container">
          <div className="logo-section">
            <a className="logo-title navbar-brand" href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
	    onClick={handleClick}
          >
            <i className="icofont-navigation-menu" />
          </button>
          <div className={isActive ? 'collapse navbar-collapse nav-main justify-content-end show' : 'collapse navbar-collapse nav-main justify-content-end'} id="navbarNav">
            <ul className="navbar-nav" id="primary-menu">
              <li className="nav-item active">
                <a className="nav-link active" href="/">
                  {t('header_home')}
                  <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/clubs">
                  {t('header_clubs')}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/wie-funktionierts">
                  {t('header_contact')}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/ueber-uns">
                  {t('header_about')}
                </a>
              </li>
              <li className="nav-item login-nav z-50">
                <a className="nav-link" href="#">
                  {displayOption(userLoggedIn, authUserData)}
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="right-side-box">
            <a className="join-btn" href="/register/user">
              JOIN US
            </a>
          </div> */}
        </div>
      </nav>
      {/* <!-- nav top end --> */}
    </header>
  );
};

Appbar.propTypes = {
  userLoggedIn: PropTypes.string,
  userLogOut: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  authUserData: PropTypes.shape({
    firstName: PropTypes.string,
    clubId: PropTypes.number,
  }),
  location: PropTypes.shape({}),
};

Appbar.defaultProps = {
  userLoggedIn: null,
  userLogOut: null,
  authUserData: {},
  location: {},
};

const mapStateToProps = (state) => ({
  userLoggedIn: state.user.userLoggedIn,
  authUserData: state.user.authUserData,
});

const mapDispatchToProps = {
  userLogOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Appbar));
