import axios from 'axios';
import {
  FETCH_ALL_CLUBS,
  FETCH_CLUB_DETAILS,
  REGISTER_CLUB,
  SAVE_CLUB_CONTACT_PERSON,
  SAVE_BANK_INFORMATION,
  BACK_PREVIOUS_STEP,
  FETCH_CLUBS_NEAR_BY,
  FETCH_ALL_TYPES,
  FETCH_GRAPH_DATA,
  FETCH_CLUB_REGISTRATION_DETAILS,
  CLEAR_CLUB_REGISTRATION_DETAILS,
  UPDATE_CLUB,
  UPDATE_CONTACT_PERSON,
  UPDATE_CLUB_REGISTER_FLOW,
  SET_USER_CLUB_ID,
  FETCH_PAYMENT_SUCCESS,
  FETCH_CLUB_PENDING_PAYOUT,
  FETCH_CLUB_PAYOUTS,
} from '../utils/types';
import { headers, baseUrl, authHeader } from '../utils/helper';
import { displayError, clearError, displaySuccess, clearSuccess } from './formResponseAction';
import displaySpinner from './spinnerAction';
import { getClubEvents } from './eventActions';
import { getClubProducts, uploadFiles, updateFiles } from './productActions';
import { refreshToken, updateUserToken } from './userActions';

export const goPreviousStep = () => {
  return {
    type: BACK_PREVIOUS_STEP,
  };
};

export const getAllClubs = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/clubs/all`, data, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_CLUBS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getClubDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/clubs/details`, data, headers);
      if (response && response.data) {
        dispatch(getClubEvents({ clubId: response.data.id }));
        dispatch(getClubProducts({ clubId: response.data.id }));
        dispatch({
          type: FETCH_CLUB_DETAILS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const registerClub = (data, cb) => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState();
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        const response = await axios.post(`${baseUrl}/clubs`, data, authHeader());
        if (response && response.data) {
          dispatch(
            updateUserToken({
              refreshToken: user.authUserData.refreshToken,
            })
          );
          dispatch(displaySpinner(false));
          cb(response.data.id);
          dispatch({
            type: REGISTER_CLUB,
            payload: response.data,
          });
          if (data.adminEmails && data.adminEmails.includes(user.authUserData.email)) {
            dispatch({
              type: SET_USER_CLUB_ID,
              payload: response.data.id,
            });
          }
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const addContactPerson = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        const response = await axios.post(`${baseUrl}/contacts`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySpinner(false));
          dispatch({
            type: SAVE_CLUB_CONTACT_PERSON,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const addBankDetails = (data, onSuccess) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        const response = await axios.post(`${baseUrl}/banks`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySpinner(false));
          dispatch({
            type: SAVE_BANK_INFORMATION,
            payload: response.data,
          });
          onSuccess();
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const getClubsNearBy = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/clubs/nearbyAll`, data, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_CLUBS_NEAR_BY,
          payload: response.data.content,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

// public end point
export const getAllTypes = () => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/types/all`, {}, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_TYPES,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getGraphData = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        const response = await axios.post(`${baseUrl}/donations/performance`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_GRAPH_DATA,
            payload: response.data,
          });
          dispatch(displaySpinner(false));
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      console.error('there is an error', error);
    }
  };
};

export const getPendingPayoutForClub = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        const response = await axios.post(`${baseUrl}/donations/pending-payout-club`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_CLUB_PENDING_PAYOUT,
            payload: response.data,
          });
          dispatch(displaySpinner(false));
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      console.error('there is an error', error);
    }
  };
};

export const clearClubRegisterDetails = () => {
  return {
    type: CLEAR_CLUB_REGISTRATION_DETAILS,
  };
};

export const getClubRegistrationDetails = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        dispatch(clearClubRegisterDetails());
        const response = await axios.post(
          `${baseUrl}/clubs/registration-details`,
          data,
          authHeader()
        );
        if (response && response.data) {
          dispatch({
            type: FETCH_CLUB_REGISTRATION_DETAILS,
            payload: response.data,
          });
          dispatch(displaySpinner(false));
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      console.error('there is an error', error);
    }
  };
};

export const updateClub = (data, cb) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        const response = await axios.put(`${baseUrl}/clubs`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySpinner(false));
          cb(response.data.id);
          dispatch({
            type: UPDATE_CLUB,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const updateContactPerson = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        const response = await axios.put(`${baseUrl}/contacts`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySpinner(false));
          dispatch({
            type: UPDATE_CONTACT_PERSON,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

const saveClubDocuments = (documents, clubId) => {
  return async (dispatch) => {
    if (documents && documents.length > 0) {
      documents.forEach((document) => {
        if (document.file) {
          const fileData = new FormData();
          fileData.append('file', document.file);
          fileData.append('entity', 'document');
          fileData.append('column', document.type);
          fileData.append('id', clubId);
          if (document.fileName) {
            fileData.append('fileName', document.fileName);
            dispatch(updateFiles(fileData, () => { }));
          } else {
            dispatch(uploadFiles(fileData, () => { }));
          }
        }
      });
    }
  };
};

export const updateBankDetails = (data, documents) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        dispatch(displaySpinner(true));
        const response = await axios.put(`${baseUrl}/banks`, data, authHeader());
        if (response && response.data) {
          const { clubId } = response.data;
          dispatch(displaySpinner(false));
          dispatch(displaySuccess('Bank Details erfolgreich aktualisiert.'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 1000);
          dispatch(saveClubDocuments(documents, clubId));
        }
      }
    } catch (error) {
      dispatch(displaySpinner(false));
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 4000);
      }
      console.error('there is an error', error);
    }
  };
};

export const validateClubRegistrationDetails = (registrationData) => {
  return async (dispatch) => {
    if (registrationData) {
      const clubId = registrationData.club ? registrationData.club.id : null;
      const contactId = registrationData.contact ? registrationData.contact.id : null;
      let completedStep = 0;
      if (clubId && contactId) {
        completedStep = 2;
      } else if (clubId) {
        completedStep = 1;
      }
      dispatch({
        type: UPDATE_CLUB_REGISTER_FLOW,
        payload: { completedStep, clubId, contactId },
      });
    }
  };
};

// for club filters
export const filterClubs = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/clubs/filter`, data, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_CLUBS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

// for club filters
export const searchClubByName = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/clubs/searchbar`, data, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_CLUBS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const searchClubsByRadius = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/clubs/nearby`, data, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_CLUBS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const twintPayment = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/payments/pay-twint`, data, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_PAYMENT_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getClubPayoutHistory = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/payouts/payout-details-club`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_CLUB_PAYOUTS,
            payload: response.data
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};
