import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import mysherz from '../../../assets/img/mys-herz-200.png';

export default function Advantages() {
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  return (
    <section className="advantage-section">
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="advantage-text">
                <h5 className="advantage-title">{t('teamwork_part_subtitle')}</h5>
                <h2 className="advantage-subtitle">{t('teamwork_part_title')}</h2>
              </div>
            </div>
          </div>
          <div className="row heart-row">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <img src={mysherz} alt="herz" />
                <div className="text-box">
                  <h2>{t('teamwork_part_firsticon')}</h2>
                </div>
                <div className="text-box">
                  <h2>{t('teamwork_part_secondicon')}</h2>
                </div>
                <div className="text-box">
                  <h2>{t('teamwork_part_thirdicon')}</h2>
                </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
