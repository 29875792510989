/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { docTypes } from '../../utils/helper';

const DocUpload = (props) => {
  const { t, i18n } = useTranslation();
  const [registerDoc, setRegisterDoc] = useState([]);
  const [licenseDoc, setLicenseDoc] = useState([]);
  const { uploadDocument, removeDocument, uploadedDocuments } = props;

  const selectedLanguage = localStorage.getItem('language') || 'en';
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    if (uploadedDocuments && uploadedDocuments.length) {
      uploadedDocuments.forEach((uploadedDocument) => {
        Object.entries(uploadedDocument).forEach(([key, value]) => {
          if (uploadedDocument[key] && key === docTypes.clubRegistration) {
            const doc = [];
            const uploadFileName = value ? value.split('/')[2] : '';
            doc.push({ name: uploadFileName, fileName: value });
            setRegisterDoc(doc);
          } else if (uploadedDocument[key] && key === docTypes.clubLicenseCopy) {
            const doc = [];
            const uploadFileName = value ? value.split('/')[2] : '';
            doc.push({ name: uploadFileName, fileName: value });
            setLicenseDoc(doc);
          }
        });
      });
    }
  }, [uploadedDocuments]);

  const handleRegisterDocUpload = (e) => {
    setRegisterDoc(e.target.files);
    uploadDocument(e.target.files[0], docTypes.clubRegistration);
  };

  const handleRegisterDocDelete = (file) => {
    setRegisterDoc([]);
    removeDocument(file, docTypes.clubRegistration);
  };

  const handleLicenseDocUpload = (e) => {
    setLicenseDoc(e.target.files);
    uploadDocument(e.target.files[0], docTypes.clubLicenseCopy);
  };

  const handleLicenseDocDelete = (file) => {
    setLicenseDoc([]);
    removeDocument(file, docTypes.clubLicenseCopy);
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={6}>
        <div className="file-upload file-upload-drag" style={{ minHeight: '115px' }}>
          <span className="doc-upload-wrapper">
            <input
              id="docUpload"
              disabled={registerDoc.length}
              style={{ display: 'none' }}
              type="file"
              accept="image/jpeg, application/pdf"
              onChange={handleRegisterDocUpload}
            />
            <div className="file-upload-container">
              <p>
                <label className="doc-upload-label" htmlFor="docUpload">
                  <CloudUploadOutlinedIcon className="cloud-upload-icon" />
                  {t('register_doc')}
                </label>
              </p>
            </div>
          </span>
        </div>
        {registerDoc.length > 0 ? (
          <div className="uploaded-doc-list">
            <p>{registerDoc[0].name}</p>
            <DeleteIcon
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => handleRegisterDocDelete(registerDoc[0])}
            />
          </div>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className="file-upload file-upload-drag" style={{ minHeight: '115px' }}>
          <span className="doc-upload-wrapper">
            <input
              id="docUpload2"
              disabled={licenseDoc.length}
              style={{ display: 'none' }}
              type="file"
              accept="image/jpeg, application/pdf"
              onChange={handleLicenseDocUpload}
            />
            <div className="file-upload-container">
              <p>
                <label className="doc-upload-label" htmlFor="docUpload2">
                  <CloudUploadOutlinedIcon className="cloud-upload-icon" />
                  {t('license_doc')}
                </label>
              </p>
            </div>
          </span>
        </div>
        {licenseDoc.length > 0 ? (
          <div className="uploaded-doc-list">
            <p>{licenseDoc[0].name}</p>
            <DeleteIcon
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => handleLicenseDocDelete(licenseDoc[0])}
            />
          </div>
        ) : null}
      </Grid>
    </Fragment>
  );
};

DocUpload.propTypes = {
  uploadDocument: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired,
  uploadedDocuments: PropTypes.arrayOf(PropTypes.shape({})),
};

DocUpload.defaultProps = {
  uploadedDocuments: [],
};

export default DocUpload;
