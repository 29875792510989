import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';

const anchorPosition = {
  vertical: 'top',
  horizontal: 'center',
};

const SuccessMessgae = (props) => {
  const { isMsgTriggered, msg } = props;
  return (
    <Snackbar
      className="register-success"
      anchorOrigin={anchorPosition}
      open={isMsgTriggered}
      message={msg}
      key={anchorPosition.vertical + anchorPosition.horizontal}
    />
  );
};

SuccessMessgae.propTypes = {
  isMsgTriggered: PropTypes.bool,
  msg: PropTypes.string,
};

SuccessMessgae.defaultProps = {
  isMsgTriggered: false,
  msg: null,
};

export default SuccessMessgae;
