import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { hours } from '../../utils/helper';

class DayChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadyToRender: false,
      series: [
        {
          name: 'Donation',
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        title: {
          text: `Sales performance by Month`,
          align: 'left',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: hours,
        },
      },
    };
  }

  componentDidMount() {
    const { series } = this.state;
    const { graphData } = this.props;
    const values = [];

    hours.forEach((hour) => {
      const hourValue = graphData.find((data) => data.date === hour);
      if (hourValue && hourValue.donations) {
        values.push(hourValue.donations);
      } else {
        values.push(0);
      }
    });

    series[0].data = values;
    this.setState({
      series,
      isReadyToRender: true,
    });
  }

  render() {
    const { options, series, isReadyToRender } = this.state;
    return isReadyToRender ? (
      <Chart options={options} series={series} type="line" height={350} />
    ) : null;
  }
}

DayChart.propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.shape({})),
};

DayChart.defaultProps = {
  graphData: [],
};

export default DayChart;
