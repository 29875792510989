import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Banner() {
  const { t } = useTranslation();
  return (
    <section className="banner-section">
      <div className="overlay">
      </div>
      <div className="banner-text-row">
        <div className="banner-text">
          <h3 className="banner-bottom-text">{t('banner_bottom_text')}</h3>
          <h1 className="font-light banner-title">{t('banner_take_you')} {t('banner_invest_startegy')}</h1>
          <h1 className="font-regular banner-title">{t('banner_next_level')}</h1>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1679916196">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" class="shape-fill"></path>
          </svg>
      </div>
    </section>
  );
}
