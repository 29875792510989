import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';
import step1image from '../../../assets/img/qr-ehcw-parkhaus-rund.png';
import step2image from '../../../assets/img/second-step-how-to.png';
import step3image from '../../../assets/img/howto-happy.png';

const HowItWorks = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Wie funktioniert MY SPONSOR? | Deine Sponsoring-Plattform</title>
          <meta name="description" content="MY SPONSOR ist ein neues Start-Up im Sponsoring-Bereich für Vereine & Athleten /-innen. Unsere App automatisiert viele Prozesse und spart Zeit & Geld." />
          <meta property="og:title" content="Berater / Agent 10-100% | Jobs  | MY SPONSOR AG" />
          <meta property="og:description" content="MY SPONSOR ist ein neues Start-Up im Sponsoring-Bereich für Vereine & Athleten /-innen. Unsere App automatisiert viele Prozesse und spart Zeit & Geld." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/wie-funktionierts" />
          <meta name="twitter:title" content="Wie funktioniert MY SPONSOR? | Deine Sponsoring-Plattform" />
          <meta name="twitter:description" content="MY SPONSOR ist ein neues Start-Up im Sponsoring-Bereich für Vereine & Athleten /-innen. Unsere App automatisiert viele Prozesse und spart Zeit & Geld." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row pb-bottom-0">
            <div className="col-lg-12">
              <h5>{t('how_subtitle')}</h5>
              <h1 className="page-title">{t('how_title')}</h1>
            </div>
            <div className="col-lg-12 pb-bottom-50">
              <div className="text-default">
                <h2 className="title">
                  {t('how_title_1_1')}<span>{t('how_title_1_span')}</span>{t('how_title_1_2')}
                </h2>
                <p className="text">
		{t('how_text_1')}
                </p>
              </div>
            </div>
            <div className="col-lg-4 how-steps">
              <div className="works-steps-img"><img src={step1image} alt={t('download_part_firsticon')} width="250" height="250" /></div>
              <div className="step-number">#1</div>
              <h3>{t('download_part_firsticon')}</h3>
            </div>
            <div className="col-lg-4 how-steps how-steps-second">
              <div className="works-steps-img"><img src={step2image} alt={t('download_part_firsticon')} width="250" height="250" /></div>
              <div className="step-number">#2</div>
              <h3>{t('download_part_secondicon')}</h3>
            </div>
            <div className="col-lg-4 how-steps">
              <div className="works-steps-img"><img src={step3image} alt={t('download_part_firsticon')} width="250" height="250" /></div>
              <div className="step-number">#3</div>
              <h3>{t('download_part_thirdicon')}</h3>
            </div>
            <div className="col-lg-12 to-benefits-button">
              <a href="/sponsoring-vereine" className="forward-btn to-pro-clubs">{t('to_pro_clubs_button')}</a>
            </div>
          </div>
          <div className="row pb-bottom-80 video-row">
            <div className="col-lg-2" />
            <div className="col-lg-8">
              <div className="text-default">
                <iframe
                  width="100%"
                  height="380"
                  src={t('embed_video_emotion')}
                  title="MY SPONSOR"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-default">
                <h2 className="title">
                 {t('how_title_2_1')}<span>{t('how_title_2_span')}</span>
                </h2>
                <p className="text">
                  {t('how_text_2')}
                </p>
              </div>
            </div>
          </div>
          <div className="row cta-row">
            <div className="col-lg-9">
              <h4>{t('cta_text')}</h4>
            </div>
            <div className="col-lg-3">
              <a href="/clubs" className="forward-btn">
                {t('donationcert_button')}
              </a>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default HowItWorks;
