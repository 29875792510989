import { DISPLAY_SPINNER } from '../utils/types';

const initialState = {
  isLoading: false,
};

export default function spinnerReducer(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_SPINNER:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}
