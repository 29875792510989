import {
  FETCH_ALL_CLUB_PRODUCTS,
  FETCH_ALL_PUBLIC_CLUB_PRODUCTS,
  FETCH_ALL_MEMBERSHIP_PRODUCTS,
  FETCH_ALL_TOP_PRODUCTS,
  FETCH_ALL_TOP_SPONSERS,
  FETCH_PRODUCT_BY_ID,
  CLEAR_PRODUCT_DATA,
} from '../utils/types';

const initialState = {
  clubProducts: [],
  clubAllProducts: [],
  clubMembershipProducts: [],
  topProducts: [],
  topSponsers: [],
  totalTopSponsors: 0,
  clubProduct: {},
};

export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_PUBLIC_CLUB_PRODUCTS:
      return { ...state, clubProducts: action.payload };
    case FETCH_ALL_CLUB_PRODUCTS:
        return { ...state, clubAllProducts: action.payload };
    case FETCH_ALL_MEMBERSHIP_PRODUCTS:
          return { ...state, clubMembershipProducts: action.payload };
    case FETCH_ALL_TOP_PRODUCTS:
      return { ...state, topProducts: action.payload };
    case FETCH_ALL_TOP_SPONSERS:
      return {
        ...state,
        topSponsers: [...state.topSponsers, ...action.payload.content],
        totalTopSponsors: action.payload.totalElements,
      };
    case FETCH_PRODUCT_BY_ID:
      return {
        ...state,
        clubProduct: action.payload,
      };
    case CLEAR_PRODUCT_DATA:
      return {
        ...state,
        clubProduct: {},
      };
    default:
      return state;
  }
}
