import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

import financial from '../../../assets/img/financial-dashboard.jpg';
import profile from '../../../assets/img/profie-club.jpg';
import dashboard from '../../../assets/img/dashboard.jpg';
import zelt from '../../../assets/img/mys-zelt.jpg';

const SponsorAssociations = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Sponsorings für Vereine | Sponsoring-Plattform | MY SPONSOR</title>
          <meta name="description" content="Spenden sammeln als Verein einfach gemacht. MY SPONSOR ist die Schweizer Sponsoring-Plattform und übernimmt all Deinen Administrativen Aufwand." />
          <meta property="og:title" content="Sponsorings für Vereine | Sponsoring-Plattform | MY SPONSOR" />
          <meta property="og:description" content="Spenden sammeln als Verein einfach gemacht. MY SPONSOR ist die Schweizer Sponsoring-Plattform und übernimmt all Deinen Administrativen Aufwand." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/sponsoring-vereine" />
          <meta name="twitter:title" content="Sponsorings für Vereine | Sponsoring-Plattform | MY SPONSOR" />
          <meta name="twitter:description" content="Spenden sammeln als Verein einfach gemacht. MY SPONSOR ist DIE Schweizer Sponsoring-Plattform und übernimmt all Deinen Administrativen Aufwand." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row pb-bottom-80">
            <div className="col-lg-12">
              <h5>{t('landing_club_subtitle')}</h5>
              <h1 className="page-title">{t('landing_club_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <h2 className="title">
                  <span>{t('landing_club_sc_one_title_span')}</span>
                  {t('landing_club_sc_one_title')}
                </h2>
                <p className="text">{t('landing_club_sc_one_text')} <a href="/register/club" >{t('landing_register_now')}</a></p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-80">
            <div className="col-lg-6">
              <div className="text-default" />
		<img className="smart-phone donate-step" src={profile} alt="Club | MY SPONSOR" title="Profil" />
            </div>
            <div className="col-lg-6 landing-text">
              <div className="text-default">
                <h2 className="title">
                  <span>{t('landing_club_sc_two_title_span')}</span>
                  {t('landing_club_sc_two_title')}
                </h2>
                <p className="text">{t('landing_club_sc_two_text')}</p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-80">
            <div className="col-lg-6 landing-text">
              <div className="text-default">
                <h2 className="title">
                  <span>{t('landing_club_sc_three_title_span')} </span>
                  {t('landing_club_sc_three_title')}
                </h2>
                <p className="text">
                  {t('landing_club_sc_three_text_top')}
                  <ul className="landing-list">
                    <li>{t('landing_club_sc_three_listone')}</li>
                    <li>{t('landing_club_sc_three_listtwo')}</li>
                    <li>{t('landing_club_sc_three_listthree')}</li>
                    <li>{t('landing_club_sc_three_listfour')}</li>
                  </ul>
                  {t('landing_club_sc_three_text_bottom')}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-default" />
		<img className="smart-phone donate-step" src={financial} alt="Finanzen | MY SPONSOR" title="Features"  />
            </div>
          </div>
          <div className="row pb-bottom-80">
            <div className="col-lg-6">
              <div className="text-default" />
		<img className="smart-phone donate-step" src={dashboard} alt="Dashboard | MY SPONSOR" title="Dashboard" />
            </div>
            <div className="col-lg-6 landing-text">
              <div className="text-default">
                <h2 className="title">
                  {t('landing_club_sc_four_title_first')}
                  <span> {t('landing_club_sc_four_title_span')}</span>
                  {t('landing_club_sc_four_title_after')}
                </h2>
                <p className="text">
                  {t('landing_club_sc_four_text_top')}
                  <br /><br />
                  {t('landing_club_sc_four_text_bottom')}
                </p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-80">
            <div className="col-lg-6 landing-text">
              <div className="text-default">
                <h2 className="title">
                  <span>{t('landing_club_sc_tent_title_span')} </span>
                   {t('landing_club_sc_tent_title')}
                </h2>
                <p className="text">
                  {t('landing_club_sc_tent_text_top')}<br />
                   <br />{t('landing_club_sc_tent_text_bottom')}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-default" />
    <img className="smart-phone donate-step" src={zelt} alt="Zelt | MY SPONSOR" title="Event-Zelt"  />
            </div>
          </div>
          <div className="row pb-bottom-80">
            <div className="col-lg-5 landing-video-left">
              <div className="text-default">
                <h2 className="title">
                  <span>{t('landing_ready_one')}</span> {t('landing_ready_two')}
                </h2>
              </div>
            </div>
            <div className="col-lg-7">
              <iframe
              src={t('embed_video_create_club')}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              width="100%"
              height="380"
              title="MY SPONSOR"
              />
            <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p className="forward-text">
                {t('landing_club_sc_five_text_first')}{' '}
                <a href="mailto:support@mysponsor.ch">support@mysponsor.ch</a>{' '}
                {t('landing_club_sc_five_text_second')}
              </p>
              <a href="/register/club" className="forward-btn">
                {t('landing_register_now')}
              </a>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default SponsorAssociations;
