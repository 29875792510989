import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EventSlider from '../../Carousel/EventSlider';

function Events(props) {
  const { events } = props;
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language');
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

// <h5 className="investment-title">{t('events_part_subtitle')}</h5>
//<h2 className="investment-subtitle">{t('events_part_title')}</h2>
//<p className="investment-title-describe">{t('events_part_text')}</p>

// bottom carousel
// {events && events.length > 0 ? <EventSlider items={events} /> : null}

  return (
    <section className="investment-section" id="investment-section">
      <div className="overlay">
        <div className="container text-center">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="investment-text">
                <h5 className="investment-title">{t('partner_part_subtitle')}</h5>
                <h2 className="investment-subtitle">{t('partner_part_title')}</h2>
                <p className="investment-title-describe">{t('partner_part_text')}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="investment-section-carousel">
                  <a href="https://mysponsor.ch/football-is-more" className="forward-btn">{t('partner_part_button')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Events.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Events;
