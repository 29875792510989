import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

import dashboard from '../../../assets/img/dahboard-550.jpg';
import tesla from '../../../assets/img/tesla-mys.jpg';
import agent from '../../../assets/img/agent-mys.png';

const SponsorAthletes = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Berater / Agent 10-100% | Jobs  | MY SPONSOR AG</title>
          <meta name="description" content="Du bist ein Verkaufs-Genie und suchst eine neue Herausforderung? Dann bewirb Dich bei uns und werde Teil von MY SPONSOR." />
          <meta property="og:title" content="Berater / Agent 10-100% | Jobs  | MY SPONSOR AG" />
          <meta property="og:description" content="Du bist ein Verkaufs-Genie und suchst eine neue Herausforderung? Dann bewirb Dich bei uns und werde Teil von MY SPONSOR." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/jobs/agent-werden" />
          <meta name="twitter:title" content="Berater / Agent 10-100% | Jobs  | MY SPONSOR AG" />
          <meta name="twitter:description" content="Du bist ein Verkaufs-Genie und suchst eine neue Herausforderung? Dann bewirb Dich bei uns und werde Teil von MY SPONSOR." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
         <div className="container">
          <div className="row pb-bottom-60">
            <div className="col-lg-12">
              <h5>{t('job_subtitle')}</h5>
              <h1 className="page-title">{t('job_agent_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('job_open_text')}</p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-50">
            <div className="col-lg-6">
              <div className="text-default" />
		<img className="smart-phone donate-step" src={dashboard} alt="Club | MY SPONSOR" title="Profil" />
            </div>
            <div className="col-lg-6 landing-text">
              <div className="text-default">
                <h2 className="title">
                  <span>{t('job_agent_title_one_first')}</span>
                  {t('job_agent_title_one_second')}
                </h2>
                <p className="text mg-bottom-20">{t('job_agent_text_one_one')}</p>
                <p className="text">{t('job_agent_text_one_two')}</p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-80">
            <div className="col-lg-6 landing-text">
              <div className="text-default">
                <h2 className="title">
                  <span>{t('job_agent_title_two_first')}</span>
                  {t('job_agent_title_two_second')}
                </h2>
                <p className="text">
                  {t('job_agent_text_two_one')}
                  <ul className="landing-list">
                    <li>{t('job_agent_list_one')}</li>
                    <li>{t('job_agent_list_two')}</li>
                    <li>{t('job_agent_list_three')}</li>
                    <li>{t('job_agent_list_four')}</li>
                    <li>{t('job_agent_list_five')}</li>
                    <li>{t('job_agent_list_six')}</li>
                  </ul>
                  {t('job_agent_text_two_two')}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-default" />
		<img className="smart-phone donate-step" src={tesla} alt="Finanzen | MY SPONSOR" title="Features"  />
            </div>
          </div>
          <div className="row pb-bottom-80">
            <div className="col-lg-6">
              <div className="text-default" />
		<img className="smart-phone donate-step" src={agent} alt="Dashboard | MY SPONSOR" title="Dashboard" />
            </div>
            <div className="col-lg-6 landing-text">
              <div className="text-default">
                <h2 className="title">
                  <span>{t('job_agent_title_three_first')}</span>{t('job_agent_title_three_second')}
                </h2>
                <p className="text mg-bottom-20">
                  {t('job_agent_text_three_one')}
                </p>
                <p className="text mg-bottom-20">
                  {t('job_agent_text_three_two')}
                </p>
                <p className="text">
                  {t('job_agent_text_three_three')}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <p className="forward-text">
                {t('job_agent_text_end_one')}
                 <a href="mailto:jobs@mysponsor.ch"> jobs@mysponsor.ch</a>. {t('job_agent_text_end_two')}
              </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 jobs-button-column">
              <a href="https://pb-g.ch/IdrE" className="forward-btn jobs-bottom-button">
                {t('job_agent_button')}
              </a>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default SponsorAthletes;
