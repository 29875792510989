import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import defaultImage from '../../../assets/img/blog-sm-1.jpg';
import { displayDescription, imageBaseUrl } from '../../../utils/helper';
import { useTranslation } from 'react-i18next';
import coverImage from '../../../assets/img/cover-user.jpg';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  viewBtnColor: {
    color: '#f50',
  },
}));

export default function Album(props) {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const classes = useStyles();
  const { clubs, handleClick } = props;

  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      <div>
        <Helmet>
          <title>Verein oder Athlet*in finden & online spenden| MY SPONSOR</title>
          <meta name="description" content="Finde hier Deinen Verein, Athlet*in oder Academy und spende direkt online oder in unserer App!" />
          <meta property="og:title" content="Verein oder Athlet*in finden & online spenden| MY SPONSOR" />
          <meta property="og:description" content="Finde hier Deinen Verein, Athlet*in oder Academy und spende direkt online oder in unserer App!" />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/clubs" />
          <meta name="twitter:title" content="Verein oder Athlet*in finden & online spenden| MY SPONSOR" />
          <meta name="twitter:description" content="Finde hier Deinen Verein, Athlet*in oder Academy und spende direkt online oder in unserer App!" />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <main>
        <Container className={classes.cardGrid} id="clubs" maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {clubs.map((club) => (
              <Grid
                item
                key={club}
                xs={12}
                sm={6}
                md={4}
                onClick={() => handleClick(club.shortName)}
              >
                <Card className={classes.card}>
                 {club.cover ? (
                    <img
                      className="cover-image"
                      src={`${imageBaseUrl}/${club.cover}`}
                      alt="cover"
                    />
                  ) : (
                    <img
                      className="cover-image"
                      src={coverImage}
                      alt="cover"
                    />
                  )}
                 {club.logo ? (
                    <img
                      className="card-logo"
                      src={`${imageBaseUrl}/${club.logo}`}
                      alt="club-icon"
                    />
                  ) : (
                    <CardMedia
                      className={classes.iconImage}
                      image={defaultImage}
                      title="Image title"
                    />
                  )}
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2" className="club-list-title">
                      {club.name}
                    </Typography>
                    <Typography className="club-list-desc">{ displayDescription(club,selectedLanguage) }</Typography>
                  </CardContent>
                  <CardActions className="club-list-button">
                    <Button className={classes.viewBtnColor} size="small">
                      {t('club-list-sponsor-now')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      {/* End footer */}
    </React.Fragment>
  );
}

Album.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.shape({})),
  handleClick: PropTypes.func.isRequired,
};

Album.defaultProps = {
  clubs: [],
};
