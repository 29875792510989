import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PropTypes from 'prop-types';
import logo from '../../assets/img/logo-form.png';
import CountrySelect from '../Select/CountrySelect';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 30,
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    margin: 3,
    padding: 2,
  },
  backArrow: {
    fontSize: '2.5rem',
    marginTop: 20,
    color: '#057cf3',
  },
}));

function SignUp(props) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const { handleChange, handleSubmit, handleCountryChange, errors, country } = props;
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <Container className="form-container" component="main" maxWidth="xs">
      <a href="/">
        <KeyboardBackspaceIcon className={classes.backArrow} />
      </a>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.logo}>
          <a href="/">
            <img src={logo} alt="reg-icon" />
          </a>
        </div>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-firstName-input"
                label={t('first_name')}
                autoComplete="fname"
                name="firstName"
                required
                fullWidth
                autoFocus
                onChange={handleChange}
                error={errors.firstName}
                helperText={errors.firstName}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-lastName-input"
                label={t('last_name')}
                autoComplete="lname"
                name="lastName"
                required
                fullWidth
                onChange={handleChange}
                error={errors.lastName}
                helperText={errors.lastName}
                inputProps={{
                  maxLength: 40,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-email-input"
                label={t('email_address')}
                name="email"
                required
                fullWidth
                onChange={handleChange}
                error={errors.email}
                helperText={errors.email}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-password-input"
                type={showPassword ? "text" : "password"}
                label={t('password')}
                name="password"
                required
                fullWidth
                onChange={handleChange}
                error={errors.password}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-password-input"
                type={showConfirmPassword ? "text" : "password"}
                label={t('confirm_password')}
                name="confirmPassword"
                required
                fullWidth
                onChange={handleChange}
                error={errors.confirmPassword}
                helperText={errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-address1-input"
                label={t('address_line1')}
                name="address1"
                fullWidth
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-address2-input"
                label={t('address_line2')}
                name="address2"
                fullWidth
                onChange={handleChange}
                error={errors.address2}
                helperText={errors.address2}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-postcode-input"
                type="number"
                label={t('post_code')}
                name="postcode"
                fullWidth
                onChange={handleChange}
                error={errors.postcode}
                helperText={errors.postcode}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="standard-city-input"
                label={t('city')}
                name="city"
                fullWidth
                onChange={handleChange}
                error={errors.city}
                helperText={errors.city}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CountrySelect
                name="country"
                label={t('country')}
                handleChange={(e, option) => handleCountryChange(option)}
                selectedCountry={country || ''}
                error={errors.country}
                helperText={errors.country}
              />
            </Grid>
            <Grid item xs={12}>
            <div>{t('condition_pre')} <a href="/agb" target="_blank">{t('condition_agb')}</a> {t('condition_btw')} <a href="/datenschutz" target="_blank">{t('condition_privacy')}</a> {t('condition_aft')}</div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSubmit(e)}
          >
            {t('sign_up')}
          </Button>
          <Grid container mt={5} style={{ justifyContent: 'center' }}>
            <Grid item>
              <Link href="/login" variant="body2">
                {t('already_have_account')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>

    </Container>
  );
}

SignUp.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  country: PropTypes.string,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    address2: PropTypes.string,
    postcode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
};

SignUp.defaultProps = {
  errors: {},
  country: '',
};

export default SignUp;
