import React from 'react';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import PropTypes from 'prop-types';
import { displayDescription, imageBaseUrl } from '../../utils/helper';
import 'react-multi-carousel/lib/styles.css';
import coverImage from '../../assets/img/cover-user.jpg';
import Icon from '../../assets/img/product-holder.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    partialVisibilityGutter: 10, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 10, // this is needed to tell the amount of px that should be visible.
  },
};

function CarouselSlider(props) {
  const history = useHistory();

  const { items, selectedLanguage } = props;
  const handleClick = (item) => {
    // const clubName = slugify(item.name);
    history.push(`/${item.shortName}`);
  };
  return (
    <Carousel partialVisible responsive={responsive}>
      {items.map((club) => {
        return (
          <div
            className="col club-item club-carousel"
            onClick={() => handleClick(club)}
            aria-hidden="true"
            key={club.id}
          >
            <div className="single-item">
              <div className="icon-box">
                 {club.cover ? (
                    <img
                      className="cover-image"
                      src={`${imageBaseUrl}/${club.cover}`}
                      alt="cover"
                    />
                  ) : (
                    <img
                      className="cover-image"
                      src={coverImage}
                      alt="cover"
                    />
                  )}
                {club.logo ? (
                  <img
                    className="home-clubs-logo"
                    src={`${imageBaseUrl}/${club.logo}`}
                    alt={club.name}
                  />
                ) : (
                  <img className="home-clubs-logo" src={Icon} alt={club.name} />
                )}
              </div>
              <div className="text-box">
                <h2 className="single-item-title">{club.name}</h2>
                <p className="clubs-slider-desc single-item-description">{displayDescription(club,selectedLanguage)}</p>
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
}

CarouselSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  history: PropTypes.shape({ push: PropTypes.func }),
};

CarouselSlider.defaultProps = {
  items: [],
  history: null,
};

export default CarouselSlider;
