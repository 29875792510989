import axios from 'axios';
import {
  headers,
  baseUrl,
  authHeader,
  translateAuthKey,
  translateApiUrl,
  multipartAuthHeader,
} from '../utils/helper';
import {
  FETCH_ALL_CLUB_PRODUCTS,
  FETCH_ALL_PUBLIC_CLUB_PRODUCTS,
  FETCH_ALL_MEMBERSHIP_PRODUCTS,
  FETCH_ALL_TOP_PRODUCTS,
  FETCH_ALL_TOP_SPONSERS,
  FETCH_PRODUCT_BY_ID,
  CLEAR_PRODUCT_DATA,
} from '../utils/types';
import displaySpinner from './spinnerAction';
import { displaySuccess, clearSuccess, displayError, clearError } from './formResponseAction';
import { refreshToken } from './userActions';

export const getClubProducts = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/products/filter`, data, headers);
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_PUBLIC_CLUB_PRODUCTS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getAllClubProducts = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/products/all`, data, authHeader());
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_CLUB_PRODUCTS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};


export const getMembershipProducts = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${baseUrl}/products/membership-products`, data, authHeader());
      if (response && response.data) {
        dispatch({
          type: FETCH_ALL_MEMBERSHIP_PRODUCTS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const getClubTopProducts = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/top-products`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_ALL_TOP_PRODUCTS,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const createClubProduct = (data, onsuccess, successMessage, errorMessage) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/products`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess(successMessage));
          setTimeout(() => {
            dispatch(clearSuccess());
            onsuccess(response.data.id);
          }, 2000);
        }else {
          dispatch(displayError(errorMessage));
          setTimeout(() => {
          dispatch(clearError());
        }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(errorMessage));
        setTimeout(() => {
          dispatch(clearError());
        }, 2000);
      }
    }
  };
};

export const editClubProduct = (data, callBack) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/products`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Produkt erfolgreich aktualisiert!'));
          callBack();
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        callBack();
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 2000);
      }
      console.error('there is an error', error);
    }
  };
};

export const getClubTopSponsers = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/donations/top-sponsor`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_ALL_TOP_SPONSERS,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const uploadFiles = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(displaySpinner(true));
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/files`, data, multipartAuthHeader());
        if (response && response.data) {
          dispatch(displaySpinner(false));
          dispatch(displaySuccess('Bild erfolgreich hochgeladen!'));
          setTimeout(() => {
            dispatch(clearSuccess());
            cb();
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displaySpinner(false));
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
          cb();
        }, 2000);
      }
      console.error('there is an error', error);
    }
  };
};

export const uploadProductAndEventImages = (data, cb) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/files`, data, multipartAuthHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Bild erfolgreich hochgeladen!'));
          setTimeout(() => {
            dispatch(clearSuccess());
            cb();
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
          cb();
        }, 2000);
      }
      console.error('there is an error', error);
    }
  };
};

export const updateProductAndEventImages = (data, cb) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/files`, data, multipartAuthHeader());
        if (response && response.data) {
          dispatch(displaySuccess('Bild erfolgreich aktualisiert!'));
          setTimeout(() => {
            dispatch(clearSuccess());
            cb();
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
          cb();
        }, 2000);
      }
      console.error('there is an error', error);
    }
  };
};

export const updateFiles = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(displaySpinner(true));
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.put(`${baseUrl}/files`, data, multipartAuthHeader());
        if (response && response.data) {
          dispatch(displaySpinner(false));
          dispatch(displaySuccess('Bild erfolgreich aktualisiert!'));
          setTimeout(() => {
            dispatch(clearSuccess());
            cb();
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displaySpinner(false));
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
          cb();
        }, 2000);
      }
      console.error('there is an error', error);
    }
  };
};

export const deactivateProduct = (data) => {
  return async (dispatch, getState) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const { user } = getState();
        dispatch(displaySpinner(true));
        const response = await axios.put(`${baseUrl}/products/status`, data, authHeader());
        if (response && response.data) {
          dispatch(displaySpinner(false));
          dispatch(getAllClubProducts({ clubId: user.authUserData.clubId }));
          dispatch(displaySuccess('Produkt erfolgreich deaktiviert!'));
          setTimeout(() => {
            dispatch(clearSuccess());
          }, 2000);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch(displaySpinner(false));
        dispatch(displayError(error.response.data.message));
        setTimeout(() => {
          dispatch(clearError());
        }, 2000);
      }
      console.error('there is an error', error);
    }
  };
};

export const descriptionTranslate = async (description, targetLanguage) => {
  try {
    const details = {
      auth_key: translateAuthKey,
      text: description,
      target_lang: targetLanguage,
    };
    let formBody = [];

    Object.entries(details).forEach(([property, value]) => {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(value);
      formBody.push(`${encodedKey}=${encodedValue}`);
    });

    formBody = formBody.join('&');
    const response = await fetch(`${translateApiUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    });
    const result = await response.json();
    if (result && result.translations) {
      return result.translations[0].text;
    }
    return false;
  } catch (e) {
    console.error('descrptionTranslate error', e);
    return false;
  }
};

export const getClubProductById = (data) => {
  return async (dispatch) => {
    try {
      const refreshUser = await refreshToken();
      if (refreshUser) {
        const response = await axios.post(`${baseUrl}/products/filter`, data, authHeader());
        if (response && response.data) {
          dispatch({
            type: FETCH_PRODUCT_BY_ID,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.error('there is an error', error);
    }
  };
};

export const clearProduct = () => ({
  type: CLEAR_PRODUCT_DATA,
});
