import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Chart from '../../Charts/DonationOverviewChart';
import Spinner from '../../Spinner';
import PayoutsList from './PayoutResult'

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  filterMargin: {
    margin: 10,
  },
}));
function Overview(props) {
  const { data, periodFilter, handleChangeTimeLine, isLoading, payouts, pageDetails, handlePageChange, handlePageSizeChange, totalCount, authUserData } = props;
  const donationData = data && data.donationDistribution ? data.donationDistribution : [];
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  return (
    <div className="tab-pane in active" id="tab0">
      <div>
        <h4>{t('financial_overview')}</h4>
        <div className={classes.filterMargin}>
          <Select
            value={periodFilter}
            onChange={handleChangeTimeLine}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
            disabled={isLoading}
          >
            <MenuItem value="all">{t('total')}</MenuItem>
            <MenuItem value="year">{t('jahr')}</MenuItem>
            <MenuItem value="month">{t('monat')}</MenuItem>
            <MenuItem value="week">{t('woche')}</MenuItem>
            <MenuItem value="day">{t('tag')}</MenuItem>
          </Select>
        </div>
        <p>
          {isLoading ? <Spinner /> : <Chart graphData={donationData} filterType={periodFilter} />}
        </p>
      </div>
      <div id='payout-history'>
        <div >
          <h4>{t('payout')}</h4>
        </div>
        <div>
          <PayoutsList
            payouts={payouts}
            pageDetails={pageDetails}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            authUserData={authUserData}
          />
        </div>
      </div>
    </div>
  );
}

Overview.propTypes = {
  data: PropTypes.shape({
    donationDistribution: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  periodFilter: PropTypes.string.isRequired,
  handleChangeTimeLine: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

Overview.defaultProps = {
  data: {
    donationDistribution: [],
  },
};

const mapStateToProps = (state) => ({
  isLoading: state.spinner.isLoading,
});

export default connect(mapStateToProps, {})(Overview);
