import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Header';
import Footer from '../../Footer/HomePageFooter';
import { Helmet } from 'react-helmet';
import ogimage from '../../../assets/img/mys-og-image.jpg';

import logoguide from '../../../assets/img/logo-guide.png';
import logoszip from '../../../assets/img/Logos-MY-SPONSOR.zip';

const PressAndMedia = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);
  return (
    <Fragment>
      <div className="profile-header">
        <Header />
      </div>
      <div>
        <Helmet>
          <title>Presse & Medien | Sponsoring-Plattform | MY SPONSOR AG</title>
          <meta name="description" content="Du bist Journalist / Journalistin und benötigst Information oder möchtest Dich mit uns unterhalten? Einfach nur das Logo -  auch das findest Du hier." />
          <meta property="og:title" content="Presse & Medien | Sponsoring-Plattform | MY SPONSOR AG" />
          <meta property="og:description" content="Du bist Journalist / Journalistin und benötigst Information oder möchtest Dich mit uns unterhalten? Einfach nur das Logo -  auch das findest Du hier." />
          <meta property="og:image" content={ogimage} />
          <meta property="og:url" content="https://www.mysponsor.ch/presse-medien" />
          <meta name="twitter:title" content="Presse & Medien | Sponsoring-Plattform | MY SPONSOR AG" />
          <meta name="twitter:description" content="Du bist Journalist / Journalistin und benötigst Information oder möchtest Dich mit uns unterhalten? Einfach nur das Logo -  auch das findest Du hier." />
          <meta name="twitter:image" content={ogimage} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      </div>
      <section className="main-section navigation">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5>{t('press_subtitle')}</h5>
              <h1 className="page-title">{t('press_title')}</h1>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">
                  {t('press_text')}
                  <a href="mailto:media@mysponsor.ch">media@mysponsor.ch</a>
                </p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-50">
            <div className="col-lg-12">
              <h2 className="media-image-title">{t('press_image_title')}</h2>
            </div>
            <div className="col-lg-12">
              <div className="text-default">
                <p className="text">{t('press_image_text')}</p>
              </div>
            </div>
          </div>
          <div className="row pb-bottom-80">
            <div className="col-lg-4 color-guide-left">
              <h3 className="media-image-title">{t('press_color_title')}</h3>
            </div>
            <div className="col-lg-8 color-guide">
              <div className="text-default">
		<div className="color-box">
               	  <p className="text">#4a903d</p>
		  <span className="colorbg green"></span>
		</div>
		<div className="color-box">
               	  <p className="text">#0b74ae</p>
		  <span className="colorbg bluelight"></span>
		</div>
		<div className="color-box">
               	  <p className="text">#003252</p>
		  <span className="colorbg bluedark"></span>
		</div>
		<div className="color-box">
               	  <p className="text">#000000</p>
		  <span className="colorbg black"></span>
		</div>
		<div className="color-box">
               	  <p className="text">#ffffff</p>
		  <span className="colorbg white"></span>
		</div>
              </div>
            </div>
          </div>
          <div className="row logo-guide pb-bottom-80">
            <div className="col-lg-7">
		<img src={logoguide} alt="" />
            </div>
            <div className="col-lg-5">
              <div className="text-default">
              <h3 className="media-image-title">{t('press_logo_title')}</h3>
		<p className="text text-title">{t('press_logo_subtitle_1')}</p>
		<p className="text">{t('press_logo_text_1')}</p>
		<p className="text text-title">{t('press_logo_subtitle_2')}</p>
		<p className="text">{t('press_logo_text_2')}</p>
		<a className="forward-btn" href={logoszip} download>{t('press_logo_button')}</a>
              </div>
            </div>
          </div>
          <div className="row background-row">
            <div className="col-lg-7 col-md-7" />
            <div className="col-lg-5 col-md-5">
              <div className="right-area">
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default PressAndMedia;
