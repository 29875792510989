import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { getUserDonationByClubId } from '../../../actions/userActions';
import { formatDate } from '../../../utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 5,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  subheader: {
    float: 'left',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  inline: {
    display: 'inline',
  },
  listTextA: {
    width: '75%',
  },
  listTextB: {
    width: '25%',
  },
}));
function RecipeReviewCard(props) {
  const {
    donationData,
    getUserDonationByClubId: fetchUserDonation,
    authUserData,
    clubDonations,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(null);
  const [clubDataFetch, setClubDataFetched] = React.useState({ clubId: '', isFetched: false });

  const handleExpandClick = (item, index) => {
    setExpanded(!expanded);
    setTabIndex(index);
    const { userId } = authUserData;
    if (clubDataFetch.clubId !== item.id) {
      fetchUserDonation({ userId, clubId: item.id });
      setClubDataFetched({ clubId: item.id });
    }
  };

  const displayDonationDetails = (details, index) => {
    return details && details.length > 0 && tabIndex === index ? (
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {details.map((detail) => (
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  className={classes.listTextA}
                  primary={formatDate(detail.date)}
                  secondary={detail.productTitle}
                />
                <ListItemText className={classes.listTextB} primary={`CHF ${detail.amount.toFixed(2)}`} />
              </ListItem>
              <Divider component="hr" />
            </List>
          ))}
        </CardContent>
      </Collapse>
    ) : null;
  };

  return (
    <Fragment>
      {donationData.map((item, index) => (
        <Card className={classes.root}>
          <CardHeader
            className="donation-history-card"
            avatar={<Avatar aria-label="recipe" className={classes.avatar} />}
            action={
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded && index === tabIndex,
                })}
                onClick={() => handleExpandClick(item, index)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            }
            title={item.name}
            subheader={`CHF ${item.amount}`}
          />
          {displayDonationDetails(clubDonations, index)}
        </Card>
      ))}
    </Fragment>
  );
}

RecipeReviewCard.propTypes = {
  donationData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getUserDonationByClubId: PropTypes.func.isRequired,
  authUserData: PropTypes.shape({
    userId: PropTypes.string,
  }),
  clubDonations: PropTypes.arrayOf(PropTypes.shape({})),
};

RecipeReviewCard.defaultProps = {
  authUserData: {},
  clubDonations: [],
};

const mapDispatchToProps = {
  getUserDonationByClubId,
};
const mapStateToProps = (state) => ({
  clubDonations: state.user.clubDonations,
  authUserData: state.user.authUserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeReviewCard);
