import {
  FETCH_ALL_CLUBS,
  FETCH_CLUB_DETAILS,
  FETCH_CLUBS_NEAR_BY,
  REGISTER_CLUB,
  SAVE_BANK_INFORMATION,
  SAVE_CLUB_CONTACT_PERSON,
  BACK_PREVIOUS_STEP,
  FETCH_ALL_TYPES,
  FETCH_GRAPH_DATA,
  FETCH_CLUB_REGISTRATION_DETAILS,
  CLEAR_CLUB_REGISTRATION_DETAILS,
  UPDATE_CLUB,
  UPDATE_CONTACT_PERSON,
  UPDATE_CLUB_REGISTER_FLOW,
  FETCH_PAYMENT_SUCCESS,
  FETCH_CLUB_PENDING_PAYOUT,
  FETCH_CLUB_PAYOUTS,
} from '../utils/types';

const saveClubDetails = (payload, prop) => {
  localStorage.setItem(prop, payload.id);
};

const getRegisteredClubDetails = (prop) => {
  const item = localStorage.getItem(prop);
  const result = item && item !== 'null' ? item : 0;
  return result;
};

const updateCompletedStep = (step) => {
  localStorage.setItem('clubRegisterCompletedStep', step);
};

const getCompletedStep = () => {
  return localStorage.getItem('clubRegisterCompletedStep');
};

const removeSavedDetails = () => {
  localStorage.removeItem('registeredClubId');
  localStorage.removeItem('contactPersonId');
};

const initialState = {
  isRetrieveClubsNearBy: false,
  clubs: {},
  nearByClubs: [],
  clubDetails: {},
  registeredClubId: getRegisteredClubDetails('registeredClubId'),
  contactPersonId: getRegisteredClubDetails('contactPersonId'),
  firstStepInfo: {},
  secondStepnfo: {},
  completedStep: getCompletedStep(),
  activeStep: 0,
  types: [],
  graphData: {},
  clubRegistrationDetails: {},
  paymentDetails: {},
};

export default function clubReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_CLUBS:
      return { ...state, clubs: action.payload };
    case FETCH_CLUBS_NEAR_BY:
      return { ...state, nearByClubs: action.payload, isRetrieveClubsNearBy: true };
    case FETCH_CLUB_DETAILS:
      return { ...state, clubDetails: action.payload };
    case REGISTER_CLUB:
      saveClubDetails(action.payload, 'registeredClubId');
      updateCompletedStep(1);
      return {
        ...state,
        firstStepInfo: action.payload,
        completedStep: 1,
        activeStep: 1,
        registeredClubId: action.payload.id,
      };
    case SAVE_CLUB_CONTACT_PERSON:
      saveClubDetails(action.payload, 'contactPersonId');
      updateCompletedStep(2);
      return {
        ...state,
        secondStepnfo: action.payload,
        completedStep: 2,
        activeStep: 2,
      };
    case SAVE_BANK_INFORMATION:
      updateCompletedStep(0);
      removeSavedDetails();
      return {
        ...state,
        secondStepnfo: action.payload,
        completedStep: 0,
        registeredClubId: null,
        activeStep: 0,
      };
    case BACK_PREVIOUS_STEP:
      return { ...state, activeStep: state.activeStep - 1 };
    case FETCH_ALL_TYPES:
      return { ...state, types: action.payload };
    case FETCH_GRAPH_DATA:
      return { ...state, graphData: action.payload };
    case FETCH_CLUB_REGISTRATION_DETAILS:
      return { ...state, clubRegistrationDetails: action.payload };
    case CLEAR_CLUB_REGISTRATION_DETAILS:
      return { ...state, clubRegistrationDetails: {} };
    case UPDATE_CLUB:
      return {
        ...state,
        activeStep: 1,
      };
    case UPDATE_CONTACT_PERSON:
      return {
        ...state,
        activeStep: 2,
      };
    case UPDATE_CLUB_REGISTER_FLOW:
      localStorage.setItem('registeredClubId', action.payload.clubId);
      localStorage.setItem('contactPersonId', action.payload.contactId);
      localStorage.setItem('clubRegisterCompletedStep', action.payload.completedStep);
      return {
        ...state,
        registeredClubId: action.payload.clubId,
        contactPersonId: action.payload.contactId,
        completedStep: action.payload.completedStep,
      };
    case FETCH_CLUB_PENDING_PAYOUT:
      return { ...state, pendingPayout: action.payload };
    case FETCH_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case FETCH_CLUB_PAYOUTS:
      return { ...state, clubPayouts: action.payload };
    default:
      return state;
  }
}
