import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import moment from 'moment';
import { days } from '../../utils/helper';

class WeekChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReadyToRender: false,
      series: [
        {
          name: 'Donation',
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 6,
            columnWidth: '45%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: days,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    const { series } = this.state;
    const { graphData } = this.props;
    const values = [];

    days.forEach((day) => {
      const weekValue = graphData.find(() => moment(new Date()).format('ddd') === day);
      if (weekValue && weekValue.donations) {
        values.push(weekValue.donations);
      } else {
        values.push(0);
      }
    });

    series[0].data = values;
    this.setState({
      series,
      isReadyToRender: true,
    });
  }

  render() {
    const { options, series, isReadyToRender } = this.state;
    return isReadyToRender ? (
      <Chart options={options} series={series} type="bar" height={350} />
    ) : null;
  }
}

WeekChart.propTypes = {
  graphData: PropTypes.arrayOf(PropTypes.shape({})),
};

WeekChart.defaultProps = {
  graphData: [],
};

export default WeekChart;
