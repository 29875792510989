import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { languageOptions, translateDropdownDefultValue } from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    paddingTop: 0,
  },
  bottomLabel: {
    paddingTop: 7,
  },
  desLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const displayLanguageOptions = () => {
  return languageOptions.map((languageOption) => (
    <option key={languageOption.id} value={languageOption.value}>
      {languageOption.name}
    </option>
  ));
};

const TranslateOptionSelect = (props) => {
  const { i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language') || 'en';
  const defaultValue = translateDropdownDefultValue(SelectedLanguageValue);
  const [transLanguage, setTransLanguage] = useState(defaultValue);
  const { handleTranslateOptions, description, isTranlationCompleted } = props;
  const classes = useStyles();

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  const changeLanguageHandler = (e) => {
    handleTranslateOptions(e);
    setTransLanguage(e.target.value);
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          {/* <h6 className={classes.bottomLabel}>description tranlation options: </h6> */}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.desLabel}>
        <NativeSelect
          value={transLanguage}
          disabled={!description || !isTranlationCompleted}
          name="selected language"
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'age' }}
          onChange={changeLanguageHandler}
        >
          {displayLanguageOptions()}
        </NativeSelect>
      </Grid>
    </Fragment>
  );
};

TranslateOptionSelect.propTypes = {
  handleTranslateOptions: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  isTranlationCompleted: PropTypes.bool.isRequired,
};

export default TranslateOptionSelect;
