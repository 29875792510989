import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditUserProfileForm from '../Forms/EditUserProfile';
import Spinner from '../Spinner';

export default function EditUserProfile(props) {
  const {
    open,
    handleClose,
    isLoading,
    userDetails,
    handleChange,
    userProfileErrors,
    handleAddressChange,
    handleSave,
    savedProfileImage,
    setProfileImage,
    removeProfileImage,
    handleCountryChange,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        id="editUserProfileModal"
      >
        <DialogTitle className="modal-title-center" id="responsive-dialog-title">
          {t('profile_edit_profile')}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <EditUserProfileForm
              errors={userProfileErrors}
              usedata={userDetails}
              handleChange={handleChange}
              handleAddressChange={handleAddressChange}
              savedProfileImage={savedProfileImage}
              setProfileImage={setProfileImage}
              removeProfileImage={removeProfileImage}
              handleCountryChange={handleCountryChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="cancel-button"
            variant="outlined"
            onClick={handleClose}
            disabled={isLoading}
          >
            {t('cancel')}
          </Button>
          <Button
            className="btn-global-filled"
            variant="outlined"
            onClick={handleSave}
            disabled={isLoading}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditUserProfile.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  userDetails: PropTypes.shape({}),
  userProfileErrors: PropTypes.shape({}),
  handleChange: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  savedProfileImage: PropTypes.func.isRequired,
  setProfileImage: PropTypes.func.isRequired,
  removeProfileImage: PropTypes.func.isRequired,
};

EditUserProfile.defaultProps = {
  isLoading: false,
  userDetails: {},
  userProfileErrors: {},
};
