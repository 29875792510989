import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { displayDescription, imageBaseUrl } from '../../../utils/helper';
import logoSample from '../../../assets/img/myFavoriteHolder.png';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../Alerts/ConfirmDialog';
import moment from 'moment';


const useStyles = makeStyles({
  root: {
    marginBottom: 15,
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  imageContainer: {
    marginRight: 15,
  },
  image: {
    width: 100,
    height: 'auto',
    borderRadius: 4,
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    marginBottom: 8,
    fontWeight: 'bold',
    color: '#333',
  },
  description: {
    marginBottom: 12,
    color: '#666',
  },
  info: {
    fontSize: 14,
    color: '#777',
  },
  subscriptionCard: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: 15,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    position: 'relative',
  },
  unsubscribeButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    backgroundColor: '#FF5733', // Change the background color to your preference
    color: '#FFF',
    borderRadius: 4,
    padding: '8px 16px',
    fontSize: 14,
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E74C3C', // Change the hover color if desired
    },
  },
});

const translatePeriod = (period, t) => {
  if (period === 'week') return t('per_week');
  if (period === 'month') return t('per_month');
  if (period === 'year') return t('per_year');
}

export default function UserSubscriptions(props) {
  const { userSubscriptions, handleCancelSubscriptionClick, setConfirmOpenUnsubscribe, openUnsubscribeConfirm } = props;
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleOpenVerifyConfirm = () => {
    setConfirmOpenUnsubscribe(true);
  };

  const handleUnsubscribe = (subId) => {
    handleCancelSubscriptionClick(subId, t("sub_cancel_suc"), t("sub_cancel_fail"));
    setConfirmOpenUnsubscribe(false);
  }

  const displaySubscriptions = (subscriptions) => {
    let subscriptionList = null;
    if (subscriptions && subscriptions.length > 0) {
      subscriptionList = subscriptions.map((sub) => {

        return (
          <div className={`row user-abo ${styles.root}`} key={sub.id}>
            <div className="col-md-2">
              <div className="item-media top-rounded overflow-hidden">
                {sub.product.image ? (
                  <img className="single-product-img" src={`${imageBaseUrl}/${sub.product.image}`} alt="" />
                ) : (
                  <img className="single-product-img" src={logoSample} alt="" />
                )}
              </div>
            </div>
            <div className="col-md-8">
              <div className="item-content">
                <h5>
                  <a className={sub.product.title}>
                    {sub.product.title}
                  </a>
                </h5>
                <p>{displayDescription(sub.product, selectedLanguage)}</p>
                <p>
                  {`${t("payment_amount")}  CHF: ${sub.amount.toFixed(2)} ${translatePeriod(sub.product.recurringPeriod, t)}`}
                  <br />
                  {`${t("next_payment")}: ${moment(sub.nextDate).format('DD.MM.YYYY')}`}
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <button onClick={handleOpenVerifyConfirm}
                className={styles.unsubscribeButton}>{t("unsubscribe")}</button>
              <ConfirmDialog
                title={t('unsubscribe')}
                open={openUnsubscribeConfirm}
                setOpen={setConfirmOpenUnsubscribe}
                onConfirm={() => handleUnsubscribe(sub.id)}
                className={"btn-global delete-account-btn"}
                positiveRequest={true}
              >
                {t('unsubscribe_message')}
              </ConfirmDialog>
            </div>
          </div>
        );
      });
    }
    return subscriptionList;
  };

  return (
    <div className="tab-pane in active" id="tab2">
      {displaySubscriptions(userSubscriptions)}
    </div>
  );
}

UserSubscriptions.propTypes = {
  userSubscriptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    product: PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      recurringPeriod: PropTypes.string.isRequired,
    }).isRequired,
    amount: PropTypes.number.isRequired,
    nextDate: PropTypes.string.isRequired,
  })).isRequired,
  handleCancelSubscriptionClick: PropTypes.func.isRequired,
};

UserSubscriptions.defaultProps = {
  userSubscriptions: [],
};
