import React, { Component, Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import { withTranslation } from 'react-i18next';
import ClubRegistrationFirstStep from '../../components/Forms/ClubRegistrationFirstStep';
import ClubRegistrationSecondStep from '../../components/Forms/ClubRegistrationSecondStep';
import ClubRegisterThirdStep from '../../components/Forms/ClubRegisterThirdStep';
import RegistrationSuccess from '../../components/Partials/RegistrationSuccess';
import Alerts from '../../components/Alerts';
import Header from '../../components/Header';
import Footer from '../../components/Footer/HomePageFooter';
import {
  validateEmail,
  baseUrl,
  docTypes,
  getTargetLanguagesAndDescriptions,
  authHeader,
  findDescriptionField,
} from '../../utils/helper';
import { uploadFiles, updateFiles, descriptionTranslate } from '../../actions/productActions';

import {
  registerClub,
  addContactPerson,
  addBankDetails,
  goPreviousStep,
  getAllTypes,
  getClubRegistrationDetails,
  updateClub,
  updateContactPerson,
} from '../../actions/clubActions';
import Spinner from '../../components/Spinner';

const styles = (theme) => ({
  appBar: {
    position: 'relative',
    paddingRight: 10,
    paddingLeft: 10,
  },
  svg: {
    verticalAlign: 'middle',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
});

const translatedDescription = {
  EN: '',
  DE: '',
  FR: '',
  IT: '',
};
class StepForm extends Component {
  state = {
    formValues: {
      clubName: '',
      clubEmail: '',
      website: '',
      address1: '',
      address2: '',
      postcode: '',
      country: '',
      city: '',
      contactNumber: '',
      sportType: [],
      clubSortName: '',
      clubDescription: '',
      cpFirstName: '',
      cpEmail:'',
      cpContact:'',
      cpLastName: '',
      cpAddress1: '',
      cpAddress2: '',
      cpPostCode: '',
      cpCity: '',
      cpCountry: '',
      cpDesignation: '',
      accountName: '',
      currency: '',
      iban: '',
      bankCountry: '',
      ibanConfirm: '',
      latitude: '',
      longitude: '',
      decriptionText: {
        enteredText: '',
        description: '',
        descriptionDe: '',
        descriptionFr: '',
        descriptionIt: '',
      },
      nature: 'CLUB',
    },
    adminEmailsInput: [{email:'',role:'ROLE_CLUB'}],
    clubLogo: [],
    savedLogo: [],
    updatedLogo: [],
    documents: {
      registrationDoc: [],
      licenseDoc: [],
    },
    savedContactId: null,
    errors: {},
  };

  componentDidMount() {
    const {
      completedStep,
      history,
      getAllTypes: fetchAllTypes,
      registeredClubId,
      getClubRegistrationDetails: fetchClubDetails,
    } = this.props;
    if (registeredClubId) {
      fetchClubDetails({ id: registeredClubId });
    }
    fetchAllTypes();
    if (completedStep === '3') {
      history.push('/');
    }
  }

  componentDidUpdate(prevProps) {
    const { formValues } = this.state;
    const { clubRegistrationDetails } = this.props;
    const adminEmailsList = [];
    const logoDetails = [];
    let savedContactPersonId = null;
    if (prevProps.clubRegistrationDetails !== clubRegistrationDetails) {
      if (clubRegistrationDetails.club) {
        formValues.clubName = clubRegistrationDetails.club.name;
        formValues.decriptionText.description = clubRegistrationDetails.club.description;
        formValues.decriptionText.descriptionDe = clubRegistrationDetails.club.descriptionDe;
        formValues.decriptionText.descriptionFr = clubRegistrationDetails.club.descriptionFr;
        formValues.decriptionText.descriptionIt = clubRegistrationDetails.club.descriptionIt;
        formValues.clubSortName = clubRegistrationDetails.club.shortName;
        formValues.clubEmail = clubRegistrationDetails.club.email;
        formValues.website = clubRegistrationDetails.club.website;
        formValues.address1 = clubRegistrationDetails.club.address.address1;
        formValues.address2 = clubRegistrationDetails.club.address.address2;
        formValues.postcode = clubRegistrationDetails.club.address.postalCode;
        formValues.city = clubRegistrationDetails.club.address.city;
        formValues.country = clubRegistrationDetails.club.address.country;
        formValues.sportType = this.getTypeId(clubRegistrationDetails.club.tags);
        formValues.contactNumber = clubRegistrationDetails.club.contactNumber;
        formValues.nature = clubRegistrationDetails.club.nature;
        if (clubRegistrationDetails.club.logo) {
          logoDetails.push({ logo: clubRegistrationDetails.club.logo });
        }
      }

      if (clubRegistrationDetails.contact) {
        savedContactPersonId = clubRegistrationDetails.contact.id;
        formValues.cpFirstName = clubRegistrationDetails.contact.firstName;
        formValues.cpLastName = clubRegistrationDetails.contact.lastName;
        formValues.cpEmail = clubRegistrationDetails.contact.email;
        formValues.cpContact = clubRegistrationDetails.contact.contactNumber;
        formValues.cpDesignation = clubRegistrationDetails.contact.position;
        formValues.cpAddress1 = clubRegistrationDetails.contact.address.address1;
        formValues.cpAddress2 = clubRegistrationDetails.contact.address.address2;
        formValues.cpCity = clubRegistrationDetails.contact.address.city;
        formValues.cpPostCode = clubRegistrationDetails.contact.address.postalCode;
        formValues.cpCountry = clubRegistrationDetails.contact.address.country;
      }

      if (clubRegistrationDetails.bank) {
        formValues.bankCountry = clubRegistrationDetails.bank.country;
        formValues.accountName = clubRegistrationDetails.bank.name;
        formValues.iban = clubRegistrationDetails.bank.iban;
        formValues.currency = clubRegistrationDetails.bank.currency;
      }

      if (clubRegistrationDetails.club && clubRegistrationDetails.club.adminEmails) {
        if (clubRegistrationDetails.club.adminEmails.length > 0) {
          clubRegistrationDetails.club.adminEmails.forEach((email, index) => {
            adminEmailsList.push(email );
          });
        }
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        formValues,
        adminEmailsInput: adminEmailsList,
        savedLogo: logoDetails,
        savedContactId: savedContactPersonId,
      });
    }
  }

  getTypeId = (types) => {
    if (types && types.length) {
      return types[0].id;
    }
    return null;
  };

  checkAllEmails = (emailList) => {
    let isValid = true;
    if (emailList && emailList.length > 0) {
      for (let i = 0; i < emailList.length; i += 1) {
        if (!validateEmail(emailList[i].email)) {
          isValid = false;
          return isValid;
        }
      }
    }
    return isValid;
  };

  validateFirstStep = (adminEmails) => {
    const { errors, formValues, clubLogo, savedLogo } = this.state;
    const selectedLang = this.getCurrentLanguage();
    const fieldName = findDescriptionField(selectedLang);
    let isValid = true;
    if (!formValues.nature) {
      isValid = false;
      errors.nature = 'Please select type';
    }
    if (!formValues.clubName) {
      isValid = false;
      errors.clubName = 'Please enter club name';
    }
    if (!formValues.decriptionText[fieldName]) {
      isValid = false;
      errors[fieldName] = 'Please enter club description';
    }
    if (!formValues.address1) {
      isValid = false;
      errors.address1 = 'Please enter club address line 1';
    }
    if (!formValues.postcode) {
      isValid = false;
      errors.postcode = 'Please enter postal Code';
    }
    if (!formValues.city) {
      isValid = false;
      errors.city = 'Please enter city';
    }
    if (!formValues.contactNumber) {
      isValid = false;
      errors.contactNumber = 'Please enter your phone number';
    }
    if (!formValues.clubEmail || !validateEmail(formValues.clubEmail)) {
      isValid = false;
      errors.clubEmail = 'please enter a valid email address';
    }
    if (!formValues.country) {
      isValid = false;
      errors.country = 'Please enter country';
    }
    if (formValues.sportType.length === 0) {
      isValid = false;
      errors.sportType = 'Please select a type';
    }
    if (clubLogo.length === 0 && savedLogo.length === 0) {
      isValid = false;
      errors.clubLogoFiles = 'please upload club logo';
    }
    if (adminEmails.length === 0) {
      isValid = false;
      errors.adminEmail = 'Du musst mindestens einen Administrator hinzufügen. Erfasse auch Deine E-Mailadresse.';
    } else if (!this.checkAllEmails(adminEmails)) {
      isValid = false;
      errors.adminEmail = 'Bitte eine gültige E-Mailadresse eingeben.';
    }
    this.setState({ errors });
    return isValid;
  };

  validateSecondStep = () => {
    const { errors, formValues } = this.state;
    let isValid = true;

    if (!formValues.cpFirstName) {
      isValid = false;
      errors.cpFirstName = 'Please enter first name of contact person';
    }
    if (!formValues.cpLastName) {
      isValid = false;
      errors.cpLastName = 'Please enter last name of contact person';
    }
    if (!formValues.cpContact) {
      isValid = false;
      errors.cpContact = 'Please enter phone number of contact person';
    }
    if (!formValues.cpEmail || !validateEmail(formValues.cpEmail)) {
      isValid = false;
      errors.cpEmail = 'Please enter phone number of contact person';
    }
    if (!formValues.cpDesignation) {
      isValid = false;
      errors.cpDesignation = 'Please enter position of contact person';
    }
    if (!formValues.cpAddress1) {
      isValid = false;
      errors.cpAddress1 = 'Please enter address of contact person';
    }
    if (!formValues.cpPostCode) {
      isValid = false;
      errors.cpPostCode = 'Please enter post code';
    }
    if (!formValues.cpCity) {
      isValid = false;
      errors.cpCity = 'Please enter city';
    }
    if (!formValues.cpCountry) {
      isValid = false;
      errors.cpCountry = 'Please enter country';
    }
    this.setState({ errors });
    return isValid;
  };

  validateThirdStep = () => {
    const { errors, formValues } = this.state;
    let isValid = true;

    if (!formValues.accountName) {
      isValid = false;
      errors.accountName = 'please enter bank account name';
    }
    if (!formValues.iban) {
      isValid = false;
      errors.iban = 'please enter iban';
    }
    if (!formValues.currency) {
      isValid = false;
      errors.currency = 'please select the currency';
    }
    if (!formValues.bankCountry) {
      isValid = false;
      errors.bankCountry = 'please enter the country';
    }
    this.setState({ errors });
    return isValid;
  };

  getSavedTypeIdList = (typeId) => {
    const typeList = [];
    typeList.push({ id: typeId });
    return typeList;
  };

  checkEmailExistOrNot = async (e) => {
    const { errors } = this.state;
    let isExist = true;
    const response = await fetch(`${baseUrl}/users/email-admin`, {
      method: 'post',
      ...authHeader(),
      body: JSON.stringify({ email: e.target.value }),
    });
    const data = await response.json();
    isExist = data.emailExists;
    if (isExist) {
      errors.adminEmail = 'Diese E-Mailadresse besitzt noch keinen MY SPONSOR Account. Die Person muss sich zuerst auf MY SPONSOR registrieren.';
    } else {
      errors.adminEmail = '';
    }
    this.setState({ errors });
  };

  setClubLogo = (file) => {
    const { errors } = this.state;
    const imageFile = [];
    imageFile.push(file);
    errors.clubLogoFiles = '';
    this.setState({ clubLogo: imageFile, errors });
  };

  removeClubLogo = (fileName) => {
    const { updatedLogo, savedLogo } = this.state;
    const imageFile = savedLogo.find((file) => file.logo === fileName);
    if (imageFile) {
      imageFile.action = 'remove';
      updatedLogo.push(imageFile);
      this.setState({ updatedLogo: imageFile });
    }
    this.setState({ savedLogo: [] });
  };

  uploadClubLogo = (id) => {
    const { clubLogo } = this.state;
    const { uploadFiles: uploadClubLogoImage } = this.props;
    const fileData = new FormData();
    fileData.append('file', clubLogo[0]);
    fileData.append('entity', 'club');
    fileData.append('column', 'logo');
    fileData.append('id', id);
    uploadClubLogoImage(fileData, () => { });
  };

  updateClubLogo = (id) => {
    const { clubLogo, updatedLogo } = this.state;
    const { updateFiles: updateClubLogoImage } = this.props;
    if (clubLogo.length > 0) {
      const fileData = new FormData();
      fileData.append('file', clubLogo[0]);
      fileData.append('entity', 'club');
      fileData.append('fileName', updatedLogo.logo);
      fileData.append('column', 'logo');
      fileData.append('id', id);
      updateClubLogoImage(fileData, () => { });
    }
  };

  handleNext = async () => {
    const { formValues, adminEmailsInput, savedContactId } = this.state;
    const { activeStep } = this.props;
    const {
      registeredClubId,
      registerClub: saveClub,
      addContactPerson: saveContactPerson,
      addBankDetails: saveBankInfo,
      updateClub: clubUpdate,
      updateContactPerson: clubContactPersonUpdate,
    } = this.props;


    if (activeStep === 0) {
      if (this.validateFirstStep(adminEmailsInput)) {
        const descriptionObj = await this.transalateAllDescriptions();
        const shortName =  slugify(formValues.clubName);
        const clubRegisterData = {
          name: formValues.clubName,
          shortName: shortName.length > 30 ?  shortName.slice(0, 30) : shortName,
          description: descriptionObj ? descriptionObj.EN : formValues.decriptionText.description,
          descriptionDe: descriptionObj
            ? descriptionObj.DE
            : formValues.decriptionText.descriptionDe,
          descriptionFr: descriptionObj
            ? descriptionObj.FR
            : formValues.decriptionText.descriptionFr,
          descriptionIt: descriptionObj
            ? descriptionObj.IT
            : formValues.decriptionText.descriptionIt,
          email: formValues.clubEmail,
          website: formValues.website,
          adminEmails: adminEmailsInput,
          type: this.getSavedTypeIdList(formValues.sportType),
          address: {
            address1: formValues.address1,
            address2: formValues.address2,
            postalCode: formValues.postcode,
            city: formValues.city,
            country: formValues.country,
          },
          contactNumber: formValues.contactNumber,
          nature: formValues.nature,
        };
        if (registeredClubId) {
          clubRegisterData.id = registeredClubId;
          delete clubRegisterData.type;
          clubUpdate(clubRegisterData, (clubId) => {
            this.updateClubLogo(clubId);
          });
        } else {
          clubRegisterData.latitude = formValues.latitude;
          clubRegisterData.longitude = formValues.longitude;
          saveClub(clubRegisterData, (clubId) => {
            this.uploadClubLogo(clubId);
          });
        }
      }
    }

    if (activeStep === 1 && registeredClubId) {
      if (this.validateSecondStep()) {
        const contactPersonData = {
          firstName: formValues.cpFirstName,
          lastName: formValues.cpLastName,
          position: formValues.cpDesignation,
          clubId: registeredClubId,
          contactNumber: formValues.cpContact,
          email:formValues.cpEmail,
          address: {
            address1: formValues.cpAddress1,
            address2: formValues.cpAddress2,
            postalCode: formValues.cpPostCode,
            city: formValues.cpCity,
            country: formValues.cpCountry,
          },
        };
        if (savedContactId) {
          contactPersonData.id = savedContactId;
          clubContactPersonUpdate(contactPersonData);
        } else {
          saveContactPerson(contactPersonData);
        }
      }
    }

    if (activeStep === 2 && registeredClubId) {
      if (this.validateThirdStep()) {
        const bankData = {
          name: formValues.accountName,
          iban: formValues.iban,
          currency: formValues.currency,
          country: formValues.bankCountry,
          clubId: registeredClubId,
        };
        saveBankInfo(bankData, () => {
          this.uploadDocs(registeredClubId);
        });
      }
    }
  };

  handleBack = () => {
    const {
      goPreviousStep: goBack,
      registeredClubId,
      getClubRegistrationDetails: fetchClubDetails,
    } = this.props;
    if (registeredClubId) {
      fetchClubDetails({ id: registeredClubId });
    }
    goBack();
  };

  handleChange = (e) => {
    const { formValues, errors } = this.state;
    const { name, value } = e.target;
    formValues[name] = value;
    errors[name] = '';
    this.setState({ formValues, errors });
  };

  handleTypeChange = (event) => {
    const { formValues, errors } = this.state;
    formValues.sportType = event.target.value;
    errors.sportType = '';
    this.setState({ formValues, errors });
  };
  handleNatureChange = (event) => {
    const { formValues, errors } = this.state;
    formValues.nature = event.target.value;
    errors.nature = '';
    this.setState({ formValues, errors });
  }

  handleEmailChange = (e, index,role) => {
    const { adminEmailsInput, errors } = this.state;
    const { name, value } = e.target;
    adminEmailsInput[index] = {email: value, role: role};
    if (!validateEmail(value)) {
      errors.adminEmail = 'Bitte eine gültige E-Mailadresse eingeben.';
    } else {
      errors.adminEmail = '';
    }
    this.setState({ adminEmailsInput, errors });
  };

  handleRoleChange = (e, index,email) => {
    const { adminEmailsInput, errors } = this.state;
    const { name, value } = e.target;
    adminEmailsInput[index] = {role: value, email: email};
    let adminAvailable = false;
    adminEmailsInput.forEach(val=> {
      if(val.role == 'ROLE_CLUB') adminAvailable = true;
    })
    if(!adminAvailable) errors.adminEmail = 'Select atleast one Administrator';
    this.setState({ adminEmailsInput });
  };

  handleAdminEmail = () => {
    const { adminEmailsInput } = this.state;
    adminEmailsInput.push({
      email: '',
      role: 'ROLE_CLUB'
    });
    this.setState({ adminEmailsInput });
  };

  removeAdminEmailTextField = (index) => {
    const { adminEmailsInput } = this.state;
    adminEmailsInput.splice(index, 1);
    this.setState({ adminEmailsInput });
  };

  handleDescriptionText = (e) => {
    const { formValues, errors } = this.state;
    const { name, value } = e.target;
    formValues.decriptionText.enteredText = value;
    formValues.decriptionText.currentFieldName = name;
    formValues.decriptionText[name] = value;
    errors[name] = '';
    this.setState({ formValues, errors });
  };

  getCurrentLanguage = () => {
    return localStorage.getItem('language') || 'du';
  };

  transalateAllDescriptions = async () => {
    const { formValues } = this.state;
    const descriptionText = formValues.decriptionText.enteredText;
    if (descriptionText) {
      const selectedLang = this.getCurrentLanguage();
      const targetLanguagesAndDescriptions = getTargetLanguagesAndDescriptions(
        translatedDescription,
        descriptionText,
        selectedLang
      );
      const targetTranslationList = targetLanguagesAndDescriptions.targetLanguagesList || [];
      const translatedTextList = targetLanguagesAndDescriptions.descriptionList;

      // eslint-disable-next-line no-restricted-syntax
      for (const targetLang of targetTranslationList) {
        // eslint-disable-next-line no-await-in-loop
        const response = await descriptionTranslate(descriptionText, targetLang);
        if (response) {
          translatedTextList[targetLang] = response;
        }
      }
      return translatedTextList;
    }
    return null;
  };

  setDocuments = (file, type) => {
    const { documents } = this.state;
    if (type === docTypes.clubRegistration) {
      documents.registrationDoc = [];
      documents.registrationDoc.push(file);
    }
    if (type === docTypes.clubLicenseCopy) {
      documents.licenseDoc = [];
      documents.licenseDoc.push(file);
    }
    this.setState({ documents });
  };

  uploadDocs = async (id) => {
    const { documents } = this.state;
    const { uploadFiles: uploadClubDocuments, history } = this.props;

    const promises = [];
    if (documents.registrationDoc.length > 0) {
      const fileData = new FormData();
      fileData.append('file', documents.registrationDoc[0]);
      fileData.append('entity', 'document');
      fileData.append('column', 'doc1');
      fileData.append('id', id);
      promises.push(uploadClubDocuments(fileData, () => { }));
    }

    if (documents.licenseDoc.length > 0) {
      const fileData = new FormData();
      fileData.append('file', documents.licenseDoc[0]);
      fileData.append('entity', 'document');
      fileData.append('column', 'doc2');
      fileData.append('id', id);
      promises.push(uploadClubDocuments(fileData, () => { }));
    }
    await Promise.all(promises);
    history.push('/club/registration-success');
  };

  handleCountryChange = (option, name) => {
    if (option) {
      const { formValues, errors } = this.state;
      formValues[name] = option.code;
      errors[name] = '';
      this.setState({ formValues, errors });
    }
  };

  handleSteps = (step) => {
    const { types } = this.props;
    const { formValues, adminEmailsInput, errors, savedLogo } = this.state;
    switch (step) {
      case 0:
        return (
          <ClubRegistrationFirstStep
            handleNext={this.handleNext}
            handleChange={this.handleChange}
            values={formValues}
            adminEmailsInput={adminEmailsInput}
            handleAdminEmail={this.handleAdminEmail}
            handleRoleChange={this.handleRoleChange}
            removeAdminEmailTextField={this.removeAdminEmailTextField}
            handleEmailChange={this.handleEmailChange}
            errors={errors}
            types={types}
            handleTypeChange={this.handleTypeChange}
            setAddressDetails={this.setAddressDetails}
            handleEmailCheck={this.checkEmailExistOrNot}
            setClubLogo={this.setClubLogo}
            removeClubLogo={this.removeClubLogo}
            savedLogo={savedLogo}
            handleDescriptionText={this.handleDescriptionText}
            handleCountryChange={this.handleCountryChange}
            handleNatureChange={this.handleNatureChange}
          />
        );
      case 1:
        return (
          <ClubRegistrationSecondStep
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            handleChange={this.handleChange}
            values={formValues}
            errors={errors}
            handleCountryChange={this.handleCountryChange}
          />
        );
      case 2:
        return (
          <ClubRegisterThirdStep
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            handleChange={this.handleChange}
            values={formValues}
            errors={errors}
            setDocuments={this.setDocuments}
            handleCountryChange={this.handleCountryChange}
          />
        );
      default:
        return null;
    }
  };

  setAddressDetails = (placeData) => {
    const { formValues } = this.state;
    const addressDetails = placeData.address_components ? placeData.address_components : null;
    const geometry = placeData.geometry ? placeData.geometry : null;
    const placeDetails = {
      streetNumber: '',
      route: '',
      premise: '',
    };
    if (geometry) {
      formValues.latitude = geometry.location ? geometry.location.lat() : '';
      formValues.longitude = geometry.location ? geometry.location.lng() : '';
    }
    if (addressDetails && addressDetails.length > 0) {
      for (let i = 0; i < addressDetails.length; i += 1) {
        const addressComponent = addressDetails[i];
        const addressType = addressComponent.types[0];

        switch (addressType) {
          case 'street_number':
            placeDetails.streetNumber = addressComponent.long_name;
            break;
          case 'premise':
            placeDetails.premise = addressComponent.short_name;
            break;
          case 'subpremise':

          case 'route':
            placeDetails.route = addressComponent.long_name;
            break;
          case 'locality':
            formValues.city = addressComponent.long_name;
            break;
          case 'postal_code':
            formValues.postcode = addressComponent.long_name;
            break;
          case 'country':
            formValues.country = addressComponent.short_name;
            break;
          default:
            break;
        }
      }
      let address = '';
      if (placeDetails.route) {
        address = placeDetails.route+ " " +placeDetails.streetNumber;
      } else if (placeDetails.premise) {
        address = placeDetails.premise;
      }
      formValues.address1 = address;
      this.setState({ formValues });
    }
  };

  render() {
    const { formValues } = this.state;
    const { classes, isLoading, activeStep, isFailed, errorMsg, t } = this.props;
    const selectedLanguage = this.getCurrentLanguage();
    // Step titles
    const labels = [
      t('club_informations', { lng: selectedLanguage }),
      t('contact_person', { lng: selectedLanguage }),
      t('bank_details_documents', { lng: selectedLanguage }),
    ];
    if (isLoading) {
      return (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Spinner />
          </Paper>
        </main>
      );
    }

    return (
      <Fragment>
        <div className="profile-header">
          <Header />
        </div>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {activeStep === labels.length ? (
              // Last Component
              <RegistrationSuccess values={formValues} />
            ) : (
              <Fragment>
                <Box style={{ margin: '30px 0 50px' }}>
                  <Typography variant="h4" align="center">
                    {t('welcome_club_register', { lng: selectedLanguage })}
                  </Typography>
                </Box>
                {isFailed ? <Alerts msg={errorMsg} type="error" /> : null}
                <Stepper activeStep={activeStep} style={{ margin: '30px 0 15px' }} alternativeLabel>
                  {labels.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {this.handleSteps(activeStep)}
              </Fragment>
            )}
          </Paper>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

StepForm.propTypes = {
  classes: PropTypes.shape({
    layout: PropTypes.string,
    paper: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  completedStep: PropTypes.string,
  registeredClubId: PropTypes.number,
  registerClub: PropTypes.func.isRequired,
  addContactPerson: PropTypes.func.isRequired,
  addBankDetails: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  updateFiles: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  activeStep: PropTypes.number.isRequired,
  goPreviousStep: PropTypes.func.isRequired,
  isFailed: PropTypes.bool,
  errorMsg: PropTypes.string,
  getAllTypes: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getClubRegistrationDetails: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})),
  clubRegistrationDetails: PropTypes.shape({
    club: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      shortName: PropTypes.string,
      description: PropTypes.string,
      descriptionDe: PropTypes.string,
      descriptionFr: PropTypes.string,
      descriptionIt: PropTypes.string,
      email: PropTypes.string,
      logo: PropTypes.string,
      cover: PropTypes.string,
      address: PropTypes.shape({
        id: PropTypes.number,
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
      }),
      website: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.shape({})),
      adminEmails: PropTypes.arrayOf(PropTypes.object),
      contactNumber: PropTypes.string,
      nature: PropTypes.string,
    }),
    contact: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      position: PropTypes.string,
      clubId: PropTypes.number,
      address: PropTypes.shape({
        id: PropTypes.number,
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
      }),
    }),
    bank: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      iban: PropTypes.number,
      currency: PropTypes.string,
      country: PropTypes.string,
      clubId: PropTypes.number,
    }),
  }),
  updateClub: PropTypes.func.isRequired,
  updateContactPerson: PropTypes.func.isRequired,
};

StepForm.defaultProps = {
  classes: {
    layout: '',
    paper: '',
  },
  isLoading: false,
  completedStep: null,
  registeredClubId: null,
  isFailed: false,
  errorMsg: '',
  types: [],
  clubRegistrationDetails: {},
};

const mapStateToProps = (state) => ({
  isLoading: state.spinner.isLoading,
  completedStep: state.club.completedStep,
  registeredClubId: state.club.registeredClubId,
  isFailed: state.formResponse.isFailed,
  errorMsg: state.formResponse.msg,
  activeStep: state.club.activeStep,
  types: state.club.types,
  clubRegistrationDetails: state.club.clubRegistrationDetails,
});

const mapDispatchToProps = {
  registerClub,
  addContactPerson,
  addBankDetails,
  goPreviousStep,
  getAllTypes,
  getClubRegistrationDetails,
  updateClub,
  updateContactPerson,
  uploadFiles,
  updateFiles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(StepForm)));
