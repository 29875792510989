import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { googleApiKey } from '../../utils/helper';

let autoComplete;

const loadScript = (url, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.onload = () => callback();
  document.body.appendChild(script);
};

async function handlePlaceSelect(updateQuery, setAddressDetails) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  setAddressDetails(addressObject);
}

function handleScriptLoaded(updateQuery, setAddressDetails) {
  autoComplete = new window.google.maps.places.Autocomplete(
    document.getElementById('autocomplete'),
    {
      types: ['geocode'],
    }
  );
  autoComplete.setFields(['address_component', 'geometry', 'formatted_address']);
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(updateQuery, setAddressDetails)
  );
}

function PlaceSearch(props) {
  const { setAddressDetails } = props;
  const [query, setQuery] = useState('');
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  useEffect(() => {
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`, () =>
      handleScriptLoaded(setQuery, setAddressDetails)
    );
  }, [setAddressDetails]);

  return (
    <div>
      <TextField
        id="autocomplete"
        onChange={(event) => setQuery(event.target.value)}
        placeholder={t('enter_address')}
        value={query}
        fullWidth
        margin="normal"
        onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
      />
    </div>
  );
}

PlaceSearch.propTypes = {
  setAddressDetails: PropTypes.func.isRequired,
};

export default PlaceSearch;
