import React, { useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const useStyles = makeStyles(() => ({
  maxDonationTxt: {
    fontSize: '12px',
    color: '#08C219',
    marginBottom: 0,
  },
  slider: {
    padding: '10px 0',
  },
  emptyDiv: {
    paddingTop: 5,
  },
}));

const DisplayPriceSlider = (props) => {
  const { selectedProduct } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem('language') || 'du';

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  if (selectedProduct.crowdFunding) {
    const totalFunded = selectedProduct.totalFunded ? selectedProduct.totalFunded : 0;
    const percentage = (totalFunded / selectedProduct.price) * 100;
    return (
      <div>
        <p
          className={classes.maxDonationTxt}
        >{`CHF ${totalFunded} ${t('raisedof')} CHF ${selectedProduct.price}`}</p>
        <PrettoSlider
          classes={{ root: classes.slider }}
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          value={percentage ? parseInt(percentage, 10) : 0}
        />
      </div>
    );
  }
  return <div className={classes.emptyDiv} />;
};

DisplayPriceSlider.propTypes = {
  selectedProduct: PropTypes.shape({
    crowdFunding: PropTypes.bool,
    totalFunded: PropTypes.number,
    price: PropTypes.number,
  }),
};

DisplayPriceSlider.defaultProps = {
  selectedProduct: {},
};

export default DisplayPriceSlider;
