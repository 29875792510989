// user
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_USER_FAVORITES = 'FETCH_USER_FAVORITES';
export const FETCH_USER_DONATION_HISTORY = 'FETCH_USER_DONATION_HISTORY';
export const FETCH_USER_DONATION_BY_CLUBID = 'FETCH_USER_DONATION_BY_CLUBID';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_USER_CLUB_ID = 'SET_USER_CLUB_ID';
export const FETCH_USER_DELETE = "FETCH_USER_DELETE";
export const FETCH_USER_SUBSCRIPTIONS = 'FETCH_USER_SUBSCRIPTIONS'

// club
export const FETCH_ALL_CLUBS = 'FETCH_ALL_CLUBS';
export const FETCH_CLUB_DETAILS = 'FETCH_CLUB_DETAILS';
export const FETCH_CLUBS_NEAR_BY = 'FETCH_CLUBS_NEAR_BY';
export const REGISTER_CLUB = 'REGISTER_CLUB';
export const SAVE_CLUB_CONTACT_PERSON = 'SAVE_CLUB_CONTACT_PERSON';
export const SAVE_BANK_INFORMATION = 'SAVE_BANK_INFORMATION';
export const BACK_PREVIOUS_STEP = 'BACK_PREVIOUS_STEP';
export const FETCH_CLUB_REGISTRATION_DETAILS = 'FETCH_CLUB_REGISTRATION_DETAILS';
export const CLEAR_CLUB_REGISTRATION_DETAILS = 'CLEAR_CLUB_REGISTRATION_DETAILS';
export const UPDATE_CLUB = 'UPDATE_CLUB';
export const UPDATE_CONTACT_PERSON = 'UPDATE_CONTACT_PERSON';
export const UPDATE_CLUB_REGISTER_FLOW = 'UPDATE_CLUB_REGISTER_FLOW';

// form success
export const GET_SUCCESS = 'GET_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const GET_ERROR = 'GET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SUCCESS_REDIRECT = 'SUCCESS_REDIRECT';

// display spinner
export const DISPLAY_SPINNER = 'DISPLAY_SPINNER';

// events
export const FETCH_ALL_EVENTS = 'FETCH_ALL_EVENTS';
export const FETCH_ALL_CLUB_EVENTS = 'FETCH_ALL_CLUB_EVENTS';

// products
export const FETCH_ALL_CLUB_PRODUCTS = 'FETCH_ALL_CLUB_PRODUCTS';
export const FETCH_ALL_MEMBERSHIP_PRODUCTS = 'FETCH_ALL_MEMBERSHIP_PRODUCTS';
export const FETCH_ALL_PUBLIC_CLUB_PRODUCTS = 'FETCH_ALL_PUBLIC_CLUB_PRODUCTS';
export const FETCH_ALL_TOP_PRODUCTS = 'FETCH_ALL_TOP_PRODUCTS';
export const FETCH_PRODUCT_BY_ID = 'FETCH_PRODUCT_BY_ID';
export const CLEAR_PRODUCT_DATA = 'CLEAR_PRODUCT_DATA';

// types
export const FETCH_ALL_TYPES = 'FETCH_ALL_TYPES';

export const FETCH_GRAPH_DATA = 'FETCH_GRAPH_DATA';
export const FETCH_ALL_TOP_SPONSERS = 'FETCH_ALL_TOP_SPONSERS';
export const FETCH_CLUB_PENDING_PAYOUT = 'FETCH_CLUB_PENDING_PAYOUT'

export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_CLUB_PAYOUTS = 'FETCH_CLUB_PAYOUTS';


//members
export const FETCH_MEMBERS_BY_PRODUCT = 'FETCH_MEMBERS_BY_PRODUCT'
export const FETCH_MEMBERS_BY_PRODUCT_LOAD_MORE = 'FETCH_MEMBERS_BY_PRODUCT_LOAD_MORE'
export const FETCH_ALL_MEMBERS = 'FETCH_ALL_MEMBERS'


export const FETCH_USER_MEMBERSHIPS = 'FETCH_USER_MEMBERSHIPS'
export const FETCH_USER_MEMBERSHIP = 'FETCH_USER_MEMBERSHIP'


//Member payment
export const FETCH_MEMBERS_PAYMENT_BY_PRODUCT = 'FETCH_MEMBERS_PAYMENT_BY_PRODUCT'
