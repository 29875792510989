import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { getMemberPaymentHistory, getMembers } from '../../../actions/membershipAction';
import { getImageData, validateEmail } from '../../../utils/helper';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { addMember } from '../../../actions/membershipAction';
import moment from 'moment';
import Results from './ClubMembershipList';
import ClubMemberDetails from '../../Modals/ClubMemberModal'
import ClubAddMemberModal from '../../Modals/ClubAddMemberModal';


const useStyles = makeStyles({
  loadMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Members = (props) => {
  const classes = useStyles();
  const {
    data
  } = props;

  const dispatch = useDispatch();
  const { authUserData } = useSelector((state) => state.user);
  const { members } = useSelector((state) => state.member);
  const [product, setProduct] = useState( null);

  const calculateStartingDate = (currentPeriod) => {
    return new Date(currentPeriod) > new Date() ? new Date(currentPeriod) : new Date();
  };

  const calculateInitialAmount = (startingDate, currentPeriod, amount, recurringPeriod) => {
    const totalDays = recurringPeriod == 'month' ? 30 : recurringPeriod == 'week' ? 7 : recurringPeriod == 'threeMonths' ? 90 : recurringPeriod == 'fourMonths' ? 120 : recurringPeriod == 'sixMonths' ? 180 : recurringPeriod == 'year' ? 365 : 1;
    return new Date(startingDate) > new Date(currentPeriod) ? (((totalDays - (new Date(startingDate).getDate() - new Date(currentPeriod).getDate())) / totalDays) * amount).toFixed(2) : amount;
  };

  const [startingDate, setStartingDate] = useState(moment(product ? calculateStartingDate(product.currentPeriod) : new Date()).format('YYYY-MM-DD'));
  const [initialAmount, setInitialAmount] = useState(product ? calculateInitialAmount(startingDate, product.currentPeriod, product.price, product.recurringPeriod) : 0.0);
  const [membersList, setMembersList] = useState([]);
  const { t, i18n } = useTranslation();
  const SelectedLanguageValue = localStorage.getItem('language');
  const [pageDetails, setPageDetails] = useState({ pageId: 0, pageSize: 10 });
  const [popupOpen, setPopupOpen] = useState(false); 
  //const [hasMore, setHasMore] = useState(false)

  const [isAddingMember, setIsAddingMember] = useState(false);

  const handleSelectionChange = (event) => {
    const product = event.target.value;
    setProduct(product);
    setPageDetails({
      ...pageDetails,
      pageId: 0
    });

    const startDate = moment(calculateStartingDate(product.currentPeriod)).format('YYYY-MM-DD');
    const amount = calculateInitialAmount(startDate, product.currentPeriod, product.price, product.recurringPeriod);
    setStartingDate(startDate);
    setInitialAmount(amount);
  };

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    initialAmount: '',
    startingDate: ''
  })
  const [selectedMember, setSelectedMember] = useState(null);


  const handlePageChange = (e, page) => {
    e.preventDefault();
    setPageDetails({
      ...pageDetails,
      pageId: page
    });
    console.log(pageDetails)
  };
  
  const handlePageSizeChange = (e) => {
    setPageDetails({
      pageId: 0,
      pageSize: e.target.value
    });
    console.log(pageDetails)
  };

  useEffect(() => {
    i18n.changeLanguage(SelectedLanguageValue);
  }, [SelectedLanguageValue, i18n]);

  const handleMoreClick = (userId, productId) => {
    dispatch(
      getMemberPaymentHistory({
        userId: userId,
        productId: productId
      })
    );
    setPopupOpen(true); 
  };

  const closePopup = () => {
    setPopupOpen(false); 
  };

  useEffect(() => {
    const getNewMembersList = async () => {
      const newMembersList = [];
      const content = members.content ?  members.content : [] ;
      for (let memberItem = 0; memberItem < content.length; memberItem += 1) {
        const item = content[memberItem];
        if (content[memberItem].image) {
          const fileName = content[memberItem].image;
          // eslint-disable-next-line no-await-in-loop
          const imageData = await getImageData(authUserData, fileName);
          item.imageUrl = imageData || '';
        }
        newMembersList.push(item);
      }
      setMembersList(newMembersList);
    };
    getNewMembersList();
  }, [members, authUserData, pageDetails]);

  const clearFilter = ()=>{
    setProduct(null)
  }

  const toggleAddMemberModal = (userId, productId) => {
    setIsAddingMember(!isAddingMember);
  };

  return (
    <div className="tab-pane product-tab" id="tab2">

      <div className="product-create-btn flex-row justify-content-between">
        <h4>{t('membership')}</h4>
        <Button onClick={toggleAddMemberModal} className="btn-global" variant="outlined">
          {t('add_member')}
        </Button>
      </div>

      <div>
        <form id="memberShipForm">
          <div className="product-create-btn">
            {t('mebership_type')}<br />
            <FormControl id="memberShipTypeDropdown" className="w-50">
              <Select
                value={product}
                onChange={handleSelectionChange}
              >
                {
                  data.map(product => (
                    <MenuItem value={product}>{product.title}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            </div>

            <ClubMemberDetails
              open={popupOpen}
              closePopup={closePopup}
              member={selectedMember}
            />

            <ClubAddMemberModal
              open={isAddingMember}
              toggleAddMemberModal={toggleAddMemberModal}
              handleSelectionChange={handleSelectionChange}
              calculateStartingDate={calculateStartingDate}
              calculateInitialAmount={calculateInitialAmount}
              data={data}
              product={product}
              setProduct={setProduct}
              pageDetails={pageDetails}
              setPageDetails={setPageDetails}
              initialAmount={initialAmount}
              setInitialAmount={setInitialAmount}
              startingDate={startingDate}
              setStartingDate={setStartingDate}
              authUserData={authUserData}
            />
        </form>
      </div>

      <div>
        <Results
          members={members.content ? members.content : []}
          pageDetails={pageDetails}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          totalCount={members.totalElements}
          totalPages={members.totalPages}
          setPageDetails={setPageDetails}
          t={t}
          onClickMore={handleMoreClick}
          setemember={setSelectedMember}
        ></Results>
      </div>
    </div>
  );
};

Members.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      userImage: PropTypes.string,
    })
  ),
};

Members.defaultProps = {
  data: [],
};

export default Members;
