import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { baseUrl, authHeader } from '../../utils/helper';

function Alert(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 120,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cancel: {
        margin: theme.spacing(0, 0, 2),
    },
    cardInput: {
        marginTop: 16,
        marginBottom: 16,
        borderBottom: '1px solid black',
        padding: '6px 0 7px',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    masterCardLogo: {
        width: '40%',
        height: 'auto',
    },
    paymentOptionLabel: {
        marginTop: 10,
        marginBottom: 0,
    },
}));

const CARD_OPTIONS = {
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
    hidePostalCode: true,
};

const apiURL = `${baseUrl}/payments/payment-method`;


const PaymentMethodForm = (props) => {
    const { authUserData } = useSelector((state) => state.user);
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const { t, i18n } = useTranslation();

    const selectedLanguage = localStorage.getItem('language');
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
    }, [selectedLanguage, i18n]);


    const hideSuccessAndGoBack = () => {
        setTimeout(() => {
            setSuccessMsg(false);
            //const { from } = history.location.state || { from: { pathname: '/user' } };
            //history.push(from);
            props.closeModal(false);
        }, 2500);
    };

    const displayError = (msg) => {
        setErrorMsg(msg);
        setLoading(false);
        setTimeout(() => {
            setErrorMsg('');
        }, 2000);
    };

    const paymentData = {
        userId: authUserData.userId,
    };

    const handleStripeJsResult = async (result) => {
        console.log(result)
        try {
            if (result.error) {
                displayError(result.error.message);
            } else {
                paymentData.setupIntentId = result.setupIntent.id;
                const response = await axios.post(apiURL, paymentData, authHeader(authUserData.token));
                if (response && response.data) {
                    setSuccessMsg(true);
                    hideSuccessAndGoBack();
                }
            }
        } catch (error) {
            if (error && error.response) {
                displayError('Something went wrong');
            }
        }
    };

    const stripePaymentMethodHandler = async (result) => {
        try {
            paymentData.payment_method = result.id;
            const response = await axios.post(apiURL, paymentData, authHeader(authUserData.token));
            if (response && response.data) {
                setLoading(false);
                if (response.data.requires_action) {
                    stripe
                        .confirmSetup({
                            clientSecret: response.data.payment_intent_client_secret,
                            confirmParams: {
                                return_url: 'http://localhost:3000/profile/user',
                            },
                            redirect:'if_required'
                        })
                        .then(handleStripeJsResult);
                } else {
                    setSuccessMsg(true);
                    hideSuccessAndGoBack();
                }
            }
        } catch (error) {
            if (error && error.response) {
                displayError('Something went wrong');
            }
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setLoading(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        if (error) {
            setLoading(false);
        } else {
            stripePaymentMethodHandler(paymentMethod);
        }

    };


    return (
        <Container component="main" maxWidth="xs" className="paymentform">
            <form className={classes.form} onSubmit={handleSubmit} style={{marginBottom: 20, marginTop: 50}}>
                {successMsg && <Alert severity="success">{t('payment_method_success')}</Alert>}
                {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                <p className="payment-sub-heading" style={{textAlign: 'center'}}>
                    {t('add_new_payment')}
                </p>

                <TextField
                    id="standard-name-input"
                    label={t('nameoncard')}
                    required
                    fullWidth
                />


                <div className={classes.cardInput}>
                    <CardElement options={CARD_OPTIONS} />
                </div>

                <Grid item xs={12} sm={12} style={{ fontSize: '0.675rem' }}>
                    <div>{t('pre_buy')} <a href="/agb" target="_blank">{t('condition_agb')}</a> und <a href="/datenschutz" target="_blank">{t('condition_privacy')}</a> {t('condition_aft')}</div>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="pay-btn"
                >
                    {t('update_payment_method')}
                    {loading && <CircularProgress size={24} color="primary" />}
                </Button>
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    className="pay-btn"
                    onClick={()=> props.closeModal(false)}
                >
                    {t('close')}
                    {loading && <CircularProgress size={24} color="primary" />}
                </Button>
            </form>
        </Container>
    );
};

PaymentMethodForm.propTypes = {
};

PaymentMethodForm.defaultProps = {
};

export default PaymentMethodForm;
